<template>
  <div class="max-w-2xl mx-auto lg:max-w-4xl md:max-w-4xl">
    <Title msg="電子教材登録" submsg="Book Register" />
    <div class="flex justify-center pb-10">
      <form @submit.prevent class="pt-7 w-full md:w-2/3">
        <ErrMsgCard :errMsgs="errMsgs" :process="process" />
        <div class="grid grid-cols-1 gap-5">
          <div>
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ licenseCd.key }}</span>
              <span class="form-required">必須</span>
            </div>
            <input
              type="text"
              placeholder="例：12345"
              v-model="licenseCd.value"
              maxlength="5"
              class="w-1/2"
            />
          </div>

          <div>
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ subject.key }}</span>
              <span class="form-required">必須</span>
            </div>
            <select v-model="subject.value" class="h-12 w-1/2 py-2 px-4 pr-10">
              <option
                :value="{ name: '', code: '', colorCd: '' }"
                disabled
                selected
                style="display: none"
              >
                選択してください
              </option>
              <option
                v-for="sbj in subjectList"
                :key="sbj.code"
                v-bind:value="sbj"
              >
                {{ sbj.name }}
              </option>
            </select>
          </div>

          <div>
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ textbookName.key }}</span>
              <span class="form-required">必須</span>
            </div>
            <input
              type="text"
              placeholder="例：美術の教材１"
              v-model="textbookName.value"
              maxlength="50"
              class="w-full"
            />
          </div>

          <div>
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ displayOrder.key }}</span>
              <span class="form-required">必須</span>
            </div>
            <input
              type="number"
              min="0"
              placeholder="例：1"
              v-model="displayOrder.value"
              @input="displayOrderSliceMaxLength(4)"
              class="w-1/2"
            />
          </div>

          <div>
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ standardTerm.key }}</span>
              <span class="form-required">必須</span>
            </div>
            <select
              v-model="standardTerm.value"
              class="h-12 w-1/2 py-2 px-4 pr-10"
            >
              <option
                :value="{ name: '', value: '' }"
                disabled
                selected
                style="display: none"
              >
                選択してください
              </option>
              <option
                v-for="st in standardTermList"
                :key="st.code"
                v-bind:value="st"
              >
                {{ st.name }}
              </option>
            </select>
          </div>

          <!-- 電子教材に関するzipファイルをアップロード取り込み機能搭載 -->
          <div class="border border-gray-400 rounded">
            <div class="bg-gray-400 py-1">
              <span class="rounded-t mx-3 text-white">ファイル1</span>
            </div>
            <div class="mt-3 mb-5 mx-5">
              <div class="flex items-center mb-1.5">
                <span class="text-gray-700">電子教材アップロード</span>
                <span class="form-required">必須</span>
              </div>
              <div class="mt-2.5">
                <label
                  class="file-upload btn-file btn-indigo"
                  v-on:click="onFileClear"
                >
                  電子教材ファイルを選択
                  <input
                    type="file"
                    name="uploadZip1"
                    accept=".zip"
                    v-on:change="onFileChange"
                  />
                </label>
                <span class="ml-2 underline">{{ zipName1 }}</span>
              </div>
            </div>

            <div class="mt-3 mb-5 mx-5">
              <div class="flex items-center mb-1.5">
                <span class="text-gray-700">{{ textbookType1.key }}</span>
                <span class="form-required">必須</span>
              </div>
              <div class="my-2">
                <div v-for="textbookType in textbookTypeList"
                  :key="textbookType.code"
                  v-bind:value="textbookType">
                  <input
                    type="radio"
                    :id="'textbookType1_' + textbookType.code"
                    name="textbookType1"
                    :value="textbookType.code"
                    v-model="textbookType1.value"
                    class="text-indigo-600"
                  />
                  <label :for="'textbookType1_' + textbookType.code" class="ml-3">
                    {{ textbookType.name }}
                  </label>
                </div>
              </div>
            </div>

            <div class="my-5 mx-5">
              <div class="flex items-center mb-1.5">
                <span class="text-gray-700">サムネイルアップロード</span>
                <span class="form-required">必須</span>
              </div>
              <div class="mt-2.5">
                <label
                  class="file-upload btn-file btn-indigo"
                  v-on:click="onFileClear"
                >
                  画像ファイルを選択
                  <input
                    type="file"
                    name="uploadImg1"
                    accept="image/*"
                    v-on:change="onFileChange"
                  />
                </label>
                <span class="ml-2 underline">{{ imgName1 }}</span>
              </div>
              <img
                v-show="previewImage1"
                class="preview-item-file mt-3"
                :src="previewImage1"
                alt="選択画像プレビュー"
              />
            </div>
          </div>

          <!-- 電子教材に関するzipファイルをアップロード取り込み機能搭載 -->
          <div class="border border-gray-400 rounded">
            <div class="bg-gray-400 py-1">
              <span class="rounded-t mx-3 text-white">ファイル2</span>
            </div>
            <div class="mt-3 mb-5 mx-5">
              <div class="flex items-center mb-1.5">
                <span class="text-gray-700">電子教材アップロード</span>
                <span class="form-not-required">任意</span>
              </div>
              <div class="mt-2.5">
                <label
                  class="file-upload btn-file btn-indigo"
                  v-on:click="onFileClear"
                >
                  電子教材ファイルを選択
                  <input
                    type="file"
                    name="uploadZip2"
                    accept=".zip"
                    v-on:change="onFileChange"
                  />
                </label>
                <span class="ml-2 underline">{{ zipName2 }}</span>
              </div>
            </div>

            <div class="mt-3 mb-5 mx-5">
              <div class="flex items-center mb-1.5">
                <span class="text-gray-700">{{ textbookType2.key }}</span>
                <span class="form-not-required">任意</span>
              </div>
              <div class="my-2">
                <div v-for="textbookType in textbookTypeList"
                  :key="textbookType.code"
                  v-bind:value="textbookType">
                  <input
                    type="radio"
                    :id="'textbookType2_' + textbookType.code"
                    name="textbookType2"
                    :value="textbookType.code"
                    v-model="textbookType2.value"
                    class="text-indigo-600"
                  />
                  <label :for="'textbookType2_' + textbookType.code" class="ml-3">
                    {{ textbookType.name }}
                  </label>
                </div>
              </div>
            </div>

            <div class="my-5 mx-5">
              <div class="flex items-center mb-1.5">
                <span class="text-gray-700">サムネイルアップロード</span>
                <span class="form-not-required">任意</span>
              </div>
              <div class="mt-2.5">
                <label
                  class="file-upload btn-file btn-indigo"
                  v-on:click="onFileClear"
                >
                  画像ファイルを選択
                  <input
                    type="file"
                    name="uploadImg2"
                    accept="image/*"
                    v-on:change="onFileChange"
                  />
                </label>
                <span class="ml-2 underline">{{ imgName2 }}</span>
              </div>
              <img
                v-show="previewImage2"
                class="preview-item-file mt-3"
                :src="previewImage2"
                alt="選択画像プレビュー"
              />
            </div>
          </div>

          <div>
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ remarks.key }}</span>
              <span class="form-not-required">任意</span>
            </div>
            <textarea
              v-model="remarks.value"
              class="w-full"
              rows="3"
            ></textarea>
          </div>

          <div class="flex justify-center mt-8">
            <button
              @click="$router.go(-1)"
              type="button"
              name="back-button"
              class="button btn-gray mr-5 md:mr-10"
            >
              戻る
            </button>
            <button
              v-on:click="register"
              type="button"
              name="button"
              class="button btn-indigo"
            >
              登録
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  registerTextbook,
  uploadTextbookZip,
  uploadTextbookThumbnail,
  searchSubjects,
} from '../api';
import Title from '../components/TheTitle.vue';
import ErrMsgCard from '../components/ErrorMssageCard.vue';
import FileType from '../const/fileType';
import Message from '../const/message';
import UserAuth from '../const/userAuthority';
import Validation from '../utils/validation';
import store from '../store';
import Authorize from '../utils/authorize';

export default {
  name: 'BookManageRegister',
  components: {
    Title,
    ErrMsgCard,
  },
  data() {
    return {
      subjectList: [],
      textbookTypeList: [],
      licenseCd: { key: 'ライセンスコード', value: '' },
      subject: { key: '教科名', value: { name: '', code: '', colorCd: '' } },
      textbookName: { key: '電子教材名', value: '' },
      displayOrder: { key: '表示順', value: '' },
      standardTermList: this.generateStanderdTermOptions([1, 3, 6]),
      standardTerm: {
        key: '基準有効期間',
        value: this.generateStanderdTermValue(1),
      },
      remarks: { key: '備考', value: '' },
      zip1: { key: 'ファイル１：電子教材', value: null },
      zipName1: Message.NOT_SELECT,
      textbookType1: { key: '電子教材種別', value: ''},
      img1: { key: 'ファイル１：サムネイル', value: null },
      imgName1: Message.NOT_SELECT,
      previewImage1: '',
      zip2: { key: 'ファイル２：電子教材', value: null },
      zipName2: Message.NOT_SELECT,
      textbookType2: { key: '電子教材種別', value: '' },
      img2: { key: 'ファイル２：サムネイル', value: null },
      imgName2: Message.NOT_SELECT,
      previewImage2: '',

      errMsgs: [],
      process: Message.PROC_TYPE.REGISTER,
      isUnsent: true
    };
  },
  async created() {
    // 権限チェック
    if (this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code) {
      // 教科コードと電子教材種別を全件取得
      const rspBody = await searchSubjects(this.$route.path);
      if(rspBody.result.code === 0){
        this.subjectList = rspBody.detail.subjects;
        this.textbookTypeList = rspBody.detail.textbookTypes;
        // 電子教材種別のデフォルト値を更新
        this.textbookType1.value = this.textbookTypeList.length ? this.textbookTypeList[0].code : '';
        this.textbookType2.value = this.textbookTypeList.length ? this.textbookTypeList[0].code : '';
      }
    } else {
      // エラーページに遷移
      this.$router.push({
        name: 'not-found',
        params: { catchAll: 'not-found' },
      });
      // 処理強制終了
      return false;
    }
  },
  methods: {
    register() {
      let self = this;
      // 登録確認ポップアップを表示
      this.$store.commit('setMessages', [
        Message.generateMessage(Message.CONFIRM, [this.process]),
      ]);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setIsModalOpen', true);
      this.$store.commit('setOkAction', async function () {
        // バリデーション定義作成
        let validationArray = [];

        // バリデーションチェック実施
        self.errMsgs = validationArray.concat(
          Validation.requiredInput(self.licenseCd),
          Validation.isInteger(self.licenseCd),
          Validation.fixedLength(self.licenseCd, 5),
          Validation.requiredSelect(self.subject),
          Validation.requiredInput(self.textbookName),
          Validation.requiredInput(self.displayOrder),
          Validation.isInteger(self.displayOrder),
          Validation.digitsMin(self.displayOrder, 1),
          Validation.requiredSelect(self.standardTerm),
          Validation.isInteger(self.standardTerm),
          Validation.fixedLength(self.standardTerm, 1),
          Validation.requiredInput(self.zip1, true),
          Validation.isFile(self.zip1, FileType.ZIP),
          Validation.requiredInput(self.img1, true),
          Validation.isFile(self.img1, FileType.IMAGE),
          Validation.fileSizeMax(self.img1, 6),
          Validation.isFile(self.zip2, FileType.ZIP),
          Validation.isFile(self.img2, FileType.IMAGE),
          Validation.fileSizeMax(self.img2, 6),
        );

        // エラーメッセージがある場合は処理を中断
        if (self.errMsgs.length) return false;

        if (Date.now() / 1000 > self.$store.state.tokenExp){
          if (!await Authorize.isAuth()) {
            // 認証失敗：ログイン画面へ遷移
            self.$router.push({ name: 'login', query: { redirect: self.$route.path } });
            return false;
          }
        }

        const isUploadedFiles =
          self.zip2.value && self.img2.value ? true : false;

        // 電子教材登録API呼び出し
        registerTextbook(
          async function (rspBody) {
            if (rspBody.result.code === 0) {
              // 成功した場合、zipファイルアップロード用署名付きURLを取得
              const urls = rspBody.detail.signedUrls;

              // ローディング画面を表示
              store.commit('setLoading', true);
              // 電子教材zipファイルとサムネイルを並列でアップロード
              const results = await Promise.all([
                self.uploadThumbnail(1, isUploadedFiles),
                self.uploadZip(1, urls, isUploadedFiles),
                self.uploadThumbnail(2, isUploadedFiles),
                self.uploadZip(2, urls, isUploadedFiles),
              ]);

              let promiseErrMsgs = [];
              // 処理結果の中から、配列のメッセージのみ配列に格納
              results.forEach(rslt => {
                if(Array.isArray(rslt)){
                  promiseErrMsgs = promiseErrMsgs.concat(rslt);
                }
              });
              // ローディング画面を非表示
              store.commit('setLoading', false);

              if (promiseErrMsgs.length){
                // エラーメッセージがある場合は表示
                self.errMsgs = promiseErrMsgs;
              } else {
                self.isUnsent = false;
                // エラーメッセージがない場合は電子教材一覧に遷移
                self.$store.commit('setMessages', [
                  Message.generateMessage(Message.COMPLETED, [
                    Message.PROC_TYPE.REGISTER,
                  ]),
                ]);
                self.$store.commit('setOkAction', function () {
                  self.toTextbookList();
                });
                self.$store.commit('setIsModalInfo', true);
                self.$store.commit('setCancelBtnHide', true);
                self.$store.commit('setIsModalOpen', true);
              }
            } else {
              // 電子教材登録に失敗した場合、エラー内容を表示
              self.errMsgs = rspBody.result.messages;
            }
          },
          self.licenseCd.value,
          self.textbookName.value,
          self.subject.value.code,
          self.standardTerm.value.code,
          self.displayOrder.value,
          self.textbookType1.value,
          self.textbookType2.value,
          self.remarks.value,
          isUploadedFiles,
          self.$route.path
        );
      });
    },
    async uploadThumbnail(seq, isUploadedFiles = false){
      let thumbnail = this.img1.value;
      if (seq === 2){
        // ファイル2をアップロードするか
        if (isUploadedFiles) {
          // する場合はthumbnailを上書き
          thumbnail = this.img2.value;
        } else {
          // しない場合は処理を中断
          return true;
        }
      }
      // サムネイルアップロードAPI呼び出し
      const rspBody = await uploadTextbookThumbnail(
        this.licenseCd.value,
        seq,
        thumbnail,
        this.$route.path
      );
      if (rspBody.result.code === 0) {
        // 成功した場合
        return true;
      } else {
        // 失敗した場合、エラー内容を返却
        return rspBody.result.messages;
      }
    },
    async uploadZip(seq, signedUrls, isUploadedFiles = false){
      let zip = this.zip1.value;
      let signedUrl = signedUrls[0];
      if (seq === 2){
        // ファイル2をアップロードするか
        if (isUploadedFiles && signedUrls.length === 2) {
          // する場合はzipファイルと署名付きURLを上書き
          zip = this.zip2.value;
          signedUrl = signedUrls[1];
        } else {
          // しない場合は処理を中断
          return true;
        }
      }
      // 電子教材を署名付きURLでアップロード
      const rspBody = await uploadTextbookZip(
        signedUrl, zip
      );
      if (rspBody === '') {
        // 成功した場合
        return true;
      } else {
        // 失敗した場合、エラー内容を返却
        return rspBody;
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      const fileName = e.target.name;
      if (fileName === 'uploadZip1') {
        this.zip1.value = file;
        this.zipName1 = file.name;
      } else if (fileName === 'uploadZip2') {
        this.zip2.value = file;
        this.zipName2 = file.name;
      }
      if (fileName === 'uploadImg1') {
        this.img1.value = file;
        this.imgName1 = file.name;
        this.previewImage(file, 1);
      } else if (fileName === 'uploadImg2') {
        this.img2.value = file;
        this.imgName2 = file.name;
        this.previewImage(file, 2);
      }
    },
    /** アップロードした画像を表示 */
    previewImage(img, seq) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (seq === 1) {
          this.previewImage1 = e.target.result;
        } else if (seq === 2) {
          this.previewImage2 = e.target.result;
        }
      };
      reader.readAsDataURL(img);
    },
    onFileClear(e) {
      const fileName = e.target.name;
      e.target.value = '';
      if (fileName === 'uploadZip1') {
        this.zip1.value = null;
        this.zipName1 = Message.NOT_SELECT;
      } else if (fileName === 'uploadZip2') {
        this.zip2.value = null;
        this.zipName2 = Message.NOT_SELECT;
      }
      if (fileName === 'uploadImg1') {
        this.img1.value = null;
        this.imgName1 = Message.NOT_SELECT;
        this.previewImage1 = '';
      } else if (fileName === 'uploadImg2') {
        this.img2.value = null;
        this.imgName2 = Message.NOT_SELECT;
        this.previewImage2 = '';
      }
    },
    generateStanderdTermOptions(standerdTermArray) {
      let options = [];
      standerdTermArray.forEach((st) => {
        options.push(this.generateStanderdTermValue(st));
      });
      return options;
    },
    generateStanderdTermValue(standerdTerm) {
      return { name: standerdTerm + '年', code: standerdTerm };
    },
    toTextbookList() {
      this.$router.push({
        name: 'book-manage',
      });
    },
    /** 表示順のMaxLengthを制御（type="Number"の要素はMaxLengthを指定できないため） */
    displayOrderSliceMaxLength(maxLength) {
      this.displayOrder.value = this.displayOrder.value.slice(0, maxLength);  
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isUnsent && to.name != 'maintenance') {
      this.$store.commit('setMessages', [Message.INPUT_VALUE_CLEAR]);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setIsModalOpen', true);
      this.$store.commit('setOkAction', function () {
        next();
      });
      this.$store.commit('setCancelAction', function () {
        next(false);
      });
    } else {
      next();
    }
  },
};
</script>

<style scoped>
input[type='file'] {
  @apply hidden;
}
.file-upload {
  @apply font-semibold py-2 px-6 text-white rounded text-center;
  cursor: pointer;
}
.btn-file {
  @apply focus:outline-none focus:ring focus:ring-opacity-50;
}
.file:hover {
  @apply bg-indigo-900;
}
.button {
  @apply font-semibold w-32 rounded py-2 px-2 focus:outline-none focus:ring focus:ring-opacity-50;
}
input,
select,
textarea {
  @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}
select,
textarea {
  @apply mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-300;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -moz-appearance: textfield;
  -webkit-appearance: none;
  margin: 0;
}
</style>
