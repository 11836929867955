<template>
  <div class="max-w-2xl mx-auto md:max-w-5xl">
    <div class="flex justify-center pb-2">
      <form @submit.prevent class="w-full">
        <div class="flex lg:flex-row flex-col mt-3 mb-1">
          <div class="flex flex-row mb-1 lg:mb-0">
            <div class="items-start">
              <p class="text-sm">
                <font-awesome-icon icon="caret-down" class="ml-1" />
                {{ authGrade.key }}
              </p>
              <select v-model="authGrade.value" class="form-left w-40 mt-1">
                <option
                  :value="{ auth: '', authCode: '', grd: '', grdCode: '' }"
                  disabled
                  selected
                  style="display: none"
                >
                  選択してください
                </option>
                <option
                  v-for="authGrade in getAuthGradeList"
                  :key="authGrade.grd"
                  v-bind:value="{
                    auth: authGrade.auth,
                    authCode: authGrade.authCode,
                    grd: authGrade.grd,
                    grdCode: authGrade.grdCode,
                  }"
                  selected
                >
                  {{
                    authGrade.auth === '学習者'
                      ? authGrade.auth + ' / ' + authGrade.grd + '年'
                      : authGrade.auth
                  }}
                </option>
              </select>
            </div>

            <div v-if="isSelectedStudent" class="items-start">
              <p class="text-sm">
                <font-awesome-icon icon="caret-down" class="ml-1" />
                {{ baseYear.key }}
              </p>
              <select v-model="baseYear.value" class="form-middle w-44 mt-1">
                <option
                  :value="0"
                  disabled
                  selected
                  style="display: none"
                >
                  選択してください
                </option>
                <option v-bind:value="getNowYear">
                  今年度（{{ getNowYear }}年度）
                </option>
                <option v-bind:value="getNowYear + 1">
                  来年度（{{ getNowYear + 1 }}年度）
                </option>
              </select>
            </div>

            <div class="relative">
              <p class="text-sm">
                <font-awesome-icon icon="caret-down" class="ml-1" />
                {{ loginId.key }}
              </p>
              <input
                type="text"
                maxlength="13"
                class="form-right"
                style="width: 8.5rem;"
                v-model="loginId.value"
              />
            </div>

            <div class="flex mt-6 ml-4 h-8">
              <button v-on:click="search" class="btn btn-search btn-short">
                <font-awesome-icon icon="search" class="mr-1" />
                検索
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Validation from '../utils/validation';
import UserAuth from '../const/userAuthority';

export default {
  name: 'UserLicenseManage',
  emits: ['search'],
  data() {
    return {
      loginId: { key: 'ログインID', value: '' },
      baseYear: { key: '基準年度', value: 0 },
      authGrade: {
        key: 'ユーザ区分 / 学年',
        value: { auth: '', authCode: '', grd: '', grdCode: '' },
      },

      errMsgs: [],
    };
  },
  created() {
    if(this.$store.state.searchUserLicenseKeys){
      this.loginId.value = this.$store.state.searchUserLicenseKeys.loginId;
      this.baseYear.value = this.$store.state.searchUserLicenseKeys.baseYear;
      this.authGrade.value = this.$store.state.searchUserLicenseKeys.authGrade;
    }
  },
  methods: {
    /** 親コンポーネントのユーザライセンス検索呼び出し */
    search() {
      // バリデーション定義作成
      let validationArray = [];

      // バリデーションチェック実施
      this.errMsgs = validationArray.concat(
        Validation.digitsMax(this.loginId, 13),
        Validation.isAuthGrade(this.authGrade),
        this.authGrade.value.authCode === UserAuth.STUDENT.code
          ? Validation.requiredInput(this.baseYear)
          : []
      );

      // エラーメッセージがある場合は処理を中断
      if (this.errMsgs.length) {
        this.$store.commit('setMessages', this.errMsgs);
        this.$store.commit('setCancelBtnHide', true);
        this.$store.commit('setIsModalOpen', true);
        return false;
      }
      
      this.$store.commit({
        type:'setSearchUserLicenseKeys',
        loginId: this.loginId.value,
        baseYear: this.baseYear.value,
        authGrade: this.authGrade.value,
      });
      this.$emit(
        'search',
        this.baseYear.value,
        this.authGrade.value.authCode,
        this.authGrade.value.grdCode,
        this.loginId.value
      );
    },
  },
  computed: {
    getNowYear() {
      let currentTime = new Date();
      // 年度を取得したいため、現在から3ヶ月前にセット
      currentTime.setMonth(currentTime.getMonth() - 3);
      return currentTime.getFullYear();
    },
    getAuthGradeList() {
      // 1から最高学年までの数字を配列化
      let gradeList = [...Array(this.$store.state.maxGrade).keys()].map(
        (i) => ++i
      );

      // 学年にユーザ区分を追加
      let authGradeList = [];

      // 学習者を追加
      gradeList.forEach((grade) => {
        let authGrade = {
          auth: UserAuth.STUDENT.name,
          authCode: UserAuth.STUDENT.code,
          grd: grade,
          grdCode: String(grade),
        };
        authGradeList.push(authGrade);
      });

      // 教職員を追加
      authGradeList.push({
        auth: UserAuth.TEACHER.name,
        authCode: UserAuth.TEACHER.code,
        grd: 0,
        grdCode: '0',
      });

      return authGradeList;
    },
    isSelectedStudent() {
      // ユーザ区分で学習者を選択しているかどうか
      return this.authGrade.value.authCode === UserAuth.STUDENT.code;
    }
  },
};
</script>

<style scoped>
input[type='text'] {
  @apply px-3 rounded-l-none focus:border-gray-400;
}
.form-left {
  @apply h-8 
         py-2 pl-3 pr-8
         leading-tight
         text-sm text-gray-700
         border border-r-0 border-gray-400
         rounded-l
         focus:ring-0;
}
.form-middle {
  @apply h-8 
         py-2 pl-3 pr-8
         leading-tight
         text-sm text-gray-700
         border border-r-0 border-gray-400
         focus:ring-0;
}
.form-right {
  @apply h-8 
         py-2 px-4 pr-8
         leading-tight
         text-sm text-gray-700
         border border-gray-400
         rounded-r
         focus:ring-0;
}

.btn-short {
  @apply h-8 py-1 px-4 w-28;
}
</style>
