<template>
  <div class="antialiased">
    <div v-bind:class="{'mt-1': (isSysAdmin || isSchoolAdmin), 'mt-4': !(isSysAdmin || isSchoolAdmin) }" >
      <div class="inline-block min-w-full shadow rounded-lg overflow-y-hidden">
        <table class="min-w-full leading-normal text-xs">
          <thead>
            <tr>
              <!-- システム管理者と学校管理者のみtbodyにチェックボックス表示 -->
              <th class="td-checkbox"></th>
              <!-- システム管理者のみ表示 -->
              <th class="td-school" v-if="isSysAdmin">学校名</th>
              <th class="td-id">ログインID</th>
              <th
                v-bind:class="{ 'td-name': isSysAdmin, 'td-name-wide': !isSysAdmin }"
              >
                表示名
              </th>
              <th class="td-auth">ユーザ区分</th>
              <th class="td-status">状態</th>
              <th class="td-grade">学年</th>
              <th class="td-last-login-date">最終ログイン日時</th>
              <!-- システム管理者は非表示 -->
              <th class="td-book" v-if="!isSysAdmin">教材</th>
              <th class="td-update">詳細/更新</th>
            </tr>
          </thead>

          <tbody
            v-if="isSysAdmin"
            class="overflow-y-scroll w-full"
            v-bind:style="{ height: calcTbodyHeight() }"
          >
            <tr v-for="user in displayUsers" :key="user.loginId">
              <td class="td-checkbox">
                <input
                  type="checkbox"
                  v-model="checkedLoginIds"
                  :value="user.loginId"
                />
              </td>
              <td class="td-school">
                <p class="td-p whitespace-nowrap">
                  {{ schoolCdToName(user.schoolCd) }}
                </p>
              </td>
              <td class="td-id">
                <p class="td-p whitespace-nowrap">{{ user.loginId }}</p>
              </td>
              <td class="td-name">
                <div class="flex items-center">
                  <p class="td-p whitespace-nowrap" v-tooltip="{content: user.displayName}">
                    {{ user.displayName }}
                  </p>
                </div>
              </td>
              <td class="td-auth">
                <p class="td-p whitespace-nowrap">{{ userAuthCdToName(user.authority) }}</p>
              </td>
              <td class="td-status">
                <span
                  v-if="user.userStatus === userStatus.BEFORE_USE.code"
                  class="py-1 px-2 bg-gray-100 text-gray-900 font-semibold rounded-full"
                >
                  {{ userStatus.BEFORE_USE.name }}
                </span>
                <span
                  v-else-if="user.userStatus === userStatus.USABLE.code"
                  class="py-1 px-2 bg-green-100 text-green-900 font-semibold rounded-full"
                >
                  {{ userStatus.USABLE.name }}
                </span>
                <span
                  v-else-if="user.userStatus === userStatus.UNUSABLE.code"
                  class="py-1 px-2 bg-red-100 text-red-900 font-semibold rounded-full"
                >
                  {{ userStatus.UNUSABLE.name }}
                </span>
              </td>
              <td class="td-grade">
                <p class="td-p whitespace-nowrap">{{ user.grade }}</p>
              </td>
              <td class="td-last-login-date">
                <p class="td-p whitespace-nowrap">{{ user.lastLoginDate.slice( 0, -4 ) }}</p>
              </td>
              <td class="td-update">
                <router-link
                  :to="{
                    name: 'user-manage-update',
                    params: { schoolCd: user.schoolCd, loginId: user.loginId },
                  }"
                  class="td-link text-xs"
                >
                  <font-awesome-icon icon="edit" size="lg" />
                </router-link>
              </td>
            </tr>
          </tbody>
          <tbody
            v-else
            class="overflow-y-scroll w-full"
            v-bind:style="{ height: calcTbodyHeight() }"
          >
            <tr v-for="user in displayUsers" :key="user.loginId">
              <td class="td-checkbox">
                <input
                  v-if="isSchoolAdmin"
                  type="checkbox"
                  v-model="checkedLoginIds"
                  :value="user.loginId"
                />
              </td>
              <td class="td-id">
                <p class="td-p whitespace-nowrap">{{ user.loginId }}</p>
              </td>
              <td class="td-name-wide">
                <div class="flex items-center">
                  <p class="td-p whitespace-nowrap" v-tooltip="{content: user.displayName}">
                    {{ user.displayName }}
                  </p>
                </div>
              </td>
              <td class="td-auth">
                <p class="td-p whitespace-nowrap">{{ userAuthCdToName(user.authority) }}</p>
              </td>
              <td class="td-status">
                <span
                  v-if="user.userStatus === userStatus.BEFORE_USE.code"
                  class="py-1 px-2 bg-gray-100 text-gray-900 font-semibold rounded-full"
                >
                  {{ userStatus.BEFORE_USE.name }}
                </span>
                <span
                  v-else-if="user.userStatus === userStatus.USABLE.code"
                  class="py-1 px-2 bg-green-100 text-green-900 font-semibold rounded-full"
                >
                  {{ userStatus.USABLE.name }}
                </span>
                <span
                  v-else-if="user.userStatus === userStatus.UNUSABLE.code"
                  class="py-1 px-2 bg-red-100 text-red-900 font-semibold rounded-full"
                >
                  {{ userStatus.UNUSABLE.name }}
                </span>
              </td>
              <td class="td-grade">
                <p class="td-p whitespace-nowrap">{{ user.grade }}</p>
              </td>
              <td class="td-last-login-date">
                <p class="td-p whitespace-nowrap">{{ user.lastLoginDate.slice( 0, -4 ) }}</p>
              </td>
              <td class="td-book">
                <button
                  v-if="user.licenses.length"
                  class="td-link text-xs"
                  v-on:click="openModal(user.licenses)"
                >
                  <font-awesome-icon icon="file-alt" size="lg" />
                </button>
                <p v-else class="td-p whitespace-nowrap">-</p>
              </td>
              <td class="td-update">
                <router-link
                  :to="{
                    name: 'user-manage-update',
                    params: { schoolCd: user.schoolCd, loginId: user.loginId },
                  }"
                  class="td-link text-xs"
                >
                  <font-awesome-icon icon="edit" size="lg" />
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="
            h-12
            px-5
            py-2
            bg-white
            border-t
            flex flex-col
            items-center
            justify-between
          "
        >
          <div v-if="pageUserList.length">
            <ul class="flex pl-0 list-none rounded text-xs">
              <li v-if="nowPage !== 1" class="pagenation-box rounded-l" v-on:click="calcPageNum(nowPage - 1)">
                前
              </li>
              <li 
                v-for="page in pagenation"
                :key="page.id"
                v-bind:style="isFormat(page)"
                class="pagenation-box"
                v-on:click="calcPageNum(page)"
              >
                {{ page }}
              </li>
              <li v-if="nowPage !== pageNum" class="pagenation-box rounded-r" v-on:click="calcPageNum(nowPage + 1)">
                次
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- 教材詳細ダイアログ -->
  <div class="overlay" v-show="showContent" v-on:click.self="closeModal">
    <div class="modal-content p-4">
      <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
        <table class="min-w-full leading-normal text-xs">
          <thead>
            <tr>
              <th class="td-subject">教科名</th>
              <th class="td-lisenceName">ライセンス名</th>
              <th class="td-startDate">開始年月日</th>
              <th class="td-endDate">終了年月日</th>
            </tr>
          </thead>
          <tbody class="w-full modal-tdody">
            <tr v-for="license in licenses" :key="license.textbookName">
              <td class="td-subject">
                <p
                  class="whitespace-nowrap subject-label"
                  v-bind:style="{
                    backgroundColor: subjctCdToColorCd(license.subjectCd),
                  }"
                >
                  {{ subjctCdToName(license.subjectCd) }}
                </p>
              </td>
              <td class="td-lisenceName">
                <p class="td-p whitespace-nowrap">{{ license.textbookName }}</p>
              </td>
              <td class="td-startDate">
                <p class="td-p whitespace-nowrap">{{ license.startDate }}</p>
              </td>
              <td class="td-endDate">
                <p class="td-p whitespace-nowrap">{{ license.endDate }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer flex justify-end">
        <button class="btn btn-gray" v-on:click="closeModal">閉じる</button>
      </div>
    </div>
  </div>
</template>

<script>
import UserAuth from '../const/userAuthority';
import UserStatus from '../const/userStatus';
import Message from '../const/message';

export default {
  name: 'UserListTable',
  emits: ['checked'],
  props: {
    searchedUsers: { type: Array, default: () => [] },
    subjects: { type: Array, default: () => [] },
    schoolList: { type: Array, default: () => [] },
    isSysAdmin: { type: Boolean, default: () => false },
    isSchoolAdmin: { type: Boolean, default: () => false },
  },
  data() {
    return {
      userStatus: UserStatus,
      isAllSelected: false,
      showContent: false,
      licenses: [],
      checkedLoginIds: [],
      displayUsers: [],
      pageUserList: [],

      pageNum: 0,
      nowPage: 1,
      pagenation: [],
      selectedPage: [],
    };
  },
  methods: {
    openModal(licenses) {
      this.licenses = licenses;
      this.showContent = true;
    },
    closeModal() {
      this.licenses = [];
      this.showContent = false;
    },
    schoolCdToName(code) {
      return this.schoolList.find((s) => s.schoolCd === code).schoolName;
    },
    userAuthCdToName(code) {
      return UserAuth.getNameByCode(code);
    },
    subjctCdToName(code) {
      return this.subjects.find((s) => s.code === code).name;
    },
    subjctCdToColorCd(code) {
      return this.subjects.find((s) => s.code === code).colorCd;
    },
    calcTbodyHeight() {
      // 教職員の場合、ボタンがない分tbodyの高さを高く設定
      return this.$store.state.authority === UserAuth.TEACHER.code
        ? 'calc(100vh - 310px)'
        : 'calc(100vh - 405px)';
    },
    calcPageNum(value) {
      // クリックされた値を現在のページに上書き
      this.nowPage = value;

      let firstPage = this.nowPage - 2;
      let lastPage = this.nowPage + 2;
      if (this.pageNum <= 5) {
        // ページの総数が5ページ以下の場合
        firstPage = 1;
        lastPage = this.pageNum;
      } else if (this.nowPage === 1 || this.nowPage === 2) {
        // 現在のページが1,2ページ目の場合
        firstPage = 1;
        lastPage = 5;
      } else if (
        this.nowPage === this.pageNum - 1 ||
        this.nowPage === this.pageNum
      ) {
        // 現在のページが最終,最終の一つ前ページ目の場合
        firstPage = this.pageNum - 4;
        lastPage = this.pageNum;
      }
      this.pagenation = [];
      for (let i = firstPage; i <= lastPage; i++) {
        this.pagenation.push(i);
      }
    },
    isFormat(page) {
      // 追加するスタイル格納用配列初期化
      let addStyle = {};

      // 現在表示中のページかどうか
      if (page === this.nowPage) {
        // 現在表示中のページのスタイルを追加
        addStyle['color'] = 'white';
        addStyle['backgroundColor'] = 'gray';
      }

      // 現在表示中のページが１ページ目かどうか
      if (page === 1 && this.nowPage === 1) {
        addStyle['border-top-left-radius'] = '0.25rem';
        addStyle['border-bottom-left-radius'] = '0.25rem';
      }
      // 現在表示中のページが最終ページ目かどうか
      if (page === this.pageNum && this.nowPage === this.pageNum) {
        addStyle['border-top-right-radius'] = '0.25rem';
        addStyle['border-bottom-right-radius'] = '0.25rem';
      }

      this.selectedPage[page] = addStyle;

      return addStyle;
    },
  },
  watch: {
    searchedUsers(users) {
      // ページごとのユーザリスト初期化
      this.pageUserList = [];
      // テーブルに表示するユーザの配列を初期化
      this.displayUsers = [];
      // チェックされているユーザリストを初期化
      this.checkedLoginIds = [];
      // 現在ページの番号を初期化
      this.nowPage = 1;
      // ページネーション番号を初期化
      this.pagenation = [];

      // ページの表示件数ごとに全ユーザリストを分割
      while (users.length) this.pageUserList.push(users.splice(0, Message.USER.DISPLAY_NUMBER));

      // ページ数を取得
      this.pageNum = this.pageUserList.length;

      // ページネーション番号のセット
      if (this.pageNum <= 5) {
        // ページ番号が5以下の場合、ページ番号のみのページネーション番号をセット
        for (let i = 1; i <= this.pageNum; i++) {
          this.pagenation.push(i);
        }
      } else {
        // 5ページ目までのページネーション番号をセット
        this.pagenation = [1, 2, 3, 4, 5];
      }
      
      // 表示ページのデータ配列を更新
      this.displayUsers = this.pageUserList[this.nowPage - 1];
    },
    nowPage: function () {
      // 表示ページのデータ配列を更新
      this.displayUsers = this.pageUserList[this.nowPage - 1];
    },
    checkedLoginIds(loginIds) {
      this.$emit('checked', loginIds);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
thead,
tbody,
tr {
  @apply flex w-full;
}
tbody {
  @apply flex-col items-center overflow-y-scroll bg-gray-50;
}
tbody.modal-tdody {
  @apply flex-col items-center overflow-y-visible bg-gray-50;
}
th,
td {
  @apply px-2.5 border-gray-200 text-xs;
}
th {
  @apply py-2 border-b-2 bg-gray-100 font-semibold text-gray-600 tracking-wider flex items-center place-content-center;
}
td {
  @apply py-1 border-b bg-white flex items-center truncate;
}
th.td-update {
  @apply pr-5;
}
.td-checkbox {
  width: 6%;
  @apply place-content-center;
}
/* システム管理者のみ表示 */
.td-school {
  width: 15%;
}
.td-id {
  width: 11.5%;
}
.td-name {
  width: 19.5%;
}
.td-name-wide {
  width: 24.5%;
}
.td-auth {
  width: 9%;
}
.td-status {
  width: 10%;
  @apply place-content-center;
}
.td-grade {
  width: 6%;
}
.td-last-login-date {
  width: 14%;
}
/* システム管理者は非表示 */
.td-book {
  width: 12%;
}
.td-update {
  width: 11%;
}

.td-p {
  @apply text-gray-900 truncate;
}
.td-link {
  @apply text-gray-600 bg-gray-100 shadow py-1.5 px-3 rounded-md;
}
td.td-book {
  @apply place-content-center;
}
td.td-update {
  width: calc(11% - 17px);
  @apply pr-0 pl-3 place-content-center;
}
td.td-grade {
  @apply flex justify-end;
}
.pagenation-box {
  @apply block py-1.5 px-2.5 leading-tight bg-white border border-gray-300 text-gray-600 cursor-pointer;
}
.pagenation-box:hover {
  @apply bg-gray-200;
}
input[type='checkbox'] {
  @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}
select {
  @apply focus:ring-0;
}

.td-subject {
  @apply w-2/12 place-content-center;
}
.td-lisenceName {
  @apply w-6/12;
}
.td-startDate {
  @apply w-2/12 place-content-center;
}
.td-endDate {
  @apply w-2/12 place-content-center;
}

.subject-label {
  @apply ml-0 mr-2 py-1 px-1.5 rounded text-xs font-normal text-center text-white;
}
</style>
