<template>
  <div class="antialiased">
    <div class="mt-1">
      <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
        <table class="min-w-full leading-normal text-xs">
          <thead>
            <tr>
              <th class="td-checkbox"></th>
              <th class="td-schoolCode">学校コード</th>
              <th class="td-schoolName">学校名</th>
              <th class="td-pref">都道府県</th>
              <th class="td-user">ユーザ</th>
              <th class="td-lisence-school">
                ライセンス<br />
                割り当て
              </th>
              <th class="td-lisence-user">
                ユーザライセンス<br />
                割り当て
              </th>
              <th class="td-certificate">証明書発行</th>
              <th class="td-update">詳細/更新</th>
            </tr>
          </thead>
          <tbody
            class="overflow-y-scroll w-full"
            style="height: calc(100vh - 410px)"
          >
            <tr v-for="school in displaySchools" :key="school.code">
              <td class="td-checkbox">
                <input
                  type="checkbox"
                  v-model="checkedSchoolCds"
                  :value="school.schoolCd"
                />
              </td>
              <td class="td-schoolCode">
                <p class="td-p whitespace-nowrap">{{ school.schoolCd }}</p>
              </td>
              <td class="td-schoolName">
                <p class="td-p whitespace-nowrap" v-tooltip="{content: school.schoolName}">
                  {{ school.schoolName }}
                </p>
              </td>
              <td class="td-pref">
                <p class="td-p whitespace-nowrap">
                  {{ prefCdToName(school.prefCd) }}
                </p>
              </td>
              <td class="td-user">
                <p class="td-p whitespace-nowrap">
                  <router-link
                    :to="{ name: 'user-manage', params: {schoolCd: school.schoolCd} }"
                    @click="this.$store.commit('clearSearchUserKeys')"
                    class="td-link text-xs"
                  >
                    <font-awesome-icon icon="users" size="lg" />
                  </router-link>
                </p>
              </td>
              <td class="td-lisence-school">
                <p class="td-p whitespace-nowrap">
                  <router-link :to="{ name: 'license-manage-school', params: {schoolCd: school.schoolCd} }" class="td-link text-xs">
                    <font-awesome-icon icon="id-card" size="lg" />
                    <span class="mx-2">
                      <font-awesome-icon icon="caret-right" size="lg" />
                    </span>
                    <font-awesome-icon icon="school" size="lg" />
                  </router-link>
                </p>
              </td>
              <td class="td-lisence-user">
                <p class="td-p whitespace-nowrap">
                  <router-link 
                    :to="{ name: 'license-manage-user', params: {schoolCd: school.schoolCd} }"
                    @click="this.$store.commit('clearSearchUserLicenseKeys')"
                    class="td-link text-xs"
                  >
                    <font-awesome-icon icon="id-card" size="lg" />
                    <span class="mx-2">
                      <font-awesome-icon icon="caret-right" size="lg" />
                    </span>
                    <font-awesome-icon icon="user-friends" size="lg" />
                  </router-link>
                </p>
              </td>
              <td class="td-certificate">
                <button @click="downloadLicense(school.schoolCd, school.schoolName)" class="td-link text-xs">
                  <font-awesome-icon icon="file-download" size="lg" />
                </button>
              </td>
              <td class="td-update">
                <router-link 
                  :to="{ name: 'school-manage-update', params: {schoolCd: school.schoolCd} }"
                  class="td-link text-xs"
                >
                  <font-awesome-icon icon="edit" size="lg" />
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="
            h-12
            px-5
            py-2
            bg-white
            border-t
            flex flex-col
            items-center
            justify-between
          "
        >
          
          <div v-if="pageSchoolList.length">
            <ul class="flex pl-0 list-none rounded text-xs">
              <li v-if="nowPage !== 1" class="pagenation-box rounded-l" v-on:click="calcPageNum(nowPage - 1)">
                前
              </li>
              <li 
                v-for="page in pagenation"
                :key="page.id"
                v-bind:style="isFormat(page)"
                class="pagenation-box"
                v-on:click="calcPageNum(page)"
              >
                {{ page }}
              </li>
              <li v-if="nowPage !== pageNum" class="pagenation-box rounded-r" v-on:click="calcPageNum(nowPage + 1)">
                次
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadLicense } from '../api';
import prefList from '../const/prefList';
import Message from '../const/message';
import DownloadFile from '../utils/downloadFile';
import Authorize from '../utils/authorize';

export default {
  name: 'SchoolListTable',
  emits: ['delete'],
  props: {
    searchedSchools: {type: Array, default: () => []},
  },
  data() {
    return {
      checkedSchoolCds: [],
      prefList: prefList,
      displaySchools: [],
      pageSchoolList: [],
      
      pageNum: 0,
      nowPage: 1,
      pagenation: [],
      selectedPage: [],
    };
  },
  methods: {
    prefCdToName(code) {
      return this.prefList.find((p) => p.code === code).name;
    },
    async downloadLicense(schoolCd, schoolName){
      if (Date.now() / 1000 > this.$store.state.tokenExp){
        if (!await Authorize.isAuth()) {
          // 認証失敗：ログイン画面へ遷移
          this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
          return false;
        }
      }
      // 証明書ダウンロードAPI呼び出し
      downloadLicense( function(rsp){
        // ファイル出力
        DownloadFile.download(rsp, DownloadFile.LICENSE, schoolName);
      }, schoolCd, this.$route.path);
    },
    calcPageNum(value) {
      // クリックされた値を現在のページに上書き
      this.nowPage = value;

      let firstPage = this.nowPage - 2;
      let lastPage = this.nowPage + 2;
      if (this.pageNum <= 5) {
        // ページの総数が5ページ以下の場合
        firstPage = 1;
        lastPage = this.pageNum;
      } else if (this.nowPage === 1 || this.nowPage === 2) {
        // 現在のページが1,2ページ目の場合
        firstPage = 1;
        lastPage = 5;
      } else if (
        this.nowPage === this.pageNum - 1 ||
        this.nowPage === this.pageNum
      ) {
        // 現在のページが最終,最終の一つ前ページ目の場合
        firstPage = this.pageNum - 4;
        lastPage = this.pageNum;
      }
      this.pagenation = [];
      for (let i = firstPage; i <= lastPage; i++) {
        this.pagenation.push(i);
      }
    },
    isFormat(page) {
      // 追加するスタイル格納用配列初期化
      let addStyle = {};

      // 現在表示中のページかどうか
      if (page === this.nowPage) {
        // 現在表示中のページのスタイルを追加
        addStyle['color'] = 'white';
        addStyle['backgroundColor'] = 'gray';
      }

      // 現在表示中のページが１ページ目かどうか
      if (page === 1 && this.nowPage === 1) {
        addStyle['border-top-left-radius'] = '0.25rem';
        addStyle['border-bottom-left-radius'] = '0.25rem';
      }
      // 現在表示中のページが最終ページ目かどうか
      if (page === this.pageNum && this.nowPage === this.pageNum) {
        addStyle['border-top-right-radius'] = '0.25rem';
        addStyle['border-bottom-right-radius'] = '0.25rem';
      }

      this.selectedPage[page] = addStyle;

      return addStyle;
    },
  },
  watch: {
    searchedSchools(schools){      
      // ページごとの学校リスト初期化
      this.pageSchoolList = [];
      // テーブルに表示する学校の配列を初期化
      this.displaySchools = [];
      // チェックされている学校リストを初期化
      this.checkedSchoolCds = [];
      // 現在ページの番号を初期化
      this.nowPage = 1;
      // ページネーション番号を初期化
      this.pagenation = [];

      // ページの表示件数ごとに全学校リストを分割
      while (schools.length) this.pageSchoolList.push(schools.splice(0, Message.SCHOOL.DISPLAY_NUMBER));

      // ページ数を取得
      this.pageNum = this.pageSchoolList.length;

      // ページネーション番号のセット
      if (this.pageNum <= 5) {
        // ページ番号が5以下の場合、ページ番号のみのページネーション番号をセット
        for (let i = 1; i <= this.pageNum; i++) {
          this.pagenation.push(i);
        }
      } else {
        // 5ページ目までのページネーション番号をセット
        this.pagenation = [1, 2, 3, 4, 5];
      }

      // 表示ページのデータ配列を更新
      this.displaySchools = this.pageSchoolList[this.nowPage - 1];
    },
    nowPage: function () {
      // 表示ページのデータ配列を更新
      this.displaySchools = this.pageSchoolList[this.nowPage - 1];
    },
    checkedSchoolCds(schoolCds) {
      this.$emit('delete', schoolCds);
    }
  }
};
</script>

<style scoped>
thead,
tbody,
tr {
  @apply flex w-full;
}
tbody {
  @apply flex-col items-center overflow-y-scroll bg-gray-50;
}
th,
td {
  @apply px-3 border-gray-200 text-xs;
}
th {
  @apply py-1 text-center border-b-2 bg-gray-100 font-semibold text-gray-600 tracking-wider flex items-center place-content-center;
}
th.td-update {
  @apply flex items-center justify-start pl-5;
}

td {
  @apply pt-1.5 pb-1 border-b bg-white flex items-center;
}
.td-checkbox {
  width: 6%;
  @apply place-content-center;
}
.td-schoolCode {
  width: 10%;
}
.td-schoolName {
  width: 27%;
}
.td-pref {
  width: 8%;
}
.td-user {
  width: 7%;
}
.td-lisence-school {
  width: 12%;
}
.td-lisence-user {
  width: 13%;
}
.td-certificate {
  width: 9%;
}
.td-update {
  width: 10%;
}

td.td-update {
  width: calc(10% - 17px);
  @apply pr-0 pl-3 text-center;
}

.td-lisence-school,
.td-lisence-user,
.td-certificate,
.td-user,
.td-update {
  @apply place-content-center;
}
.td-p {
  @apply text-gray-900 align-middle;
}
.td-link {
  @apply text-gray-600 bg-gray-100 shadow py-1.5 px-3 rounded-md;
}

.pagenation-box {
  @apply block py-1.5 px-2.5 leading-tight bg-white border border-gray-300 text-gray-600 cursor-pointer;
}
.pagenation-box:hover {
  @apply bg-gray-200;
}

input[type='checkbox'] {
  @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}
select {
  @apply focus:ring-0;
}
</style>
