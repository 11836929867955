<template>
  <div class="antialiased pt-6">
    <span class="text-gray-700 text-lg font-bold">
      <font-awesome-icon icon="caret-down" class="ml-1" />
      期限切れライセンス一覧
    </span>
    <div class="mt-1 inline-block min-w-full shadow rounded-lg overflow-hidden">
      <table class="min-w-full leading-normal text-xs">
        <thead>
          <tr>
            <th class="td-status"></th>
            <th class="td-licenseCd">ライセンス<br />コード</th>
            <th class="td-lisenceName">ライセンス名</th>
            <th class="td-usedCnt md:px-0">使用数</th>
            <th class="td-totalCnt">総数</th>
            <th class="td-purchaseDate">購入年月日</th>
            <th class="td-startDate">開始年月日</th>
            <th class="td-endDate">終了年月日</th>
            <th class="td-delBtn bg-gray-300"></th>
          </tr>
        </thead>
        <tbody class="w-full">
          <tr
            v-for="invalidLicense in userLicenses.invalidLicenses"
            :key="invalidLicense.licenseCd"
          >
            <td class="td-status"></td>
            <td class="td-licenseCd">
              <p class="td-p whitespace-nowrap">
                {{ invalidLicense.licenseCd }}
              </p>
            </td>
            <td class="td-lisenceName">
              <p class="td-p whitespace-pre-wrap overflow-hidden" style="overflow-wrap: break-word">
                {{ licenseCdToName(invalidLicense.licenseCd) }}
              </p>
            </td>
            <td class="td-usedCnt">
              <p class="td-p whitespace-nowrap">
                {{ invalidLicense.usedCnt }}
              </p>
            </td>
            <td class="td-totalCnt">
              {{ invalidLicense.totalCnt }}
            </td>
            <td class="td-purchaseDate">
              {{ invalidLicense.purchaseDate.replace('-', '年').replace('-', '月') + '日' }}
            </td>
            <td class="td-startDate">
              {{
                invalidLicense.startDate.replace('-', '年').replace('-', '月') +
                '日'
              }}
            </td>
            <td class="td-endDate">
              {{
                invalidLicense.endDate.replace('-', '年').replace('-', '月') +
                '日'
              }}
            </td>
            <td class="td-delBtn"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SchoolLicenseManageInvalidTable',
  props: {
    userLicenses: { type: Object, default: () => {} },
  },
  data() {
    return {};
  },
  methods: {
    licenseCdToName(code) {
      return this.userLicenses.allLicenses.find((s) => s.licenseCd === code)
        .textbookName;
    },
  },
};
</script>

<style scoped>
thead,
tbody,
tr {
  @apply flex w-full;
}
tbody {
  @apply flex-col items-center bg-gray-50;
}
th,
td {
  @apply px-3 border-gray-200 text-xs;
}
th {
  @apply py-2 border-b-2 bg-gray-100 font-semibold text-gray-600 tracking-wider flex items-center place-content-center;
}
td {
  @apply py-2.5 border-b bg-white flex items-center;
}
.td-status {
  width: 4%;
}
.td-licenseCd {
  @apply place-content-center;
  width: 10%;
}
.td-lisenceName {
  width: 28%;
}
.td-usedCnt {
  width: 6%;
}
.td-totalCnt {
  width: 8%;
}
.td-purchaseDate,
.td-startDate,
.td-endDate {
  @apply place-content-center;
  width: 13%;
}
.td-delBtn {
  width: 6%;
}

.td-p {
  @apply text-gray-900;
}

td.td-usedCnt,
td.td-totalCnt {
  @apply flex justify-end text-sm;
}
td.td-licenseCd {
  @apply text-sm;
}
th.td-usedCnt {
  @apply md:px-0;
}
</style>
