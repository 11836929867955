<template>
  <div class="max-w-2xl mx-auto lg:max-w-4xl md:max-w-4xl">
    <Title msg="ライセンス割り当て" submsg="Assign License to School" />
    <div class="pb-10">
      <Form :userLicenses="userLicenses" :schoolCd="schoolCd" @parent-event="addColumn" />
      <ValidTable
        :userLicenses="userLicenses"
        :addLicenseInfo="addLicenseInfo"
        @parent-event="update"
      />
      <InvalidTable :userLicenses="userLicenses" />
    </div>
  </div>
</template>

<script>
import { searchLicenses, updateLicenses } from '../api';
import Title from '../components/TheTitle.vue';
import Form from '../components/SchoolLicenseManageForm.vue';
import ValidTable from '../components/SchoolLicenseManageValidTable.vue';
import InvalidTable from '../components/SchoolLicenseManageInvalidTable.vue';
import UserAuth from '../const/userAuthority';
import Message from '../const/message';
import Authorize from '../utils/authorize';

export default {
  name: 'BookManageSchool',
  components: {
    Title,
    Form,
    ValidTable,
    InvalidTable,
  },
  props: {},
  data() {
    return {
      schoolCd: '',
      addLicenseCnt: 0,
      userLicenses: {},
      addLicenseInfo: {},
      isUnsent: false
    };
  },
  created: function () {
    // 権限チェック
    if (this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code) {
      // 学校コードをURLから取得
      this.schoolCd = this.$route.params.schoolCd;
      // ライセンス情報取得
      this.searchLicenses(this.schoolCd);
    } else {
      // エラーページに遷移
      this.$router.push({
        name: 'not-found',
        params: { catchAll: 'not-found' },
      });
      // 処理強制終了
      return false;
    }
  },
  methods: {
    /** テーブルへカラムを追加する */
    addColumn(value) {
      let licenseInfo = {};
      // 全ライセンス情報からライセンス情報を取得
      this.userLicenses.allLicenses.forEach((license) => {
        if (license.licenseCd === value.code) {
          licenseInfo = {
            // 追加カラム識別のため、idをセット
            id: this.addLicenseCnt,
            licenseCd: value.code,
            licenseName: value.name.substr(8),
          };
        }
      });
      // dataに格納
      this.addLicenseInfo = licenseInfo;
      // idをインクリメント
      this.addLicenseCnt++;
      this.isUnsent = true;
    },
    /** ライセンス更新 */
    async update(licensList) {
      if (Date.now() / 1000 > this.$store.state.tokenExp){
        if (!await Authorize.isAuth()) {
          // 認証失敗：ログイン画面へ遷移
          this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
          return false;
        }
      }
      let self = this;
      // ライセンス更新API呼び出し
      updateLicenses(
        function (rspBody) {
          // 正常レスポンスかどうか
          if (rspBody.result.code === 0) {
            self.isUnsent = false;
            // ライセンス情報再取得
            self.searchLicenses(self.schoolCd);
          } else {
            // 失敗した場合、エラーメッセージをポップアップにセットして表示
            self.$store.commit('setMessages', rspBody.result.messages);
            self.$store.commit('setCancelBtnHide', true);
            self.$store.commit('setIsModalOpen', true);
            self.$store.commit('setOkAction', function () {
              self.searchLicenses(self.schoolCd);
            });      
          }
        },
        this.schoolCd,
        licensList,
        this.$route.path
      );
    },
    searchLicenses(schoolCd) {
      let self = this;
      searchLicenses(function (rspBody) {
        // 成功した場合、dataに格納
        self.userLicenses = rspBody.detail;
      }, schoolCd, this.$route.path);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isUnsent && to.name != 'maintenance') {
      this.$store.commit('setMessages', [Message.INPUT_VALUE_CLEAR]);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setIsModalOpen', true);
      this.$store.commit('setOkAction', function () {
        next();
      });
      this.$store.commit('setCancelAction', function () {
        next(false);
      });
    } else {
      next();
    }
  },
};
</script>

<style scoped></style>
