import axios from 'axios';
import store from '../store';
import router from '../router';
import Cookie from '../utils/cookie';

/**
 * レスポンスデータの加工
 */
function processResponse(response) {
  return {
    statusCode: response.status,
    body: response.data,
    headers: response.headers,
  };
}

/**
 * Http通信用クラス
 */
export default class Api {
  /**
   * Http通信を行うためのインスタンスを作成
   * APIが複数ある場合にも対応
   * @param {string} baseUrl ベースURL
   * @param {string} option.headers デフォルトヘッダー情報
   */
  constructor(baseUrl, headers) {
    this.baseUrl = baseUrl;
    this.instance = axios.create({
      baseURL: baseUrl,
      headers: headers,
    });
  }

  /**
   * GETメソッドの呼び出し
   * @param {string} url URLパス
   * @param {object} params リクエストパラメータ
   * @param {string} fromPath 呼び出し元のパス（401エラー時に使用）
   * @param {boolean} isResponseBlob レスポンスのbodyをblobに設定するか
   */
  async get(url, params, fromPath = '', isResponseBlob = false) {
    store.commit('setLoading', true);
    const config = {
      params,
    };
    if (isResponseBlob)
      this.instance.defaults.headers.common['responseType'] = 'blob';
    this.instance.defaults.headers.common['Authorization'] =
      localStorage.getItem('iToken');
    this.instance.defaults.withCredentials = true;

    const self = this;
    const response = await this.instance
      .get(url, config)
      .catch(function (error) {
        // エラー処理
        self.errorHandler(error, fromPath);
      });

    store.commit('setLoading', false);

    if (response && response.status === 200) {
      return processResponse(response);
    }
  }

  /**
   * POSTメソッド呼び出し
   * @param {string} url URLパス
   * @param {object} data リクエストボディ
   * @param {string} fromPath 呼び出し元のパス（401エラー時に使用）
   * @param {boolean} isIgnoreError エラーを無視するかどうか
   */
  async post(url, data, fromPath = '', isIgnoreError = false) {
    store.commit('setLoading', true);

    this.instance.defaults.headers.common['Authorization'] =
      localStorage.getItem('iToken');
    this.instance.defaults.withCredentials = true;

    const self = this;
    const response = await this.instance
      .post(url, data)
      .catch(function (error) {
        // エラー処理
        if (!isIgnoreError) self.errorHandler(error, fromPath);
      });
    store.commit('setLoading', false);

    if (response && response.status === 200) {
      return processResponse(response);
    }
  }

  /**
   * PUTメソッド呼び出し
   * @param {string} url URLパス
   * @param {object} data リクエストボディ
   * @param {string} fromPath 呼び出し元のパス（401エラー時に使用）
   * @param {boolean} isLoading ローディング画面を表示するかどうか
   */
  async put(url, data, fromPath = '', isLoading = true) {
    if (isLoading) store.commit('setLoading', true);

    this.instance.defaults.headers.common['Authorization'] =
      localStorage.getItem('iToken');
    this.instance.defaults.withCredentials = true;
    if (typeof data === FormData) {
      this.instance.defaults.headers.common['Content-Type'] =
        'multipart/form-data';
    }

    const self = this;
    const response = await this.instance.put(url, data).catch(function (error) {
      // エラー処理
      self.errorHandler(error, fromPath);
    });

    if (isLoading) store.commit('setLoading', false);

    if (response && response.status === 200) {
      return processResponse(response);
    }
  }

  /**
   * S3 putメソッド呼び出し
   * @param {string} url URLパス
   * @param {object} data リクエストボディ
   */
  async s3Put(url, data) {
    try {
      const response = await axios
        .put(url, data, {
          headers: {
            'Content-Type': 'application/zip',
            'x-amz-acl': 'bucket-owner-full-control',
          },
        })
        .catch(function (error) {
          throw error;
        });
      return processResponse(response);
    } catch (error) {
      if (error.response) {
        return processResponse(error.response);
      } else {
        let res = {
          statusCode: 500,
          body: 'Internal Server Error',
        };
        return res;
      }
    }
  }

  /**
   * DELETEメソッド呼び出し
   * @param {string} url URLパス
   * @param {object} params リクエストボディ
   * @param {string} fromPath 呼び出し元のパス（401エラー時に使用）
   */
  async delete(url, params, fromPath = '') {
    store.commit('setLoading', true);
    const data = {
      data: params,
    };

    this.instance.defaults.headers.common['Authorization'] =
      localStorage.getItem('iToken');
    this.instance.defaults.withCredentials = true;

    const self = this;
    const response = await this.instance
      .delete(url, data)
      .catch(function (error) {
        // エラー処理
        self.errorHandler(error, fromPath);
      });

    store.commit('setLoading', false);
    if (response && response.status === 200) {
      return processResponse(response);
    }
  }

  /**
   * エラーハンドリング
   * @param {Error} error エラーオブジェクト
   * @param {string} fromPath 呼び出し元のパス
   */
  errorHandler(error, fromPath) {
    store.commit('setLoading', false);

    // 遷移先をエラーページ(メンテナンス画面)に指定
    let toPath = {
      name: 'maintenance',
    };
    // 401エラーの場合
    if (error.response && error.response.status === 401) {
      // storeに保持している情報を初期化
      localStorage.removeItem('plusManabi');
      // localStrageに保持している情報を初期化
      localStorage.removeItem('iToken');
      localStorage.removeItem('aToken');
      // cookieからtokenを削除
      Cookie.deleteToken();
      //  遷移先をログイン画面で上書き
      toPath = {
        name: 'login',
      };
      // 遷移元パスがある場合はリダイレクトURLに追加
      if (fromPath) {
        toPath.query = { redirect: fromPath };
      }
    }
    // 指定した遷移先へ遷移
    router.push(toPath);
  }
}
