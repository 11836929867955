import Message from '../const/message';
import jconv from 'jconv';

export default class DownloadFile {
  static USER_CARD = { fileName: 'ユーザ情報カード_{0}.pdf' };
  static LICENSE = { fileName: '{1}-証明書_{0}.xlsx' };
  static USER_CSV = { fileName: 'p-manabi_users_{0}.csv' };
  static ERROR_CSV = { fileName: 'p-manabi_users_error_{0}.csv' };
  static SCHOOL_CSV = { fileName: 'p-manabi_schools_{0}.csv' };

  /**
   * APIから受け取ったファイルをダウンロード
   * @param {Response} response APIからのレスポンス
   * @param {object} downloadType ダウンロードするファイルの種類
   * @param {string=null} schoolName 学校名
   */
  static download (response, downloadType, schoolName = '') {
    const contentType = response.headers['content-type'];
    let blob = new Blob();
    if (contentType.includes('text/csv')) {
      // csvデータをSJISにエンコードし、Blobを生成
      blob = new Blob([jconv.encode(response.body, 'SJIS')], { type: contentType });
    } else if (contentType.includes('application/pdf')) {
      blob = new Blob([response], { type: contentType });
    } else {
      // base64文字列をarrayBufferに変換
      const arrBuffer = this.base64ToArrayBuffer(response.body);
      // arrayBufferからBlobを生成
      blob = new Blob([arrBuffer], { type: contentType });
    }
    // BlobからオブジェクトURLを生成
    const url = URL.createObjectURL(blob);
    this.downloadUrl(url, downloadType, schoolName);
  }

  /**
   * URLからファイルをダウンロード
   * @param {string} url ダウンロードするファイルのURL
   * @param {object} downloadType ダウンロードするファイルの種類
   * @param {string=null} schoolName 学校名
   */
  static downloadUrl (url, downloadType, schoolName = '') {
    // DL用の疑似aタグを作成
    const link = document.createElement('a');
    document.body.appendChild(link);
    // 現在日時の取得
    const now = new Date();
    // 年月日の型を作成
    let format = 'yyyyMMdd';
    // 作成した型に年月日をreplace
    format = format.replace(/yyyy/, now.getFullYear());
    format = format.replace(/MM/, ('0' + (now.getMonth() + 1)).slice(-2));
    format = format.replace(/dd/, ('0' + now.getDate()).slice(-2));
    // ファイル名を設定
    link.download = Message.generateMessage(downloadType.fileName, [
      format,
      schoolName,
    ]);
    link.href = url;
    // clickイベントが発火、「名前を付けて保存」が開かれる
    link.click();
    // 後処理
    link.remove();
    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 1e4);
  }

  /**
   * base64文字列をarrayBufferに変換
   * @param {string} base64 base64文字列
   * @returns 変換したarrayBuffer
   */
  static base64ToArrayBuffer (base64) {
    let binaryString = atob(base64);
    let binaryLen = binaryString.length;
    let bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      let ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }
}
