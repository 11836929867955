<template>
  <div class="max-w-5xl mx-auto">
    <Title msg="ユーザ閲覧履歴" submsg="Browsing History" />
    <div class="flex justify-center pb-5">
      <div class="w-full md:w-2/3 my-1">
        <div class="mt-2">
          <div class="mb-2" v-if="isSysAdmin">
            <span
              v-if="usageStatsDetail.schoolName"
              class="
                flex-1
                border-b border-gray-500
                max-w-max
                pl-2
                pr-3
                pb-1
                mb-2
                font-semibold
              "
            >
              {{ usageStatsDetail.schoolName }}
            </span>
          </div>
          <div class="flex md:flex-row flex-col mb-3">
            <span
              v-if="usageStatsDetail.viewName"
              class="
                flex-1
                border-b border-gray-500
                max-w-max
                pl-2
                pr-3
                md:pr-0
                pb-1
                mb-2
                font-semibold
              "
            >
              {{ usageStatsDetail.viewName }}
            </span>
            <span
              v-if="usageStatsDetail.usageStatsDetail[0].timestamp"
              class="
                flex-1
                border-b border-gray-500
                max-w-max
                pl-2
                pr-3
                pb-1
                mb-2
                font-semibold
              "
            >
              {{ usageStatsDetail.usageStatsDetail[0].timestamp.slice(0, 10) }}
            </span>
            <span class="flex flex-1 md:justify-end">
              <button
                v-if="usageStatsDetail.viewName"
                @click="$router.go(-1)"
                type="button"
                name="back-button"
                class="button btn-gray"
              >
                戻る
              </button>
            </span>
          </div>
        </div>
        <UsageStatsDetailTable
          v-if="usageStatsDetail.viewName"
          :accessLogs="usageStatsDetail"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { searchUsageStatsDetail } from '../api';
import UserAuth from '../const/userAuthority';
import Title from '../components/TheTitle.vue';
import UsageStatsDetailTable from '../components/UsageStatsDetailTable.vue';

export default {
  name: 'UsageStatsDetail',
  components: {
    Title,
    UsageStatsDetailTable,
  },

  data() {
    return {
      isSysAdmin: this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code,
      usageStatsDetail: { usageStatsDetail: [{ timestamp: '' }] },
    };
  },
  created: function () {
    // 権限チェック
    if (
      (this.$store.state.authority === UserAuth.TEACHER.code &&
        this.$store.state.schoolCd === this.$route.params.schoolCd) ||
      (this.$store.state.authority === UserAuth.SCHOOL_ADMIN.code &&
        this.$store.state.schoolCd === this.$route.params.schoolCd) ||
      this.isSysAdmin
    ) {
      // 学校コード、学年、画面ID、日付を取得
      let schoolCd = this.$route.params.schoolCd;
      let grade = this.$route.query.grade;
      let viewId = this.$route.params.id;
      let date = this.$route.params.date.replace(/\//g, '-');

      // ユーザ閲覧履歴情報を取得
      let self = this;
      searchUsageStatsDetail(
        function (rspBody) {
          self.usageStatsDetail = rspBody.detail;
        },
        schoolCd,
        grade,
        viewId,
        date,
        this.$route.path
      );
    } else {
      // エラーページに遷移
      this.$router.push({
        name: 'not-found',
        params: { catchAll: 'not-found' },
      });
      // 処理強制終了
      return false;
    }
  },
  methods: {},
};
</script>

<style scoped>
.button {
  @apply h-8 w-28 font-semibold rounded focus:outline-none focus:ring focus:ring-opacity-50;
}
</style>
