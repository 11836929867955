<template>
  <div class="max-w-5xl mx-auto">
    <Title msg="学校一覧" submsg="School List" />
    <div class="flex my-3">
      <router-link to="/manage/school/register" id="school-register">
        <button class="btn btn-indigo mr-3">新規登録</button>
      </router-link>
      <button @click="downloadSchoolsCsv" class="btn btn-indigo mr-4">
        CSV出力
      </button>
    </div>
    <Form ref="form" :handleSearch="search" :schoolList="allSchools" :schoolTypeList="schoolTypeList"/>
    <div class="mt-4">
      <button
        @click="batchDelete"
        v-tooltip="{content: 'チェックボックスを選択して一括削除'}"
        class="w-32 text-sm font-semibold text-white bg-gray-500 border border-gray-300 shadow py-1.5 px-5 rounded"
      >
        <font-awesome-icon icon="trash-alt" class="mr-1" />
        一括削除
      </button>
    </div>
    <Table :searchedSchools="searchedSchools" @delete="getDeleteSchoolCds" />
  </div>
</template>

<script>
import { searchSchools, searchSchoolTypes, deleteSchools, downloadSchoolsCsv } from '../api';
import Title from '../components/TheTitle.vue';
import Form from '../components/SchoolListForm.vue';
import Table from '../components/SchoolListTable.vue';
import DownloadFile from '../utils/downloadFile';
import Message from '../const/message';
import UserAuth from '../const/userAuthority';
import Authorize from '../utils/authorize';

export default {
  name: 'SchoolManageSearch',
  components: {
    Title,
    Form,
    Table,
  },
  data() {
    return {
      searchedSchools: [],
      allSchools: [],
      deleteSchoolCds: [],
      schoolTypeList: [],

      errMsgs: [],
    };
  },
  created: async function () {
    // 権限チェック
    if (this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code) {
      // 学校区分を全件取得
      const schoolTypeRspBody = await searchSchoolTypes(this.$route.path);
      this.schoolTypeList = schoolTypeRspBody.detail;
      // 学校コードを全件取得
      const schoolsRspBody = await searchSchools(this.$route.path);
      this.allSchools = schoolsRspBody.detail;
    } else {
      // エラーページに遷移
      this.$router.push({
        name: 'not-found',
        params: { catchAll: 'not-found' },
      });
      // 処理強制終了
      return false;
    }
  },
  methods: {
    async search(schoolCd, prefCd, schoolType) {
      if (Date.now() / 1000 > this.$store.state.tokenExp){
        if (!await Authorize.isAuth()) {
          // 認証失敗：ログイン画面へ遷移
          this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
          return false;
        }
      }
      let self = this;
      const rspBody = await searchSchools(this.$route.path, schoolCd, prefCd, schoolType);
      if (rspBody.result.messages.length) {
        self.searchedSchools = [];
        // 失敗した場合、エラーメッセージをポップアップにセットして表示
        self.$store.commit('setMessages', rspBody.result.messages);
        self.$store.commit('setCancelBtnHide', true);
        self.$store.commit('setIsModalOpen', true);
      } else {
        // 成功した場合、取得したレコードを渡す
        self.searchedSchools = rspBody.detail;
      }
    },
    batchDelete() {
      let self = this;
      const deleteCnt = self.deleteSchoolCds.length;
      if (deleteCnt === 0) {
        // 削除対象が存在しない場合はエラーメッセージを表示
        self.$store.commit('setMessages', [
          Message.generateMessage(Message.DELETE_NOT_SELECT, [
            Message.PROC_TYPE.DELETE,
            Message.SCHOOL.NAME,
          ]),
        ]);
        self.$store.commit('setCancelBtnHide', true);
        self.$store.commit('setIsModalOpen', true);
        return false;
      }

      // 削除する学校コードの配列をさらに5件ごとに配列に格納
      let deleteIdsSets = [];

      for (let i = 0; i < self.deleteSchoolCds.length; i = i + 5) {
        const sliceLength = i + 5 <= deleteCnt ? i + 5 : deleteCnt;
        deleteIdsSets.push(self.deleteSchoolCds.slice(i, sliceLength));
      }
      // 5件ごとに配列に格納された学校コードを、それぞれカンマ区切りで文字列結合
      let deleteSchoolCdsStringSets = [];
      deleteIdsSets.forEach((deleteIdsSet) => {
        deleteSchoolCdsStringSets.push(deleteIdsSet.join(', '));
      });
      // 警告メッセージの初期化
      let warnMsgs = [];
      warnMsgs.push(
        Message.generateMessage(Message.DELETE_WARN, [
          deleteCnt,
          Message.PROC_TYPE.DELETE,
        ])
      );
      // 警告メッセージに削除する学校コードのリストを追加
      warnMsgs = warnMsgs.concat(deleteSchoolCdsStringSets);

      // 削除確認ポップアップを表示する
      this.$store.commit('setMessages', warnMsgs);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setIsModalOpen', true);
      this.$store.commit('setOkAction', async function () {
        if (Date.now() / 1000 > self.$store.state.tokenExp){
          if (!await Authorize.isAuth()) {
            // 認証失敗：ログイン画面へ遷移
            self.$router.push({ name: 'login', query: { redirect: self.$route.path } });
            return false;
          }
        }
        deleteSchools(function (rspBody) {
          if (rspBody.result.code === 0) {
            // 成功した場合、削除完了メッセージをポップアップにセット
            self.$store.commit('setMessages', [
              Message.generateMessage(Message.COMPLETED, [
                Message.PROC_TYPE.DELETE,
              ]),
            ]);
            self.$store.commit('setIsModalInfo', true);
          } else {
            // 失敗した場合、エラーメッセージをポップアップにセット
            self.$store.commit('setMessages', rspBody.result.messages);
          }
          // OK押下後、フォームから検索条件を取得後、フォーム内で検索API呼び出し
          self.$store.commit('setOkAction', async function () {
            // 学校コードを全件取得
            const allSchoolsRspBody = await searchSchools(self.$route.path);
            self.allSchools = allSchoolsRspBody.detail;
            // 入力済みの検索条件で再検索
            self.$refs.form.search();
          });
          // ポップアップを表示
          self.$store.commit('setCancelBtnHide', true);
          self.$store.commit('setIsModalOpen', true);
        }, self.deleteSchoolCds, self.$route.path);
      });
    },
    getDeleteSchoolCds(array) {
      this.deleteSchoolCds = array;
    },
    async downloadSchoolsCsv() {
      if (Date.now() / 1000 > this.$store.state.tokenExp){
        if (!await Authorize.isAuth()) {
          // 認証失敗：ログイン画面へ遷移
          this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
          return false;
        }
      }
      // 学校CSVダウンロードAPI呼び出し
      downloadSchoolsCsv(function (rsp) {
        // ファイル出力
        DownloadFile.download(rsp, DownloadFile.SCHOOL_CSV);
      }, this.$route.path);
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.deleteSchoolCds.length && to.name != 'maintenance') {
      this.$store.commit('setMessages', [Message.TRANSITION_WARN]);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setIsModalOpen', true);
      this.$store.commit('setOkAction', function () {
        next();
      });
    } else {
      next();
    }
  },
};
</script>

<style scoped>
input[type='file'] {
  @apply hidden;
}
.button {
  @apply font-semibold w-32 rounded py-2 px-2 focus:outline-none focus:ring focus:ring-opacity-50;
}
</style>
