<template>
  <div
    class="
      z-30
      fixed
      w-full
      grid
      place-items-center
      bg-white
      border-indigo-800 border-b-4
    "
    style="height: 61px"
  >
    <img
      class="py-3 md:py-2 w-24 md:w-32 ml-24 md:ml-0 place-self-start md:place-self-center"
      alt="logo"
      src="../assets/logo.png"
    />
    <nav id="nav" v-click-outside="onClickOutside" v-if="$route.path !== '/maintenance'">
      <ul>
        <li>
          <div
            v-on:click="open"
            class="
              header-right-icon
              text-indigo-800 text-sm
              place-content-center
              flex flex-row
            "
          >
            <div class="mr-6 text- font-bold flex-1 flex-col">
              <span class="flex justify-end"> ログインID：{{loginId}} </span>
              <span class="flex justify-end"> {{name}} さん </span>
            </div>
            <font-awesome-icon icon="user-circle" size="3x" class="flex" />
            <ul class="dropdown" :class="{ isOpen }">
              <li v-if="isNotStudent && !isSsoLogin">
                <router-link :to="{ path: '/password-change', query: { redirect: $route.path } }">
                  <span class="mr-2">
                    <font-awesome-icon icon="unlock-alt" size="lg" />
                  </span>
                  パスワード変更
                </router-link>
              </li>
              <li>
                <a v-on:click='logout'>
                  <span class="mr-2">
                    <font-awesome-icon icon="sign-out-alt" size="lg" />
                  </span>
                  ログアウト
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { logout } from '../api';
import UserAuth from '../const/userAuthority';
import vClickOutside from 'vue3-click-outside';
import Cookie from '../utils/cookie';
import { isNavigationFailure, NavigationFailureType } from 'vue-router';

export default {
  name: 'Header',
  props: {
    msg: String,
  },
  data() {
    return {
      isOpen: false,
      loginId: this.$store.state.loginId,
      name: this.$store.state.displayName,
      isNotStudent: this.$store.state.authority !== UserAuth.STUDENT.code,
      isSsoLogin: this.$store.state.isSsoLogin,
    };
  },
  methods: {
    open() {
      this.isOpen = !this.isOpen;
    },
    onClickOutside() {
      this.isOpen = false;
    },
    logout(){
      let self = this;
      // ログアウトAPI呼び出し
      logout( function(){
        // ログイン画面へ遷移
        self.$router.push({
          name: 'login'
        }).then(failure => {
          // 遷移がキャンセルされなかった場合
          if (!isNavigationFailure(failure, NavigationFailureType.aborted)) {
            // storeに保持している情報を初期化
            localStorage.removeItem('plusManabi');
            // localStrageに保持している情報を初期化
            localStorage.removeItem('iToken');
            localStorage.removeItem('aToken');
            // cookieからtokenを削除
            Cookie.deleteToken();
          }
        });
      }, localStorage.getItem('aToken'));
    }
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-right-icon {
  position: fixed;
  right: 36px;
  top: 7px;
  cursor: pointer;
}

#nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

#nav > ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

#nav > ul > li {
  margin: 0 20px 0 0;
}

#nav > ul > li > span {
  display: block;
  height: auto;
  text-decoration: none;
}

#nav > ul > li > span > svg {
  @apply mb-2;
}

.dropdown {
  position: absolute;
  top: 55px;
  right: -36px;
  display: none;
  padding: 0;
  list-style-type: none;
  background-color: #3730a3;
}

.dropdown li {
  width: 200px;
  border-bottom: 1px solid #fff;
}

.dropdown li a {
  display: block;
  padding: 15px 25px;
  color: #fff;
  text-decoration: none;
}

.dropdown li a:hover {
  @apply bg-indigo-900;
}

.isOpen {
  @apply block;
}
</style>
