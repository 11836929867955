<template>
  <div class="antialiased">
    <div class="mt-1">
      <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
        <table class="min-w-full leading-normal text-xs">
          <thead>
            <tr>
              <th class="td-checkbox"></th>
              <th class="td-license-cd">ライセンス<br />コード</th>
              <th class="td-subject">教科名</th>
              <th class="td-textbook-name">電子教材名</th>
              <th class="td-display-order">表示順</th>
              <th class="td-register-date">登録日時</th>
              <th class="td-update-date">更新日時</th>
              <th class="td-update">詳細/更新</th>
            </tr>
          </thead>
          <tbody
            class="overflow-y-scroll w-full"
            style="height: calc(100vh - 355px)"
          >
            <tr v-for="textbook in textbooks" :key="textbook.licenseCd">
              <td class="td-checkbox">
                <input 
                  type="checkbox"
                  v-model="checkedLicenseCds"
                  :value="textbook.licenseCd"
                />
              </td>
              <td class="td-license-cd">
                <p class="td-p whitespace-nowrap">{{ textbook.licenseCd }}</p>
              </td>
              <td class="td-subject">
                <p class="td-p whitespace-nowrap subject-label"  v-bind:style="{ backgroundColor: textbook.subjectColorCd }">
                  {{ textbook.subjectName }}
                </p>
              </td>
              <td class="flex td-textbook-name">
                <p class="flex-grow td-p whitespace-nowrap" v-tooltip="{content: textbook.textbookName}">
                  {{ textbook.textbookName }}
                </p>
                <a
                  v-if="textbook.textbookUrl1 && !textbook.isFaildFile1Upload"
                  class="flex-none td-link text-xs"
                  target="_blank"
                  v-bind:href="textbook.textbookUrl1"
                >
                  <font-awesome-icon icon="book" size="lg" />
                  1
                </a>
                <!-- ファイル１ アップロード失敗時 -->
                <span v-if="textbook.isFaildFile1Upload"
                  class="flex-none td-link-warning text-xs ml-2"
                  v-tooltip="{content: nowUploadingMsg}"
                >
                  <font-awesome-icon icon="book" size="lg" />
                  1
                  <span v-if="textbook.isFaildFile1Upload">
                    <font-awesome-icon class="text-red-600" icon="exclamation-circle" size="lg" />
                  </span>
                </span>

                <a v-if="textbook.textbookUrl2 && !textbook.isFaildFile2Upload"
                  class="flex-none td-link text-xs ml-2"
                  target="_blank"
                  v-bind:href="textbook.textbookUrl2"
                >
                  <font-awesome-icon icon="book" size="lg" />
                  2
                </a>
                <!-- ファイル２ アップロード失敗時 -->
                <span v-if="textbook.isFaildFile2Upload"
                  class="flex-none td-link-warning text-xs ml-2"
                  v-tooltip="{content: nowUploadingMsg}"
                >
                  <font-awesome-icon icon="book" size="lg" />
                  2
                  <span v-if="textbook.isFaildFile2Upload">
                    <font-awesome-icon class="text-red-600" icon="exclamation-circle" size="lg" />
                  </span>
                </span>

              </td>
              <td class="td-display-order">
                <p class="td-p whitespace-nowrap">{{ textbook.displayOrder }}</p>
              </td>
              <td class="td-register-date">
                <p class="td-p whitespace-nowrap">{{ textbook.createdAt.slice( 0, -4 ) }}</p>
              </td>
              <td class="td-update-date">
                <p class="td-p whitespace-nowrap">{{ textbook.updatedAt.slice( 0, -4 ) }}</p>
              </td>
              <td class="td-update">
                <router-link 
                  :to="{ name: 'book-manage-update', params: {licenseCd: textbook.licenseCd} }"
                  class="td-link text-xs">
                  <font-awesome-icon icon="edit" size="lg" />
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Message from '../const/message';

export default {
  name: 'Table',
  props: {
    textbooks: {
      type: Array,
      default: () => [],
    },
    subjects: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      checkedLicenseCds: [],
      nowUploadingMsg: Message.TEXTBOOK.NOW_UPLOADING,
    };
  },
  methods: {
  },
  emits: ['delete'],
  watch: {
    textbooks(){
      // チェックされている電子教材リストを初期化
      this.checkedLicenseCds = [];
    },
    checkedLicenseCds(licenseCds) {
      this.$emit('delete', licenseCds);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
thead,
tbody,
tr {
  @apply flex w-full;
}
tbody {
  @apply flex-col items-center overflow-y-scroll bg-gray-50;
}
th,
td {
  @apply px-4 border-gray-200 text-xs;
}
th {
  @apply py-2 border-b-2 bg-gray-100 text-center font-semibold text-gray-600 tracking-wider flex items-center place-content-center;
}
td {
  @apply py-1 border-b bg-white flex items-center;
}
th.td-update {
  @apply pl-0 place-content-start;
}
.td-checkbox {
  width: 6%;
  @apply place-content-center;
}
.td-license-cd {
  width: 10%;
}
.td-subject {
  width: 7%;
}
.td-textbook-name {
  width: 36%;
}
.td-display-order {
  width: 7%;
}
.td-register-date {
  width: 14%;
}
.td-update-date {
  width: 14%;
}
.td-update {
  width: 9%;
}
td.td-display-order,
td.td-license-cd {
  @apply flex justify-end;
}
td.td-update {
  width: calc(9% - 17px);
  @apply pr-0 pl-3 text-center;
}

.td-p {
  @apply text-gray-900;
}
.td-link {
  @apply text-gray-600 bg-gray-100 shadow py-1.5 px-3 rounded-md;
}
.td-link-warning {
  @apply text-red-700 bg-red-50 shadow py-1.5 px-3 rounded-md;
}

input[type='checkbox'] {
  @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}
select {
  @apply focus:ring-0;
}
.link {
  @apply text-indigo-800 underline;
}

.subject-label {
  @apply ml-0 mr-2 py-1 px-1.5 rounded text-xs font-normal text-center text-white;
}
</style>
