<template>
  <div class="max-h">
    <div class="abusolute-center bg-white shadow-lg w-full sm:w-max rounded-lg">
      <div class="flex justify-center p-4 border-b-4 border-indigo-800">
        <img class="w-32" alt="logo" src="../assets/logo.png" />
      </div>

      <div class="py-8 px-5 sm:px-12" id="app">
        <div class="max-w-lg">
          <ErrMsgCard :errMsgs="errMsgs" process="ログイン" />
        </div>
        <form
          v-on:submit.prevent="inputInfo"
          class="grid grid-cols-1 gap-6 text-gray-700"
        >
          <div>
            <span class="text-gray-700">{{ loginId.key }}</span>
            <input
              type="text"
              :placeholder="loginId.key + 'を入力'"
              v-model="loginId.value"
              maxlength="50"
              class="w-full"
            />
          </div>

          <div>
            <span class="text-gray-700">{{ password.key }}</span>
            <input
              :type="passInputType"
              :placeholder="password.key + 'を入力'"
              v-model="password.value"
              maxlength="50"
              class="w-full"
            />
            <span class="pass-mask-icon" v-if="password.value" @click="passVisibleToggle">
              <font-awesome-icon v-if="isPassVisible" icon="eye-slash" size="lg" />
              <font-awesome-icon v-else icon="eye" size="lg" />
            </span>
          </div>

          <div>
            <div class="flex justify-center">
              <button v-on:click="submit" name="button" class="btn btn-indigo">
                ログイン
              </button>
            </div>
          </div>

          <div
            class="flex flex-col sm:flex-row justify-self-center justify-center"
          >
            <div class="relative sm:pr-6">
              <button
                name="google_sso"
                @click="googleLogin()"
                class="h-10 w-60 font-semibold sso-set"
              >
                Googleでログイン
              </button>
              <div class="absolute bottom-2 left-3">
                <img
                  class="h-6"
                  alt="Googleアイコン"
                  src="../assets/icon_google.svg"
                />
              </div>
            </div>
            <div class="relative mt-6 sm:mt-0">
              <button
                @click="microsoftLogin()"
                class="h-10 w-60 font-semibold sso-set"
              >
                Microsoftでログイン
              </button>
              <div class="absolute bottom-2 left-3">
                <img
                  class="h-6"
                  alt="Microsoftアイコン"
                  src="../assets/icon_microsoft.svg"
                />
              </div>
            </div>
          </div>
          <div class="flex justify-end">
            <a
              href="https://www.bijutsushiryo.com/elementor-6684"
              name="information"
              class="a"
            >
              <img
                class="h-9"
                alt="お知らせはこちら"
                src="../assets/osirase.png"
              />
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from '../api';
import UserAuth from '../const/userAuthority';
import { rsaEncrypto } from '../crypto';
import ErrMsgCard from '../components/ErrorMssageCard.vue';
import Validation from '../utils/validation';
import Cookie from '../utils/cookie';
// import { v4 as uuidv4 } from 'uuid';
import Message from '../const/message';

export default {
  name: 'Login',
  components: {
    ErrMsgCard,
  },
  data() {
    return {
      loginId: { key: 'ログインID', value: '' },
      password: { key: 'パスワード', value: '' },
      isPassVisible: false,
      errMsgs: [],
    };
  },
  async created() {
    if (this.$route.query.errMsg) {
      const queryErrMsg = this.$route.query.errMsg;
      if (Array.isArray(queryErrMsg)) {
        // エラーメッセージが配列の場合、ループ
        queryErrMsg.forEach(msgCd=> {
          // コードを日本語のメッセージに変換してerrMsgsセット
          const msg = Message.SSO_ERROR[msgCd].message;
          this.errMsgs.push(msg);
        });
      } else {
        // エラーメッセージが配列ではない場合
        // コードを日本語のメッセージに変換してerrMsgsセット
        const msg = Message.SSO_ERROR[queryErrMsg].message;
        this.errMsgs.push(msg);
      }
    }

    // ログアウト時のlocalStrageとVuexの削除待ち（0.5秒）
    await new Promise((resolve) => setTimeout(resolve, 500));
    // ログイン済みの場合はトップページにリダイレクト
    if (localStorage.getItem('iToken')) {
      this.$router.push({ name: 'routing' });
    }
  },
  methods: {
    googleLogin() {
      location.href = process.env.VUE_APP_GOOGLE_LOGIN_URL;
    },
    microsoftLogin() {
      location.href = process.env.VUE_APP_MICROSOFT_LOGIN_URL;
    },
    submit() {
      // パスワードをマスキング
      this.isPassVisible = false;
      // バリデーション定義作成
      let validationArray = [];
      // バリデーションチェック実施
      this.errMsgs = validationArray.concat(
        Validation.requiredInput(this.loginId),
        Validation.requiredInput(this.password)
      );

      if (this.errMsgs.length) {
        // エラーメッセージがある場合は処理を中断
        return false;
      }
      // パスワードの暗号化
      let encryptoPass = rsaEncrypto(this.password.value);

      // 端末制御をしなくなったためコメントアウト
      // UUIDを取得
      // var clientId = Cookie.getClientId();
      // if(!clientId){
      //   clientId = uuidv4();
      // }
      // // cookieにクライアントIDをセット
      // Cookie.setClientId(clientId);

      let self = this;
      // ログインAPI呼び出し
      login(
        function (rspBody) {
          if (rspBody.result.code === 9) {
            // 処理結果が失敗の場合
            if (rspBody.result.messages.length) {
              // エラーメッセージを表示
              self.errMsgs = rspBody.result.messages;
            }
          } else {
            // 処理結果が成功の場合
            let user = rspBody.detail;
            // IDトークンとアクセストークンを保持
            localStorage.setItem('iToken', user.idToken);
            localStorage.setItem('aToken', user.accessToken);
            // cookieにIDトークンをセット
            Cookie.setToken(user.idToken);
            // リフレッシュトークンとユーザ情報を保持
            self.$store.commit('setRToken', user.refreshToken);
            self.$store.commit('setAuthTime', user.authTime);
            self.$store.commit('setTokenExp', user.tokenExp);
            self.$store.commit('setLoginId', user.loginId);
            self.$store.commit('setDisplayName', user.displayName);
            self.$store.commit('setSchoolCd', user.schoolCd);
            self.$store.commit('setMaxGrade', user.maxGrade);
            self.$store.commit('setAuthority', user.authority);
            self.$store.commit('setIsSsoLogin', false);
            self.$store.commit('setIsNotChangedPassword', user.isNotChangedPassword);

            if (
              user.isNotChangedPassword &&
              user.authority !== UserAuth.STUDENT.code
            ) {
              // 学習者以外のパスワード未変更の場合はパスワード変更画面へ遷移
              self.$router.push({
                name: 'password-change',
                query: self.$route.query.redirect
                  ? { redirect: self.$route.query.redirect }
                  : {}
              });
            } else if (self.$route.query.redirect) {
              // リダイレクトURLがある場合
              const regStr = '^https:\\/\\/' + process.env.VUE_APP_DOMAIN + '\\/textbooks\\/[0-9]{5}\\/.*index.html+$'
              const regExp = new RegExp(regStr);
              if (regExp.test(self.$route.query.redirect)) {
                // 電子教材からのリダイレクトの場合はcookieにIDtokenを付与してアクセス
                window.location.href = self.$route.query.redirect;
              } else {
                // それ以外の場合はリダイレクト先の画面へ遷移
                self.$router.push(self.$route.query.redirect);
              }
            } else {
              // 上記以外の場合はルートページへ遷移
              self.$router.push({ name: 'routing' });
            }
          }
        },
        this.loginId.value,
        encryptoPass
      );
    },
    passVisibleToggle() {
      this.isPassVisible = !this.isPassVisible;
    }
  },
  computed: {
    passInputType () {
      return this.isPassVisible ? 'text' : 'password';
    }
  },
};
</script>

<style scoped>
.abusolute-center {
  @apply absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;
}
input,
select {
  @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:bg-white bg-gray-50;
}
.btn {
  @apply font-semibold w-60 h-11 rounded py-2 px-6 text-sm focus:outline-none focus:ring focus:ring-opacity-50;
}
a {
  @apply text-indigo-800 underline;
}
.sso-set {
  @apply text-sm rounded;
  border-width: 1px;
  border-color: #8c8c8c;
  color: #5e5e5e;
  font-size: 600;
}
</style>
