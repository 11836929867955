/** ユーザステータス */
class UserStatus {
  /** 利用前 */
  static BEFORE_USE = { code: '0', name: '利用前' };
  /** 利用可 */
  static USABLE = { code: '1', name: '利用可' };
  /** 利用不可 */
  static UNUSABLE = { code: '9', name: '利用不可' };

  /**
   * ユーザステータスのコードから論理名を取得する
   * @param {string} code ユーザステータスのコード
   * @returns ユーザステータスの論理名
   */
   static getNameByCode(code){
    let name = '';
    for (const key in this) {
      if(this[key].code === code){
        name = this[key].name;
      }
    }
    return name;
  }
}

module.exports = UserStatus;