import { createRouter, createWebHistory } from 'vue-router';

// compornent
import Routing from '../views/TheRouting.vue';
import Login from '../views/Login.vue';
import PasswordChange from '../views/PasswordChange.vue';
import UsageStats from '../views/UsageStats.vue';
import UsageStatsDetail from '../views/UsageStatsDetail.vue';
import BookShelf from '../views/BookShelf.vue';
import BookList from '../views/BookList.vue';
import BookRegister from '../views/BookRegister.vue';
import BookUpdate from '../views/BookUpdate.vue';
import UserList from '../views/UserList.vue';
import UserRegister from '../views/UserRegister.vue';
import UserUpdate from '../views/UserUpdate.vue';
import UserLicenseManage from '../views/UserLicenseManage.vue';
import SchoolList from '../views/SchoolList.vue';
import SchoolRegister from '../views/SchoolRegister.vue';
import SchoolUpdate from '../views/SchoolUpdate.vue';
import SchoolLicenseManage from '../views/SchoolLicenseManage.vue';
import NotFound from '../views/errors/404.vue';
import Maintenance from '../views/errors/Maintenance.vue';
import LoginRedirect from '../views/LoginWithSso.vue';

import store from '../store';
import Authorize from '../utils/authorize';
import ViewId from '../const/viewId';
import UserAuth from '../const/userAuthority';
import { outputAccessLog } from '../api';

const routerHistory = createWebHistory(process.env.BASE_URL);

const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/',
      name: 'routing',
      component: Routing,
      meta: { title: '＋まなび', requiresAuth: true },
    },
    {
      path: '/bookshelf',
      name: 'bookshelf',
      component: BookShelf,
      meta: { title: '本棚 - ＋まなび', requiresAuth: true },
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { title: 'ログイン - ＋まなび', requiresAuth: false },
    },
    {
      path: '/password-change',
      name: 'password-change',
      component: PasswordChange,
      meta: { title: 'パスワード変更 - ＋まなび', requiresAuth: true },
    },
    {
      path: '/usage-stats/:schoolCd',
      name: 'usage-stats',
      component: UsageStats,
      meta: { title: '画面別閲覧数一覧 - ＋まなび', requiresAuth: true },
    },
    {
      path: '/usage-stats/:schoolCd/detail/:date/:id',
      name: 'usage-stats-detail',
      component: UsageStatsDetail,
      meta: { title: 'ユーザ閲覧履歴 - ＋まなび', requiresAuth: true },
    },
    {
      path: '/manage/school',
      name: 'school-manage',
      component: SchoolList,
      meta: { title: '学校一覧 - ＋まなび', requiresAuth: true },
    },
    {
      path: '/manage/school/register',
      name: 'school-manage-register',
      component: SchoolRegister,
      meta: { title: '学校登録 - ＋まなび', requiresAuth: true },
    },
    {
      path: '/manage/school/:schoolCd/update',
      name: 'school-manage-update',
      component: SchoolUpdate,
      meta: { title: '学校更新 - ＋まなび', requiresAuth: true },
    },
    {
      path: '/manage/school/:schoolCd/license',
      name: 'license-manage-school',
      component: SchoolLicenseManage,
      meta: { title: 'ライセンス割り当て - ＋まなび', requiresAuth: true },
    },
    {
      path: '/manage/school/:schoolCd/user',
      name: 'user-manage',
      component: UserList,
      meta: { title: 'ユーザ一覧 - ＋まなび', requiresAuth: true },
    },
    {
      path: '/manage/school/:schoolCd/user/register',
      name: 'user-manage-register',
      component: UserRegister,
      meta: { title: 'ユーザ登録 - ＋まなび', requiresAuth: true },
    },
    {
      path: '/manage/school/:schoolCd/user/:loginId/update',
      name: 'user-manage-update',
      component: UserUpdate,
      meta: { title: 'ユーザ更新 - ＋まなび', requiresAuth: true },
    },
    {
      path: '/manage/school/:schoolCd/user/license',
      name: 'license-manage-user',
      component: UserLicenseManage,
      meta: { title: 'ユーザライセンス割り当て - ＋まなび', requiresAuth: true },
    },
    {
      path: '/manage/book',
      name: 'book-manage',
      component: BookList,
      meta: { title: '電子教材一覧 - ＋まなび', requiresAuth: true },
    },
    {
      path: '/manage/book/register',
      name: 'book-manage-register',
      component: BookRegister,
      meta: { title: '電子教材登録 - ＋まなび', requiresAuth: true },
    },
    {
      path: '/manage/book/:licenseCd/update',
      name: 'book-manage-update',
      component: BookUpdate,
      meta: { title: '電子教材更新 - ＋まなび', requiresAuth: true },
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance,
      meta: { title: 'メンテナンス中 - ＋まなび', requiresAuth: false },
    },
    {
      path: '/:catchAll(.*)',
      name: 'not-found',
      component: NotFound,
      meta: { title: 'ページが見つかりません - ＋まなび', requiresAuth: false },
    },
    {
      path: '/login/redirect',
      name: 'login-redirect',
      component: LoginRedirect,
      meta: { title: '＋まなび', requiresAuth: false },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  // 認証の要不要を判断
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    // プログレスサークルが回り続けている場合は停止させる
    store.commit('setLoading', false);
    // 認証不要：遷移先画面を描画
    next();
    return false;
  }

  // 認証が必要な場合、認証処理を行う
  if (!(await Authorize.isAuth())) {
    // 認証失敗：ログイン画面へ遷移
    // （リダイレクトURLに遷移先予定画面を指定）
    if (to.fullPath === '/') {
      next({ name: 'login' });
    } else {
      next({ name: 'login', query: { redirect: to.fullPath } });
    }
    return false;
  }

  // パスワード変更画面へリダイレクト要否を判断
  if (
    store.state.isNotChangedPassword &&
    store.state.authority !== UserAuth.STUDENT.code &&
    !to.fullPath.match(/password-change/)
  ) {
    if (to.fullPath === '/') {
      next({ name: 'password-change' });
    } else {
      // パスワード未変更で他画面への遷移試行：パスワード変更画面へ強制遷移
      next({ name: 'password-change', query: { redirect: to.fullPath } });
    }
  } else {
    // アクセスログ出力API呼び出し
    if (ViewId[to.name]) {
      const viewId = ViewId[to.name].id;
      await outputAccessLog(viewId, to.fullPath);
    }
    // 遷移予定先画面を描画
    next();
  }
});

export default router
