<template>
  <div></div>
</template>

<script>
import { loginWithSso } from '../api';
import Cookie from '../utils/cookie';
// import { v4 as uuidv4 } from 'uuid'
import Message from '../const/message';

export default {
  name: 'LoginWithSso',
  components: {},
  data() {},
  created: function () {
    // URLの?以降の文字を取得
    let queryString = window.location.search;
    // クエリストリングパラメータ格納用object初期化
    let queryObject = new Object();
    // Cognito発行Code格納用String初期化
    let code = '';
    // Cognito発行state格納用String初期化
    let state = '';

    // queryStringが存在する場合
    if (queryString) {
      // 取得した文字から?を取り除く
      queryString = queryString.substring(1);
      // パラメータごとに配列に格納
      let parameters = queryString.split('&');

      // パラメータごとに区切った配列を、名前と値で分けたオブジェクトに変換
      for (let i = 0; i < parameters.length; i++) {
        let element = parameters[i].split('=');

        let paramName = decodeURIComponent(element[0]);
        let paramValue = decodeURIComponent(element[1]);

        queryObject[paramName] = paramValue;
      }
      // Cognito発行Codeを代入
      code = queryObject['code'] ?? '';
      state = queryObject['state'] ?? '';
    }

    // 端末制御をしなくなったためコメントアウト
    // UUIDを取得
    // var clientId = Cookie.getClientId();
    // if(!clientId){
    //   clientId = uuidv4();
    // }
    // // cookieにクライアントIDをセット
    // Cookie.setClientId(clientId);

    // クエリストリングスにerrorが含まれている場合
    if (queryObject.error) {
      let errCodes = [];
      if (queryObject['error_subcode'] === 'cancel' && queryObject.state === 'MS') {
        // SSOの画面でログインをキャンセルした場合、errMsgに何もセットしない
        errCodes = [];
      } else if (!queryObject.state && queryObject['error_description']) {
        // preSignupTriggerからthrowされたエラーメッセージをセット
        const errDesc = queryObject['error_description'];
        if (errDesc.includes(Message.SSO_ERROR.NOT_FOUND_EMAIL.name)){
          errCodes.push(Message.SSO_ERROR.NOT_FOUND_EMAIL.name);
        } else if (errDesc.includes(Message.SSO_ERROR.USER_UNUSABLE.name)){
          errCodes.push(Message.SSO_ERROR.USER_UNUSABLE.name);
        } else {
          // Cognitoのその他のエラーの場合は汎用的エラーメッセージをセット
          errCodes.push(Message.SSO_ERROR.PROCESS_FAILED.name);
        }
      } else {
        // それ以外の場合は汎用的エラーメッセージをセット
        errCodes.push(Message.SSO_ERROR.PROCESS_FAILED.name);
      }
      // 処理を中断し、ログイン画面へ遷移
      this.$router.push({
        name: 'login',
        query: { errMsg: errCodes },
      });
      return false;
    }

    let self = this;
    // SSOログインAPI呼び出し
    loginWithSso(
      function (rspBody) {
        // 処理結果が失敗の場合
        if (rspBody.result && rspBody.result.code === 9) {
          // ログイン画面へ遷移
          self.$router.push({
            name: 'login',
            query: {
              errMsg: rspBody.result.messages.length
                ? rspBody.result.messages
                : [],
            },
          });
        } else {
          // 処理結果が成功の場合
          let user = rspBody.detail;
          // IDトークンとアクセストークンを保持
          localStorage.setItem('iToken', user.idToken);
          localStorage.setItem('aToken', user.accessToken);
          // cookieにIDトークンをセット
          Cookie.setToken(user.idToken);
          // リフレッシュトークンとユーザ情報を保持
          self.$store.commit('setRToken', user.refreshToken);
          self.$store.commit('setAuthTime', user.authTime);
          self.$store.commit('setTokenExp', user.tokenExp);
          self.$store.commit('setLoginId', user.loginId);
          self.$store.commit('setDisplayName', user.displayName);
          self.$store.commit('setSchoolCd', user.schoolCd);
          self.$store.commit('setMaxGrade', user.maxGrade);
          self.$store.commit('setAuthority', user.authority);
          self.$store.commit('setIsSsoLogin', true);
          self.$store.commit('setIsNotChangedPassword', false);

          // デッドロジックのため、コメントアウト
          // if (self.$route.query.redirect) {
          //   // リダイレクトURLがある場合
          //   const regStr = '^https:\\/\\/' + process.env.VUE_APP_DOMAIN + '\\/textbooks\\/[0-9]{5}\\/.*index.html+$'
          //   const regExp = new RegExp(regStr);
          //   if (regExp.test(self.$route.query.redirect)) {
          //     // 電子教材からのリダイレクトの場合はcookieにIDtokenを付与してアクセス
          //     window.location.href = self.$route.query.redirect;
          //   } else {
          //     // それ以外の場合はリダイレクト先の画面へ遷移
          //     self.$router.push(self.$route.query.redirect);
          //   }
          // } else {
          // 上記以外の場合はルートページへ遷移
          self.$router.push({ name: 'routing' });
          // }
        }
      },
      code,
      state
    );
  },
};
</script>
