/** ファイル状態 */
class FileStatus {
  /** 取込待ち */
  static IMPORT_WAIT = { code: '0', name: '取込待ち' };
  /** 取込エラー */
  static IMPORT_ERROR = { code: '1', name: '取込エラー' };
  /** 取込失敗 */
  static IMPORT_FAILED = { code: '9', name: '取込失敗' };
}

module.exports = FileStatus;