<template>
  <div class="antialiased">
    <div class="flex my-3">
      <div class="flex lg:flex-row flex-col">
        <div class="relative flex flex-row">
          <div class="relative" v-if="isSysAdmin">
            <p class="text-sm">
              <font-awesome-icon icon="caret-down" class="ml-1" />
              {{ school.key }}
            </p>
            <multiselect
              v-model="school.value"
              :options="setSchoolOptions(schoolList)"
              :close-on-select="true"
              placeholder="入力、選択してください"
              selectedLabel=""
              selectLabel=""
              deselectLabel=""
              label="name"
              track-by="name"
            >
              <template v-slot:noResult>
                {{noResult}}
              </template>
              <template v-slot:noOptions>
                {{noOptions}}
              </template>
            </multiselect>
          </div>

          <div class="mb-3">
            <p class="text-sm">
              <font-awesome-icon icon="caret-down" class="ml-1" />
              {{ grade.key }}
            </p>
            <select
              v-model="grade.value"
              class="
                h-12
                text-sm
                rounded-r
                lg:rounded-r-none lg:border-r-0
                border-r border-b
                w-full
                bg-white
                border-gray-400
                text-gray-700
                py-2
                px-4
                pr-10
                leading-tight
                focus:outline-none
                focus:border-l
                focus:border-r
                focus:bg-white
                focus:border-gray-500
              "
            >
              <option
                v-bind:value="{ name: 'すべて', code: '' }"
                selected="selected"
              >
                すべて
              </option>
              <option
                v-for="grd in getGradeList"
                :key="grd"
                v-bind:value="{ name: grd + '年', code: grd }"
              >
                {{ grd }}年
              </option>
            </select>
          </div>
        </div>

        <div class="relative flex flex-row">
          <div class="relative">
            <p class="text-sm">
              <font-awesome-icon icon="caret-down" class="ml-1" />
              {{ startDate.key }}
            </p>
            <span
              class="h-1/3 absolute inset-y-7 left-0 flex items-center pl-2"
            >
              <svg
                viewBox="0 0 24 24"
                class="h-4 w-4 fill-current text-gray-500"
              >
                <path
                  d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"
                ></path>
              </svg>
            </span>
            <input v-model="startDate.value" type="date" onkeydown="return false" class="form-middle w-44" />
          </div>

          <div class="relative">
            <p class="text-sm">
              <font-awesome-icon icon="caret-down" class="ml-1" />
              {{ endDate.key }}
              <span class="text-xs">(※開始日から2週間以内)</span>
            </p>
            <span
              class="h-1/3 absolute inset-y-7 left-0 flex items-center pl-2"
            >
              <svg
                viewBox="0 0 24 24"
                class="h-4 w-4 fill-current text-gray-500"
              >
                <path
                  d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"
                ></path>
              </svg>
            </span>
            <input v-model="endDate.value" type="date" onkeydown="return false" class="form-right w-44" />
          </div>

          <div class="mt-7 lg:ml-4">
            <button v-on:click="search" class="btn btn-search btn-short">
              <font-awesome-icon icon="search" class="mr-1" />
              検索
            </button>
          </div>
          <div class="mt-7 ml-4">
            <button v-on:click="clear" class="btn btn-gray btn-short">
              <font-awesome-icon icon="backspace" />
              クリア
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Message from '../const/message';
import UserAuth from '../const/userAuthority';

export default {
  name: 'UsageStatsForm',
  props: {
    schoolList: {
      type: Array,
      default: () => [],
    },
    handleSearch: {
      type: Function,
      required: true,
    },
    isSysAdmin: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      school: { key: '学校コード / 学校名', value: { name: '', code: '' } },
      grade: { key: '学年', value: { name: 'すべて', code: '' } },
      startDate: { key: '開始日', value: '' },
      endDate: { key: '終了日', value: '' },
      noResult: Message.MULTI_NOT_RESULT,
      noOptions: Message.MULTI_NOT_OPTIONS,
    };
  },
  created() {
    if (this.$store.state.searchUsageStatsKeys){
      this.school.value = this.$store.state.searchUsageStatsKeys.school;
      this.grade.value = this.$store.state.searchUsageStatsKeys.grade;
      this.startDate = this.$store.state.searchUsageStatsKeys.startDate;
      this.endDate = this.$store.state.searchUsageStatsKeys.endDate;
      this.search();
    } else {
      if (this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code) {
        // システム管理者はプレースホルダーの表示をするためにnullを設定
        this.school.value = null;
      }
    }
  },
  methods: {
    setSchoolOptions(schoolList) {
      let options = new Array();
      schoolList.forEach((s) => {
        let option = {
          name: s.schoolCd + ' / ' + s.schoolName,
          code: s.schoolCd,
        };
        options.push(option);
      });
      return options;
    },
    search() {
      this.$store.commit({
        type:'setSearchUsageStatsKeys',
        school: this.school.value,
        grade: this.grade.value,
        startDate: this.startDate,
        endDate: this.endDate,
      });
      this.handleSearch(
        this.school,
        this.grade,
        this.startDate,
        this.endDate
      );
    },
    clear() {
      Object.assign(this.$data, this.$options.data());
    },
  },
  computed: {
    getGradeList() {
      // 1から最高学年までの数字を配列化
      return [...Array(this.$store.state.maxGrade).keys()].map((i) => ++i);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.multiselect {
  @apply m-0 p-0;
}
.multiselect >>> .multiselect__single {
  min-height: 15px;
  width: 190px;
  @apply m-0 p-0 h-10 text-sm leading-tight;
}
.multiselect >>> .multiselect__input {
  min-height: 15px;
  @apply m-0 p-0 h-8 text-sm leading-tight;
}
.multiselect >>> .multiselect__tags {
  min-height: 48px;
  max-height: 48px;
  padding-top: 6px;
  width: 230px;
  @apply text-sm border rounded border-r-0 rounded-r-none bg-white border-gray-400 text-gray-700;
}
.multiselect >>> .multiselect__select {
  top: 4px;
  transform: none;
}
.multiselect >>> .multiselect__content-wrapper {
  @apply overflow-x-hidden;
}
input[type='date'].search-between {
  @apply h-8 mt-0 inline rounded-none rounded-l lg:rounded-l-none border-gray-400 shadow-sm focus:border-white placeholder-gray-400;
  line-height: 28px;
  height: 48px;
  -webkit-appearance: textfield;
}
input[type='date'].search-right {
  @apply h-8 mt-0 inline rounded-none rounded-r border-gray-400 shadow-sm focus:border-white placeholder-gray-400;
  line-height: 28px;
  height: 48px;
  -webkit-appearance: textfield;
}

.form-left {
  @apply h-12
         py-2 px-4
         leading-tight
         text-sm text-gray-700
         border border-r-0 border-gray-400
         rounded-l
         focus:ring-0;
}

.form-middle {
  @apply h-12
         py-2 px-4 pl-8 pr-2
         leading-tight
         text-sm text-gray-700
         border border-r-0 border-gray-400
         focus:ring-1 ring-gray-400
         search-between;
}
.form-right {
  @apply h-12
         py-2 px-4 pl-8 pr-2
         leading-tight
         text-sm text-gray-700
         border border-gray-400
         rounded-r
         focus:ring-1 ring-gray-400
         search-right;
}

.btn-short {
  @apply h-8 py-1 px-4 w-28;
}
</style>
