<template>
  <div class="antialiased">
    <div class="flex flex-wrap mt-0 lg:-mt-12 lg:justify-end place-items-center">
      <button
        @click="$router.go(-1)"
        type="button"
        name="back-button"
        class="btn btn-gray mr-4 md:mr-6"
      >
        戻る
      </button>
      <button
        v-on:click="openFirstModal"
        type="button"
        name="update-button"
        class="btn btn-indigo"
      >
        更新
      </button>
    </div>

    <template v-if="users.length !== 0">
      <div class="flex flex-col-reverse lg:flex-row mt-2">
        <p class="flex-auto">学校名：{{ userLicenses.schoolName }}</p>
        <p class="flex-auto lg:text-right text-lg font-bold text-red-500">
          ※一度割り当てたライセンスの変更・解除はできません。
        </p>
      </div>
      <table
        class="
          licencse-user-table
          bg-gray-50
          w-full
          overflow-x-scroll
          block
          whitespace-nowrap
        "
      >
        <thead class="">
          <tr>
            <th class="fixed-th-rowspan first-row th-year">開始年月日 ～ 終了年月日</th>
            <th
              class="fixed-th first-row th-year"
              v-for="licenseInfo in allLicenses"
              :key="licenseInfo.id"
            >
              {{ licenseInfo.startDate }} ～ {{ licenseInfo.endDate }}
            </th>
          </tr>
          <tr class="bg-gray-100" style="table-layout: fixed">
            <th class="fixed-th-rowspan second-row">
              <div class="inline-block">
                <p>ライセンス名</p>
              </div>
            </th>
            <th
              class="fixed-th second-row"
              v-for="licenseInfo in allLicenses"
              :key="licenseInfo.id"
            >
              <div class="text-center" style="max-height: 50px">
                <p class="whitespace-pre-wrap overflow-hidden w-40" style="overflow-wrap: break-word">
                  {{ licenseInfo.textbookName }}
                </p>
              </div>
            </th>
          </tr>
          <tr class="th-use-cnt">
            <th class="fixed-th-rowspan third-row">割り当て数 / 総数</th>
            <th
              class="fixed-th third-row"
              v-for="licenseInfo in allLicenses"
              :key="licenseInfo.id"
            >
              {{ licenseInfo.usedCnt }} / {{ licenseInfo.totalCnt }}
            </th>
          </tr>
          <tr class="th-select-btn">
            <th class="fixed-th-rowspan fourth-row">全選択 / 解除</th>
            <th
              class="fixed-th fourth-row"
              v-for="licenseInfo in allLicenses"
              :key="licenseInfo.id"
            >
              <input
                type="checkbox"
                :id="licenseInfo.id"
                :true-value="{ licenseId: licenseInfo.id, check: true }"
                :false-value="{ licenseId: licenseInfo.id, check: false }"
                v-model="checkedAllColumns"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="userInfo in users" :key="userInfo.loginId">
            <td class="td-user-info">
              <p>{{ userInfo.loginId }}</p>
              <p class="mt-1">{{ userInfo.displayName }}</p>
            </td>
            <td
              class="text-center"
              v-for="licenseInfo in allLicenses"
              :key="licenseInfo.id"
            >
              <input
                type="checkbox"
                :id = "licenseInfo.id + userInfo.loginId"
                :true-value="{ licenseId: licenseInfo.id, loginId:userInfo.loginId, check: true }"
                :false-value="{ licenseId: licenseInfo.id, loginId:userInfo.loginId, check: false }"
                v-model="onChangeCheckbox"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import Message from '../const/message';

export default {
  name: 'UserLicenseManageTable',
  emits: ['register'],
  props: {
    userLicenses: { type: Object, default: () => {} },
  },
  data() {
    return {
      users: [],
      allLicenses: [],
      cellValues: [],
    };
  },
  updated() {
    // 割り当て済みライセンス事前チェックオン処理
    this.cellValues.forEach((cell) => {
      let targetElement = this.getCheckboxElementByCellInfo(cell);
      this.changeCellValuesChecked(cell, cell.isChecked);
      this.changeElementChecked(targetElement, cell.isChecked);
      if(cell.isLinkLicense) this.changeElementLinkLicensedOrMax(cell, true);
      if(!cell.isLinkLicense && cell.isMaxLinkLicense) this.changeElementLinkLicensedOrMax(cell, false);
    });
  },
  methods: {
    /** 割り当て済みライセンスを判定する */
    isLinkLicense(loginId, licenseId) {
      // 対象のユーザーを取得
      let targetUser = this.users.find((user) => loginId === user.loginId);
      // ユーザーに紐づく対象ライセンスを取得
      let targetLicense = targetUser.userLicensesId.find((userLicenseId) => userLicenseId === licenseId);
      // ユーザーが対象ライセンスを保有しているか判定
      return targetLicense ? true : false
    },
    /** 親コンポーネントのメソッドにアクセスする(ライセンス割り当て登録) */
    register() {
      // チェック済みレコードを取り出す(ライセンス付与済み or ライセンス数Maxのものは対象としない)
      let checkedCellValues = this.cellValues.filter((cell) => cell.isChecked && !cell.isLinkLicense && !cell.isMaxLinkLicense);
      // チェック済みレコードからloginIdを取り出し、重複削除
      let loginIdList = Array.from(new Set(checkedCellValues.map((cell) => {return cell.loginId})));
      // リクエストパラメータ作成
      let updateList = loginIdList.map((loginId) =>{
        // loginIdをキーにチェック済みレコードからlicenseIdのリストを作成
        let licenseListByLoginId = checkedCellValues.filter((cell) => cell.loginId === loginId).map((cell) => {return cell.licenseId});
        return {
          loginId: loginId,
          licenses: licenseListByLoginId,
        }
      });

      // 登録リクエスト
      this.$emit('register', updateList);
    },
    /** 1つ目の確認モーダルウィンドウを開く */
    openFirstModal() {
      const self = this;
      const msg = [
        '一度割り当てたライセンスの変更・解除はできません。',
        'ライセンス割り当て先が正しいことを確認しましたか？'
      ];
      this.$store.commit('setMessages', msg);
      this.$store.commit('setOkBtnMsg', '確認済み');
      this.$store.commit('setCancelBtnMsg', '再度確認する');
      this.$store.commit('setOkAction', async function(){
        await new Promise((resolve) => setTimeout(resolve, 100));
        self.openSecondModal();
      });
      this.$store.commit('setIsModalOpen', true);
    },
    /** 2つ目の確認モーダルウィンドウを開く */
    openSecondModal() {
      const self = this;
      const msg = [
        Message.generateMessage(Message.CONFIRM, [Message.PROC_TYPE.UPDATE]),
      ];
      this.$store.commit('setMessages', msg);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setOkAction', function(){
        self.register();
      });
      this.$store.commit('setIsModalBtnReverse', true);
      this.$store.commit('setIsModalOpen', true);
    },
    getCheckboxElementByCellInfo(targetCell) {
      return document.getElementById(targetCell.licenseId + targetCell.loginId);
    },
    /** cellValuesの特定データのチェックフラグを更新する */
    changeCellValuesChecked(targetCell, isChecked) {
      // ライセンス付与済みデータはregisterメソッドの抽出で弾くため、データとしてチェックの更新は許容している
      this.cellValues.find((cellValue) =>
        cellValue.licenseId === targetCell.licenseId && cellValue.loginId === targetCell.loginId
      ).isChecked = isChecked;
    },
    /** 画面チェックボックスの状態を変更する */
    changeElementChecked(targetElement, isChecked) {
      // Vue.jsを通さず直接Elementにアクセスすることで処理速度を改善
      targetElement.checked = isChecked;
    },
    /** ライセンス付与済み or ライセンス数MAXのチェックボックスを変更(初期表示時のみ適応) */
    changeElementLinkLicensedOrMax(targetCell, isChecked){
      let checkboxElement = this.getCheckboxElementByCellInfo(targetCell);
      // ライセンス付与済みのものは「チェックオン」「変更不可」
      // ライセンス数Maxのものは「チェックオンオフ」「変更不可」
      checkboxElement.checked = isChecked;
      checkboxElement.disabled= true;
      checkboxElement.style.backgroundColor = '#9CA3AF';
      checkboxElement.parentElement.style.backgroundColor = '#F3F4F6';
    },
    /** チェックボックスの更新時ライセンス使用数を変更する(上限を超える場合、falseを返却) */
    changeLicenseUsedCount(licenseId, targetElement, isChecked) {
      let targetLicense = this.allLicenses.find((license) => license.id === licenseId);
      // ライセンス使用数を更新する
      isChecked ? targetLicense.usedCnt++ : targetLicense.usedCnt--;
      // 更新した結果使用数の上限を超える場合、使用数を戻してfalseを返却
      if (targetLicense.usedCnt > targetLicense.totalCnt) {
        targetLicense.usedCnt--;
        targetElement.checked = false;
        return false;
      }
      return true;
    },
    /** 一括チェックとチェックボックスの状態が同じか判定する */
    checkBalkUpdate(targetElement, isChecked){
      return isChecked == targetElement.checked ? false : true;
    },
    /** データ&画面の更新を呼び出す */
    changeCellValuesAndElementChecked(targetCell, isChecked, isbalk) {
      // ライセンス付与済み or ライセンス数Maxの場合、Cellの更新を行わない。
      if (targetCell.isLinkLicense || targetCell.isMaxLinkLicense) return;
      // 対象のチェックボックスElementを取得する。
      let targetElement = this.getCheckboxElementByCellInfo(targetCell);
      // 一括チェックの場合、一括チェックとチェックボックスの状態が同じ時はCellの更新を行わない。
      // ※一括チェックで途中までチェックが入ったデータが対象となる。
      if (isbalk && !this.checkBalkUpdate(targetElement, isChecked)) return;
      // ライセンス数のカウント処理。
      let isChangeCell = this.changeLicenseUsedCount(targetCell.licenseId, targetElement, isChecked);
      if (isChangeCell) {
        // 更新可能な場合、Cell情報を更新する
        this.changeCellValuesChecked(targetCell, isChecked);
        this.changeElementChecked(targetElement, isChecked);
      } else {
        // 更新不可の場合、エラーモーダルを表示する。
        const overLimitLicense = this.allLicenses.find((license) => license.id === targetCell.licenseId);
        const msg = [
          '以下のライセンス数が上限を超えています。',
          'ライセンス名:【' + overLimitLicense.textbookName + '】',
          '開始年月日：【' + overLimitLicense.startDate + '】',
          '終了年月日：【' + overLimitLicense.endDate + '】'
        ]
        this.$store.commit('setMessages', msg);
        this.$store.commit('setCancelBtnHide', true);
        this.$store.commit('setIsModalOpen', true);
      }
    },
  },
  computed: {
    /** チェックボックス 全選択/全解除 */
    checkedAllColumns: {
      get() {
        // 初期表示時、false返却
        return false;
      },
      set(bulkCheckCellInfo) {
        // 全選択/解除対象のlicenseを取得
        let targetCells = this.cellValues.filter((cellValue) => 
          cellValue.licenseId === bulkCheckCellInfo.licenseId
        );
        // 選択されたライセンスからデータを一括チェックする
        targetCells.forEach((targetCell) => {
          this.changeCellValuesAndElementChecked(targetCell, bulkCheckCellInfo.check, true);
        });
      },
    },
    /** 個別チェックボックス 選択/解除 */
    onChangeCheckbox: {
      get() {
        return false;
      },
      set(checkCellInfo) {
        // 個別のチェックボックスが変更された時にデータ／画面のチェックをする
        this.changeCellValuesAndElementChecked(checkCellInfo, checkCellInfo.check, false);
      }
    }
  },
  watch: {
    userLicenses: function () {
      this.allLicenses = this.userLicenses.allLicenses;
      this.users = this.userLicenses.users;
      // セルの値格納配列を初期化
      this.cellValues = [];

      // 全選択ボタンをチェックオフにする
      this.allLicenses.forEach((license) =>{
        let licenseElement = document.getElementById(license.id);
        if(licenseElement) licenseElement.checked = false;
      });

      this.users.forEach((user) => {
        // 割り当て済みライセンス照合のため、userLicensesIdフィールドを追加
        user.userLicensesId = user.userLicenses.map((userLicense) =>{
          let licenseIdForUser = userLicense.licenseCd + '#' + userLicense.startDate + '#' + userLicense.endDate;
          return licenseIdForUser.replace(/\//g, '');
        });
        this.allLicenses.forEach((license) => {
          // 全セルのvalueの配列を作成
          let isLinkLicense = this.isLinkLicense(user.loginId, license.id);
          let isMaxLinkLicense = license.usedCnt === license.totalCnt;
          this.cellValues.push({
            loginId: user.loginId,
            licenseId: license.id,
            isChecked: false,
            isLinkLicense: isLinkLicense,
            isMaxLinkLicense: isMaxLinkLicense,
          });
        });
      });
    },
  },
};
</script>

<style scoped>
th,
td {
  @apply px-2 py-1 border w-16 border-gray-200 text-xs;
}
th {
  @apply bg-gray-100 box-border;
  outline: 1px solid rgba(229, 231, 235, var(--tw-border-opacity));
  outline-offset: -1px;
}
.th-use-cnt > th,
.th-select-btn > th {
  @apply text-xs;
}
.th-year {
  @apply bg-gray-400 text-white w-44;
}
td {
  @apply bg-white;
}

.fixed-th,
.fixed-th-rowspan {
  @apply sticky;
  top: 0;
  border-top: #ffffff;
}
.fixed-th-rowspan {
  @apply z-10;
}
.fixed-th,
td.td-user-info {
  z-index: 1;
}
td.td-user-info,
.fixed-th-rowspan {
  @apply sticky;
  left: 0;
  outline: 1px solid rgba(229, 231, 235, var(--tw-border-opacity));
  outline-offset: -1px;
  border-left: #ffffff;
}
td.td-user-info {
  @apply h-8 w-44 whitespace-pre-wrap;
}
.first-row {
  height: 26px;
}
.second-row {
  top: 26px;
  height: 60px;
  overflow: auto;
  scrollbar-width: thin;
}
.second-row::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #ddd; /* or add it to the track */
}
/* Add a thumb */
.second-row::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #888;
}
.third-row {
  top: 86px;
  height: 30px;
}
.fourth-row {
  top: 116px;
  height: 35px;
}

.btn-small {
  @apply py-0.5 px-1.5 rounded text-xs;
}
.licencse-user-table {
  height: calc(100vh - 20rem);
}

.btn-daialog {
  @apply font-semibold w-40 rounded py-2 px-6 text-base focus:outline-none focus:ring focus:ring-opacity-50;
}

@media (min-width: 1024px) {
  .licencse-user-table {
    height: calc(100vh - 16rem);
  }
}
</style>
