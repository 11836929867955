<template>
  <div class="antialiased">
    <div class="py-1">
      <div
        id="accessLogsTable"
        class="inline-block min-w-full shadow rounded-lg overflow-hidden"
      >
        <table class="min-w-full leading-normal text-xs">
          <thead>
            <tr>
              <th
                @click="sortBy('grade')"
                :class="addClass('grade')"
                class="td-grade"
              >
                学年
              </th>
              <th
                @click="sortBy('loginId')"
                :class="addClass('loginId')"
                class="td-loginId"
              >
                ログインID
              </th>
              <th
                @click="sortBy('displayName')"
                :class="addClass('displayName')"
                class="td-displayName"
              >
                表示名
              </th>
              <th
                @click="sortBy('timestamp')"
                :class="addClass('timestamp')"
                class="td-timestamp"
              >
                アクセス時間
              </th>
            </tr>
          </thead>
          <tbody
            class="overflow-y-scroll w-full"
            style="height: calc(100vh - 305px)"
          >
            <tr v-for="accessLog in sortAccessLogs" :key="accessLog.timestamp">
              <td class="td-grade">{{ accessLog.grade }}</td>
              <td class="td-loginId">{{ accessLog.loginId }}</td>
              <td class="td-displayName">{{ accessLog.displayName }}</td>
              <td class="td-timestamp">
                {{ accessLog.timestamp.slice(-12, -4) }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  Express: 'UsageStatsDetailTable',
  props: {
    accessLogs: { type: Object, default: () => {} },
  },
  data() {
    return {
      sortKey: 'timestamp',
      sortAsc: true,
    };
  },
  // created: function () {},
  methods: {
    sortBy(key) {
      this.sortKey === key
        ? (this.sortAsc = !this.sortAsc)
        : (this.sortAsc = true);
      this.sortKey = key;
    },
    addClass(key) {
      return {
        asc: this.sortKey === key && this.sortAsc,
        desc: this.sortKey === key && !this.sortAsc,
      };
    },
  },
  computed: {
    sortAccessLogs() {
      let sortAccessLogs = this.accessLogs.usageStatsDetail.slice();
      if (this.sortKey != '') {
        let set = 1;
        this.sortAsc ? (set = 1) : (set = -1);
        sortAccessLogs.sort((a, b) => {
          if (a[this.sortKey] < b[this.sortKey]) return -1 * set;
          if (a[this.sortKey] > b[this.sortKey]) return 1 * set;
          return 0;
        });
      }
      return sortAccessLogs;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
thead,
tbody,
tr {
  @apply flex w-full;
}
tbody {
  @apply flex-col items-center overflow-y-scroll bg-gray-50;
}
th,
td {
  @apply px-4 border-gray-200 text-xs;
}
th {
  @apply py-2 border-b-2 bg-gray-100 text-center font-semibold text-gray-600 tracking-wider flex items-center place-content-center;
}
td {
  @apply py-1 border-b bg-white flex items-center;
}

th.td-grade {
  width: 16%;
}
td.td-grade {
  width: calc(16% + 5px);
  @apply place-content-center;
}
th.td-loginId {
  width: 18%;
}
td.td-loginId {
  width: calc(18% + 5px);
}
th.td-displayName {
  width: 40%;
}
td.td-displayName {
  width: calc(40% + 5px);
}
th.td-timestamp {
  width: 26%;
}
td.td-timestamp {
  width: calc(26% - 15px);
  @apply place-content-center;
}

.td-p {
  @apply text-gray-900;
}
.asc:after {
  content: '⇧';
  font-size: small;
  padding-left: 1px;
}
.desc:after {
  content: '⇩';
  font-size: small;
  padding-left: 1px;
}
select {
  @apply focus:ring-0;
}
</style>
