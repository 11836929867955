<template>
  <div class="antialiased">
    <div class="mt-3 mb-1">
      <div class="flex md:flex-row flex-col">
        <div class="flex flex-row mb-2 md:mb-0">
          <div class="items-start">
            <p class="text-sm">
              <font-awesome-icon icon="caret-down" class="ml-1" />
              都道府県
            </p>
            <select
              v-model="pref"
              class="
                h-12
                w-28
                text-sm
                rounded-l
                border border-r-0
                bg-white
                border-gray-400
                text-gray-700
                py-1
                px-4
                pr-8
                leading-tight
                focus:outline-none focus:bg-white focus:border-gray-500
              "
            >
              <option
                v-bind:value="{ name: 'すべて', code: '' }"
                selected="selected"
              >
                すべて
              </option>
              <option
                v-for="pref in prefList"
                :key="pref.code"
                v-bind:value="pref"
              >
                {{ pref.name }}
              </option>
            </select>
          </div>
          <div class="items-start">
            <p class="text-sm">
              <font-awesome-icon icon="caret-down" class="ml-1" />
              学校区分
            </p>
            <select
              v-model="schoolType"
              class="
                h-12
                w-32
                text-sm
                border md:border-r-0 rounded-r md:rounded-none
                bg-white
                border-gray-400
                text-gray-700
                py-1
                px-4
                pr-8
                leading-tight
                focus:outline-none focus:bg-white focus:border-gray-500
              "
            >
              <option
                v-bind:value="{ name: 'すべて', code: '' }"
              >
                すべて
              </option>
              <option
                v-for="st in schoolTypeList"
                :key="st.code"
                v-bind:value="{ name: st.name, code: st.code }"
              >
                {{ st.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="flex">
          <div class="relative">
            <p class="text-sm">
              <font-awesome-icon icon="caret-down" class="ml-1" />
              学校コード / 学校名
            </p>
            <multiselect
              v-model="school"
              :options="setOptions(conditionSchoolList)"
              :close-on-select="true"
              placeholder="入力、選択してください"
              selectedLabel=""
              selectLabel=""
              deselectLabel=""
              label="name"
              track-by="name"
            >
              <template v-slot:noResult>
                {{noResult}}
              </template>
              <template v-slot:noOptions>
                {{noOptions}}
              </template>
            </multiselect>
          </div>

          <div class="mt-5">
            <div class="ml-4 inline-block" v-tooltip="{content: '検索'}">
              <button v-on:click="search" class="btn-search btn-circle">
                <font-awesome-icon icon="search" class="" />
              </button>
            </div>
            <div class="ml-2 inline-block" v-tooltip="{content: 'クリア'}">
              <button v-on:click="clear" class="btn-gray btn-circle">
                <font-awesome-icon icon="backspace" class="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import prefList from '../const/prefList';
import Message from '../const/message';

export default {
  props: {
    schoolList: {
      type: Array,
      default: () => [],
    },
    schoolTypeList: {
      type: Array,
      default: () => [],
    },
    handleSearch: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      conditionSchoolList: [],
      prefList: prefList,
      school: null,
      pref: { name: 'すべて', code: '' },
      schoolType: { name: 'すべて', code: '' },
      noResult: Message.MULTI_NOT_RESULT,
      noOptions: Message.MULTI_NOT_OPTIONS,
    };
  },
  methods: {
    setOptions(schoolList) {
      let options = new Array();
      schoolList.forEach((s) => {
        let option = {
          name: s.schoolCd + ' / ' + s.schoolName,
          code: s.schoolCd,
        };
        options.push(option);
      });
      return options;
    },
    search() {
      const schoolCd = this.school ? this.school.code : '';
      this.$store.commit({
        type:'setSearchSchoolKeys',
        pref: this.pref,
        schoolType: this.schoolType,
        school: this.school,
      });
      this.handleSearch(schoolCd, this.pref.code, this.schoolType.code);
    },
    clear() {
      Object.assign(this.$data, this.$options.data());
      const schoolTypeAll = { name: 'すべて', code: '' };
      if (this.schoolTypeList.length) {
        // 学校区分リストが取得できた場合はデフォルトフラグを参照し、デフォルト値を選択
        const schoolType = this.schoolTypeList.find( st => st.isDefault );
        this.schoolType = schoolType ? { name: schoolType.name, code: schoolType.code } : schoolTypeAll;
      } else {
        // 学校区分リストが空の場合は「すべて」をデフォルト選択
        this.schoolType = this.schoolTypeAll;
      }
      this.filterSchools();
    },
    filterSchools() {
      if (this.pref.code && this.schoolType.code) {
        // 選択された都道府県と学校区分で学校リストを絞り込み
        this.conditionSchoolList = this.schoolList.filter(
          s => s.prefCd === this.pref.code && s.schoolType === this.schoolType.code
        );
      } else if (this.pref.code) {
        // 選択された都道府県で学校リストを絞り込み
        this.conditionSchoolList = this.schoolList.filter( s => s.prefCd === this.pref.code);
      } else if (this.schoolType.code) {
        // 選択された学校区分で学校リストを絞り込み
        this.conditionSchoolList = this.schoolList.filter( s => s.schoolType === this.schoolType.code);
      } else {
        // 都道府県と学校区分が未選択の場合は全件を返却
        this.conditionSchoolList = this.schoolList;
      }
    },
  },
  watch: {
    pref: {
      handler: function () {
        // 学校区分と都道府県で学校リストを絞り込み
        this.filterSchools();
      },
      deep: true,
    },
    schoolType: {
      handler: function () {
        // 学校区分と都道府県で学校リストを絞り込み
        this.filterSchools();
      },
      deep: true,
    },
    school: {
      handler: function (school) {
        if (school && school.code){
          // 学校が選択された場合、検索API呼び出し
          this.search();
        }
      },
      deep: true,
    },
    schoolList: {
      handler: function (schoolList) {
        if (this.$store.state.searchSchoolKeys) {
          this.pref = this.$store.state.searchSchoolKeys.pref ?? { name: 'すべて', code: '' };
          this.school = this.$store.state.searchSchoolKeys.school;
        } else {
          if (this.pref.code || this.schoolType.code){
            // 都道府県または学校区分が選択済の場合、学校リストを絞り込み
            this.filterSchools();
          } else {
            // 都道府県または学校区分が未選択の場合、学校全件をセット
            this.conditionSchoolList = schoolList;
          }
          if (!this.school || !this.schoolList.some((s) => s.schoolCd === this.school.code)){
            // 学校が未選択、または選択済みの学校が学校リストに存在しない場合は、空の学校コードで上書き
            this.school = null;
          }
        }
      },
      deep: true,
    },
    schoolTypeList: {
      handler: function(schoolTypeList){
        const schoolTypeAll = { name: 'すべて', code: '' };
        if (this.$store.state.searchSchoolKeys) {
          this.schoolType = this.$store.state.searchSchoolKeys.schoolType ?? { name: 'すべて', code: '' };
        } else {
          if (schoolTypeList.length) {
            // 学校区分リストが取得できた場合はデフォルトフラグを参照し、デフォルト値を選択
            const schoolType = schoolTypeList.find( st => st.isDefault );
            this.schoolType = schoolType ? { name: schoolType.name, code: schoolType.code } : schoolTypeAll;
          } else {
            // 学校区分リストが空の場合は「すべて」をデフォルト選択
            this.schoolType = schoolTypeAll;
          }
        }
      },
      deep: true,
    }
  }
};
</script>

<style scoped>
.multiselect {
  @apply m-0 p-0;
}
.multiselect >>> .multiselect__single {
  min-height: 15px;
  width: 190px;
  @apply m-0 p-0 h-10 text-sm leading-tight;
}
.multiselect >>> .multiselect__input {
  min-height: 15px;
  @apply m-0 p-0 h-8 text-sm leading-tight;
}
.multiselect >>> .multiselect__tags {
  min-height: 48px;
  max-height: 48px;
  padding-top: 6px;
  width: 280px;
  @apply text-sm border rounded md:rounded-l-none bg-white border-gray-400 text-gray-700;
}
.multiselect >>> .multiselect__select {
  top: 4px;
  transform: none;
}
.multiselect >>> .multiselect__content-wrapper {
  @apply overflow-x-hidden;
}
input[type='checkbox'] {
  @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}
select {
  @apply focus:ring-0;
}
</style>
