<template>
  <div class="antialiased">
    <table
      class="licencse-user-table bg-gray-50 w-full block whitespace-nowrap"
    >
      <thead class="">
        <tr style="table-layout: fixed">
          <th v-if="schoolName" class="fixed-th-rowspan td-user-info">
            <p>学校名：</p>
            <p>{{ schoolName }}</p>
          </th>
          <template v-for="displayDate in displayDates">
            <th
              class="fixed-th first-row saturday"
              :key="displayDate.date"
              v-if="displayDate.day === 6"
            >
              {{ displayDate.date }}
            </th>
            <th
              class="fixed-th first-row sunday"
              :key="displayDate.date"
              v-else-if="displayDate.day === 0"
            >
              {{ displayDate.date }}
            </th>
            <th :key="displayDate.date" v-else class="fixed-th first-row">
              {{ displayDate.date }}
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="userStats in usageStatsList" :key="userStats.viewId">
          <th class="td-user-info">
            <p>
              <span>{{ userStats.viewName }}</span>
            </p>
          </th>

          <td
            v-for="dateCount in userStats.dateCounts"
            :key="dateCount"
            class="text-center"
          >
            <router-link
              v-if="dateCount.count"
              :to="{
                name: 'usage-stats-detail',
                params: {
                  schoolCd: schoolCd,
                  date: dateCount.date,
                  id: userStats.viewId,
                },
                query: {
                  grade: grade,
                }
              }"
            >
              {{ dateCount.count }}
            </router-link>
            <span v-if="!dateCount.count">
              {{ dateCount.count }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'UsageStatsTable',
  props: {
    schoolCd: { type: String, default: () => '' },
    schoolName: { type: String, default: () => '' },
    usageStatsList: { type: Array, default: () => [] },
    displayDates: { type: Array, default: () => [] },
    grade: { type: String, default: () => '' },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input:read-only {
  @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-200;
}
input,
select,
textarea {
  @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}
select,
textarea {
  @apply mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-300;
}

th,
td {
  @apply py-2 border w-14 border-gray-200;
}
td {
  @apply px-1 text-sm;
}
th {
  @apply px-2 w-16 bg-gray-100 text-xs;
}
th.saturday {
  @apply bg-blue-100;
}
th.sunday {
  @apply bg-red-100;
}

.td-user-info {
  @apply w-40 text-left;
}

.fixed-th,
.td-user-info,
.fixed-th-rowspan {
  @apply sticky;
  top: 0;
  left: 0;
}
.fixed-th-rowspan {
  @apply z-20;
}
.fixed-th,
td.td-user-info {
  @apply z-10;
}
td.td-user-info {
  @apply h-12;
}
.first-row {
  height: 32px;
}

.btn-small {
  @apply py-0.5 px-1.5 rounded text-xs;
}

a {
  @apply text-indigo-800 underline font-bold;
}
</style>
