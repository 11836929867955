<template>
  <div class="antialiased">
    <div class="flex flex-row">
      <div class="flex flex-row mb-2">
        <div class="">
          <p class="text-sm">
            <font-awesome-icon icon="caret-down" class="ml-1" />
            教科名
          </p>
          <select
            v-model="subject"
            class="
              h-8
              w-24
              text-sm
              rounded-l
              border border-r-0
              bg-white
              border-gray-400
              text-gray-700
              py-2
              px-4
              pr-8
              leading-tight
              focus:outline-none focus:bg-white focus:border-gray-500
            "
          >
            <option
              v-bind:value="{ name: 'すべて', code: '' }"
              selected="selected"
            >
              すべて
            </option>
            <option
              v-for="sbj in subjectList"
              :key="sbj.code"
              v-bind:value="sbj"
            >
              {{ sbj.name }}
            </option>
          </select>
        </div>
        <div class="relative">
          <p class="text-sm">
            <font-awesome-icon icon="caret-down" class="ml-1" />
            電子教材名
          </p>
          <span class="h-1/3 absolute inset-y-7 left-0 flex items-center pl-2">
            <svg viewBox="0 0 24 24" class="h-4 w-4 fill-current text-gray-500">
              <path
                d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"
              ></path>
            </svg>
          </span>
          <input
            v-model="name"
            placeholder="電子教材名を入力"
            maxlength="50"
            class="
              h-8
              w-48 sm:w-60
              appearance-none
              rounded rounded-l-none
              border-r border border-gray-400 border-b
              pl-8
              pr-6
              py-2
              bg-white
              text-sm
              placeholder-gray-400
              text-gray-700
              focus:bg-white
              focus:placeholder-gray-600
              focus:text-gray-700
              focus:outline-none
            "
          />
        </div>
      </div>
      <div class="mt-2">
        <div class="ml-2 sm:ml-4 inline-block" v-tooltip="{content: '検索'}">
          <button v-on:click="search" class="btn-search btn-circle">
            <font-awesome-icon icon="search" class="" />
          </button>
        </div>
        <div class="ml-1 sm:ml-2 inline-block" v-tooltip="{content: 'クリア'}">
          <button v-on:click="clear" class="btn-gray btn-circle">
            <font-awesome-icon icon="backspace" class="" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  express: 'Form',
  props: {
    subjectList: {
      type: Array,
      default: () => [],
    },
    handleSearch: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      subject: { name: 'すべて', code: '' },
      name: '',
    };
  },
  created() {
    if(this.$store.state.searchBookKeys){
      this.subject = this.$store.state.searchBookKeys.subject;
      this.name = this.$store.state.searchBookKeys.name;
      this.search();
    }
  },
  methods: {
    search() {
      this.$store.commit({
        type:'setSearchBookKeys',
        subject: this.subject,
        name: this.name,
      });
      this.handleSearch(this.subject.code, this.name);
    },
    clear() {
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
