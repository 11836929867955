<template>
  <div class="max-w-5xl mx-auto">
    <Title msg="画面別閲覧数一覧" submsg="Screen View List" />
    <div>
      <UsageStatsForm
        :handleSearch="search"
        :schoolList="allSchools"
        :isSysAdmin="isSysAdmin"
      />
    </div>
    <ErrMsgCard :errMsgs="errMsgs" process="検索" />
    <div class="mt-4 max-w-max">
      <UsageStatsTable
        :schoolName="schoolName"
        :usageStatsList="usageStats"
        :displayDates="displayDates"
        :schoolCd="schoolCd"
        :grade="grade"
      />
    </div>
    <div class="my-4 flex max-h-screen w-full" :class="bgWhite">
      <UsageStatsChart
        :usageStatsList="usageStats"
        :displayDates="displayDates"
      />
    </div>
  </div>
</template>

<script>
import { searchUsageStats, searchSchools } from '../api';
import UserAuth from '../const/userAuthority';
import Title from '../components/TheTitle.vue';
import UsageStatsForm from '../components/UsageStatsForm.vue';
import UsageStatsTable from '../components/UsageStatsTable.vue';
import UsageStatsChart from '../components/UsageStatsChart.vue';
import ErrMsgCard from '../components/ErrorMssageCard.vue';
import Validation from '../utils/validation';
import Authorize from '../utils/authorize';

export default {
  name: 'UsageStats',
  components: {
    Title,
    UsageStatsForm,
    UsageStatsTable,
    UsageStatsChart,
    ErrMsgCard,
  },
  data() {
    return {
      usageStats: [],
      allSchools: [],
      schoolName: '',
      displayDates: [],
      schoolCd: '',
      grade: '',
      isSysAdmin: this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code,
      errMsgs: [],
    };
  },
  created: async function () {
    // 権限チェック
    if (
      (this.$store.state.authority === UserAuth.TEACHER.code ||
        this.$store.state.authority === UserAuth.SCHOOL_ADMIN.code) &&
      this.$store.state.schoolCd === this.$route.params.schoolCd
    ) {
      return false;
    } else if (this.isSysAdmin) {
      // システム管理者の場合、学校コードを全件取得
      const rspBody = await searchSchools(this.$route.path);
      this.allSchools = rspBody.detail;
    } else {
      // エラーページに遷移
      this.$router.push({
        name: 'not-found',
        params: { catchAll: 'not-found' },
      });
      // 処理強制終了
      return false;
    }
  },
  methods: {
    async search(school, grade, startDateString, endDateString) {
      if (!this.isSysAdmin) {
        // システム管理者でない場合、検索条件の学校コードに自身の所属する学校をセット
        school.value.code = this.$store.state.schoolCd;
      }
      // バリデーション定義作成
      let validationArray = [];
      // バリデーションチェック実施
      this.errMsgs = validationArray.concat(
        Validation.requiredSelect(school),
        Validation.requiredInput(startDateString),
        Validation.isDate(startDateString),
        Validation.requiredInput(endDateString),
        Validation.isDate(endDateString),
        Validation.isOkUsageStatsDate(startDateString, endDateString)
      );

      this.schoolName = '';
      this.usageStats = [];
      this.displayDates = [];

      // エラーメッセージがある場合は処理を中断
      if (this.errMsgs.length) return false;

      if (Date.now() / 1000 > this.$store.state.tokenExp){
        if (!await Authorize.isAuth()) {
          // 認証失敗：ログイン画面へ遷移
          this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
          return false;
        }
      }

      let self = this;
      // 画面別閲覧数を取得
      searchUsageStats(
        function (rspBody) {
          if (rspBody.result.code === 9) {
            self.errMsgs = rspBody.result.messages;
          } else {
            self.schoolCd = school.value.code;
            self.schoolName = rspBody.detail.schoolName;
            self.usageStats = rspBody.detail.usageStats;
            self.grade = grade.value.code;
            // 日付文字列をDate型に変換
            const startDate = new Date(startDateString.value);
            const endDate = new Date(endDateString.value);
            // 期間内の日付の配列を作成
            self.displayDates = [];
            for (let d = startDate; d <= endDate; d.setDate(d.getDate() + 1)) {
              const month = d.getMonth() + 1;
              const date = d.getDate();
              const day = d.getDay();

              self.displayDates.push({
                date: month + '/' + date,
                day: day,
              });
            }
          }
        },
        school.value.code,
        grade.value.code,
        startDateString.value,
        endDateString.value,
        this.$route.path
      );
    },
  },
  computed: {
    bgWhite() {
      if (this.displayDates.length) {
        return 'bg-gray-50';
      } else {
        return '';
      }
    },
  },
};
</script>

<style scoped></style>
