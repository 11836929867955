<template>
  <h1>
    {{msg}}
    <span class="pl-4 italic text-indigo-200 text-lg">
      {{submsg}}
    </span>
  </h1>
</template>

<script>
export default {
  name: 'Title',
  props: {
    msg: String,
    submsg: String
  },
  data () {
    return {
      counter: 0
    };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    @apply text-2xl font-bold mt-3 py-2 relative border-b-2 border-indigo-400;
/*   border-bottom: solid 3px #cce4ff; */
}

h1:after {
    @apply font-bold py-2 absolute border-b-2 border-indigo-800 w-1/5 block;
  content: " ";
  bottom: -2px;
}
</style>