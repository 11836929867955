<template>
  <div class="antialiased">
    <div class="mt-1">
      <div class="flex xl:flex-row flex-col">
        <div class="flex flex-row mb-0">
          <div class="relative" v-if="isSysAdmin">
            <p class="text-sm">
              <font-awesome-icon icon="caret-down" class="ml-1" />
              {{ school.key }}
            </p>
            <multiselect
              v-model="school.value"
              :options="setSchoolOptions(schoolList)"
              :close-on-select="true"
              placeholder="入力、選択してください"
              selectedLabel=""
              selectLabel=""
              deselectLabel=""
              label="name"
              track-by="name"
            >
              <template v-slot:noResult>
                {{noResult}}
              </template>
              <template v-slot:noOptions>
                {{noOptions}}
              </template>
            </multiselect>
          </div>

          <div class="">
            <p class="text-sm">
              <font-awesome-icon icon="caret-down" class="ml-1" />
              {{ authority.key }}
            </p>
            <select
              v-model="authority.value"
              class="w-32"
              :class="{
                'form-left': !isSysAdmin,
                'xl-form-middle form-right': isSysAdmin && !isSelectedStudent,
                'xl-form-left form-right': !isSysAdmin && !isSelectedStudent,
                'form-middle': isSysAdmin,
              }"
            >
              <option
                v-bind:value="{ name: 'すべて', code: '' }"
                selected="selected"
              >
                すべて
              </option>
              <option
                v-for="auth in authorityList"
                :key="auth.code"
                v-bind:value="auth"
              >
                {{ auth.name }}
              </option>
            </select>
          </div>
          <div v-if="isSelectedStudent">
            <p class="text-sm">
              <font-awesome-icon icon="caret-down" class="ml-1" />
              {{ grade.key }}
            </p>
            <select
              v-model="grade.value"
              class="form-right xl-form-middle w-24"
            >
              <option
                v-bind:value="{ name: 'すべて', code: '' }"
                selected="selected"
              >
                すべて
              </option>
              <option
                v-for="grd in getGradeList"
                :key="grd"
                v-bind:value="{ name: grd + '年', code: grd }"
              >
                {{ grd }}年
              </option>
            </select>
          </div>
        </div>

        <div class="flex flex-col md:flex-row mb-3 xl:mb-0">
          <div class="flex items-start">
            <div v-if="isSelectedStudent" class="inline-block">
              <p class="text-sm">
                <font-awesome-icon icon="caret-down" class="ml-1" />
                {{ baseYear.key }}
              </p>
              <select
                v-model="baseYear.value"
                class="form-left xl-form-middle w-44"
              >
                <option v-bind:value="getNowYear" selected>
                  今年度（{{ getNowYear }}年度）
                </option>
                <option v-bind:value="getNowYear + 1">
                  来年度（{{ getNowYear + 1 }}年度）
                </option>
              </select>
            </div>

            <div class="inline-block">
              <p class="text-sm">
                <font-awesome-icon icon="caret-down" class="ml-1" />
                {{ loginId.key }}
              </p>
              <input
                type="text"
                v-model="loginId.value"
                placeholder="ﾛｸﾞｲﾝIDを入力"
                maxlength="13"
                class="form-right"
                style="width: 8.5rem;"
                :style="{
                  'border-top-left-radius': isSelectedStudent ? '0px' : '0.375rem',
                  'border-bottom-left-radius': isSelectedStudent ? '0px' : '0.375rem',
                }"
              />
            </div>
          </div>

          <div class="mt-5">
            <div class="md:ml-4 inline-block" v-tooltip="{content: '検索'}">
              <button v-on:click="search(false)" class="btn-search btn-circle">
                <font-awesome-icon icon="search" class="" />
              </button>
            </div>
            <div v-if="isSchoolAdmin" class="ml-2 inline-block" v-tooltip="{content: 'ユーザ情報カード出力'}">
              <button v-on:click="downloadUsersCard" class="btn-search btn-circle">
                <font-awesome-icon icon="id-card" size="lg" class="" />
              </button>
            </div>
            <div v-if="isSysAdmin || isSchoolAdmin" class="ml-2 inline-block" v-tooltip="{content: 'CSV出力'}">
              <button v-on:click="search(true)" class="btn-search btn-circle">
                <font-awesome-icon icon="file-csv" size="lg" class="" />
              </button>
            </div>
            <div class="ml-2 inline-block" v-tooltip="{content: 'クリア'}">
              <button v-on:click="clear" class="btn-gray btn-circle">
                <font-awesome-icon icon="backspace" class="" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserAuth from '../const/userAuthority';
import Validation from '../utils/validation';
import Message from '../const/message';

export default {
  name: 'UserListForm',
  props: {
    schoolList: {
      type: Array,
      default: () => [],
    },
    handleSearch: {
      type: Function,
      required: true,
    },
    handleDownloadCsv: {
      type: Function,
      required: true,
    },
    handleDownloadUsersCard: {
      type: Function,
      required: true,
    },
    handleGetFileStatus: {
      type: Function,
      required: true,
    },
    isSysAdmin: {
      type: Boolean,
      default: () => false,
    },
    isSchoolAdmin: {
      type: Boolean,
      default: () => false,
    },
    resetSchoolCd: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      school: { key: '学校コード/学校名', value: { name: '', code: '' } },
      authority: { key: 'ユーザ区分', value: { name: 'すべて', code: '' } },
      grade: { key: '学年', value: { name: 'すべて', code: '' } },
      baseYear: { key: '基準年度', value: '' },
      loginId: { key: 'ログインID', value: '' },
      authorityList: [],

      errMsgs: [],
      noResult: Message.MULTI_NOT_RESULT,
      noOptions: Message.MULTI_NOT_OPTIONS,
    };
  },
  created: function () {
    this.baseYear.value = this.getNowYear;
    this.authorityList = UserAuth.getList(this.$store.state.authority);
    if (this.$store.state.searchUserKeys) {
      this.baseYear.value = this.$store.state.searchUserKeys.baseYear;
      this.authority.value = this.$store.state.searchUserKeys.authority;
      this.grade.value = this.$store.state.searchUserKeys.grade ?? this.getNowYear;
      this.loginId.value = this.$store.state.searchUserKeys.loginId ?? '';
      if (this.$store.state.authority !== UserAuth.SYSTEM_ADMIN.code) {
        // システム管理者以外はここで再検索
        this.search();
      }
    }
  },
  methods: {
    setSchoolOptions(schoolList) {
      let options = new Array();
      schoolList.forEach((s) => {
        let option = {
          name: s.schoolCd + ' / ' + s.schoolName,
          code: s.schoolCd,
        };
        options.push(option);
      });
      return options;
    },
    search(isCsv = false) {
      // バリデーション定義作成
      let validationArray = [];
      // バリデーションチェック実施
      this.errMsgs = validationArray.concat(
        this.isSysAdmin
          ? Validation.requiredSelect(this.school)
          : [],
        Validation.requiredInput(this.baseYear),
        Validation.digitsMax(this.loginId, 13)
      );

      // エラーメッセージがある場合は処理を中断
      if (this.errMsgs.length) {
        this.$store.commit('setMessages', this.errMsgs);
        this.$store.commit('setCancelBtnHide', true);
        this.$store.commit('setIsModalOpen', true);
        return false;
      }

      this.$store.commit({
        type:'setSearchUserKeys',
        school: this.school.value,
        baseYear: this.baseYear.value,
        authority: this.authority.value,
        grade: this.grade.value,
        loginId: this.loginId.value,
      });

      if(isCsv){
        this.handleDownloadCsv(
          this.school.value.code,
          this.baseYear.value,
          this.authority.value.code,
          this.grade.value.code,
          this.loginId.value
        );
      } else {
        this.handleSearch(
          this.school.value.code,
          this.baseYear.value,
          this.authority.value.code,
          this.grade.value.code,
          this.loginId.value
        );
      }
    },
    downloadUsersCard(){
      // バリデーション定義作成
      let validationArray = [];
      // バリデーションチェック実施
      this.errMsgs = validationArray.concat(
        this.isSysAdmin
          ? Validation.requiredSelect(this.school)
          : [],
        Validation.isNonSelectableSchoolAdmin(this.authority),
        Validation.requiredInput(this.baseYear),
        Validation.digitsMax(this.loginId, 13)
      );

      // エラーメッセージがある場合は処理を中断
      if (this.errMsgs.length) {
        this.$store.commit('setMessages', this.errMsgs);
        this.$store.commit('setCancelBtnHide', true);
        this.$store.commit('setIsModalOpen', true);
        return false;
      }
      this.handleDownloadUsersCard(
        this.baseYear.value,
        this.authority.value.code,
        this.grade.value.code,
        this.loginId.value
      );
    },
    clear() {
      Object.assign(this.$data, this.$options.data());
      // 学校リストが存在する場合、学校リストをセット、
      // 存在しない場合、パスパラメータの学校コードをセット
      this.school.value = this.schoolList.length > 0 ? 
        this.getSchoolValue(this.$route.params.schoolCd) : {name:'', code:this.$route.params.schoolCd};
      this.baseYear.value = this.getNowYear;
      this.authorityList = UserAuth.getList(this.$store.state.authority);
    },
    clearOutsideSchool() {
      const schoolValue = this.school.value;
      Object.assign(this.$data, this.$options.data());
      // 学校については、元の値を設定する
      this.school.value = schoolValue;
      this.baseYear.value = this.getNowYear;
      this.authorityList = UserAuth.getList(this.$store.state.authority);
    },
    getSchoolValue(schoolCd){
      const schoolName = this.schoolList.find((s) => s.schoolCd === schoolCd).schoolName
      return {
        name: schoolCd + ' / ' + schoolName,
        code: schoolCd,
      };
    }
  },
  computed: {
    getNowYear() {
      let currentTime = new Date();
      // 年度を取得したいため、現在から3ヶ月前にセット
      currentTime.setMonth(currentTime.getMonth() - 3);
      return currentTime.getFullYear();
    },
    getGradeList() {
      // 1から最高学年までの数字を配列化
      return [...Array(this.$store.state.maxGrade).keys()].map((i) => ++i);
    },
    isSelectedStudent() {
      // ユーザ区分で '学習者' または 'すべて' を選択しているかどうか
      return (this.authority.value.code === UserAuth.STUDENT.code) || (this.authority.value.code === '');
    }
  },
  watch: {
    school: {
      handler: async function (school) {
        if (school.value){
          // 値に変更があった場合のみ以下処理を行う
          if (this.$parent.schoolCd !== school.value.code) {
            // CSVファイルの取込状態を取得
            await this.handleGetFileStatus(school.value.code);
          }
        } else {
          // 値がない場合は空の値をセット
          this.school.value = { name: '', code: '' };
        }
        // 学校が選択されるたびに親コンポーネントに学校コードを渡す
        this.$parent.schoolCd = this.school.value.code;
      },
      deep: true,
    },
    resetSchoolCd: function (schoolCd) {
      if(this.$store.state.searchUserKeys) {
        this.school.value = this.$store.state.searchUserKeys.school;
      } else {
        // 親コンポーネントから学校コードが渡された場合は学校コードを上書き
        if (schoolCd !== this.school.value.code)
          this.school.value = schoolCd ? this.getSchoolValue(schoolCd) : { name: '', code: '' };
      }
      // 学校コードで全件検索
      this.search();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.multiselect {
  @apply m-0 p-0;
}
.multiselect >>> .multiselect__single {
  min-height: 15px;
  width: 200px;
  @apply m-0 p-0 h-10 text-sm leading-tight;
}
.multiselect >>> .multiselect__input {
  min-height: 15px;
  @apply m-0 p-0 h-8 text-sm leading-tight;
}
.multiselect >>> .multiselect__tags {
  min-height: 48px;
  max-height: 48px;
  padding-top: 6px;
  width: 240px;
  @apply text-sm border rounded-md border-r-0 rounded-l-md rounded-r-none bg-white border-gray-400 text-gray-700;
}
.multiselect >>> .multiselect__select {
  top: 4px;
  transform: none;
}
.multiselect >>> .multiselect__content-wrapper {
  @apply overflow-x-hidden;
}

input[type='text'] {
  @apply mt-0 px-3 xl:rounded-l-none focus:border-gray-400;
  line-height: 28px;
  height: 48px;
}
@media (min-width: 1280px) {
  input[type='text'] {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
}

.form-left {
  @apply h-12 
         py-2 px-4
         leading-tight
         text-sm text-gray-700
         border border-r-0 border-gray-400
         rounded-l-md
         focus:ring-0;
}

.form-middle {
  @apply h-12 
         py-2 px-4 pr-8
         leading-tight
         text-sm text-gray-700
         border border-r-0 border-gray-400
         focus:ring-0;
}
.form-right {
  @apply h-12 
         py-2 px-4 pr-8
         leading-tight
         text-sm text-gray-700
         border border-gray-400
         rounded-r-md
         focus:ring-0;
}
.xl-form-middle {
  @apply xl:border-r-0 xl:rounded-none;
}
.xl-form-left {
  @apply xl:border-r-0 xl:rounded-r-none
}
.btn {
  @apply font-semibold w-24 rounded py-2 px-2 text-sm focus:outline-none focus:ring focus:ring-opacity-50;
}
</style>
