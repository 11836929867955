import Type from '../const/validationType';
import FileType from '../const/fileType';
import Message from '../const/message';
import UserAuth from '../const/userAuthority';
/** バリデーション */
export default class Validation {
  /**
   * 必須チェック：入力
   * @typedef {object} Item
   * @property {string} key 項目名
   * @property {*} value 値
   * @param {Item} item チェックする項目
   * @param {boolean}} isFile ファイルかどうか(true: ファイルである、false: ファイルでない)
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static requiredInput(item, isFile = false) {
    let errMsgs = [];
    if (!item.value) {
      const msgPamams = [item.key];
      // エラーメッセージを作成
      if (isFile) {
        // ファイルの場合は「必須チェック：選択」のメッセージを表示する
        errMsgs.push(
          Message.generateMessage(Type.REQUIRED_SELECT.message, msgPamams)
        );
      } else {
        errMsgs.push(
          Message.generateMessage(Type.REQUIRED_INPUT.message, msgPamams)
        );
      }
    }
    return errMsgs;
  }

  /**
   * 必須チェック：選択
   * @typedef {object} SelectItem
   * @property {string} key 項目名
   * @property {SelectItemValue} value 値
   * @typedef {object} SelectItemValue
   * @property {string} code コード
   * @property {item} name コードの論理名
   * @param {SelectItem} item チェックする項目({key: '項目名', value: {code: '値', name: 'codeの論理名'}})
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static requiredSelect(item) {
    let errMsgs = [];
    if (!item.value.code) {
      const msgPamams = [item.key];
      // エラーメッセージを作成
      errMsgs.push(
        Message.generateMessage(Type.REQUIRED_SELECT.message, msgPamams)
      );
    }
    return errMsgs;
  }

  /**
   * 属性チェック：ファイル
   * @typedef {object} FileItem
   * @property {string} key 項目名
   * @property {File} value 値
   * @param {FileItem} item チェックする項目
   * @param {FileType} fileType ファイルの種類
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static isFile(item, fileType = FileType.ZIP) {
    let errMsgs = [];
    const regex = new RegExp(fileType.regex);
    // 正規表現チェック
    if (item.value && !regex.test(item.value.type)) {
      const msgPamams = [item.key, fileType.name];
      // エラーメッセージを作成
      errMsgs.push(Message.generateMessage(Type.IS_FILE.message, msgPamams));
    }
    return errMsgs;
  }

  /**
   * 属性チェック：数字（0以上の整数）
   * @typedef {object} Item
   * @property {string} key 項目名
   * @property {(number|string)} value 値
   * @param {Item} item チェックする項目
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static isInteger(item) {
    let errMsgs = [];
    const regex = new RegExp(/^[0-9]*$/);
    const value = item.value.code ? item.value.code : item.value;
    // 正規表現チェック
    if (value && (!regex.test(value) || Number(value) < 0)) {
      const msgPamams = [item.key];
      // エラーメッセージを作成
      errMsgs.push(Message.generateMessage(Type.IS_INTEGER.message, msgPamams));
    }
    return errMsgs;
  }

  /**
   * 属性チェック：年月（yyyy-MM形式）
   * @typedef {object} StringItem
   * @property {string} key 項目名
   * @property {string} value 値（yyyy-MM形式）
   * @param {StringItem} item チェックする項目
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static isYearMonth(item) {
    let errMsgs = [];
    const regex = new RegExp(/^[0-9]{4}-[0-9]{2}$/);
    let y = item.value.split('-')[0];
    let m = item.value.split('-')[1] - 1;
    const date = new Date(y, m, 1);
    const isNotDate = date.getFullYear() != y || date.getMonth() != m;
    // 正規表現 & 有効な日付形式かどうかチェック
    if (item.value && (!regex.test(item.value) || isNotDate)) {
      const msgPamams = [item.key];
      // エラーメッセージを作成
      errMsgs.push(
        Message.generateMessage(Type.IS_YEAR_MONTH.message, msgPamams)
      );
    }
    return errMsgs;
  }

  /**
   * 属性チェック：日付（yyyy-MM-dd形式）
   * @typedef {object} StringItem
   * @property {string} key 項目名
   * @property {string} value 値（yyyy-MM-dd形式）
   * @param {StringItem} item チェックする項目
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static isDate(item) {
    let errMsgs = [];
    const regex = new RegExp(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/);
    let y = item.value.split('-')[0];
    let m = item.value.split('-')[1] - 1;
    let d = item.value.split('-')[2];
    const date = new Date(y, m, d);
    const isNotDate =
      date.getFullYear() != y || date.getMonth() != m || date.getDate() != d;
    // 正規表現 & 有効な日付形式かどうかチェック
    if (item.value && (!regex.test(item.value) || isNotDate)) {
      const msgPamams = [item.key];
      // エラーメッセージを作成
      errMsgs.push(Message.generateMessage(Type.IS_DATE.message, msgPamams));
    }
    return errMsgs;
  }

  /**
   * 属性チェック：英数字のみ（記号なし）
   * @typedef {object} StringItem
   * @property {string} key 項目名
   * @property {string} value 値
   * @param {StringItem} item チェックする項目
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static isNotContainSymbols(item) {
    let errMsgs = [];
    const regex = new RegExp(/^[0-9a-zA-Z]+$/);
    // 正規表現チェック
    if (item.value && !regex.test(item.value)) {
      const msgPamams = [item.key];
      // エラーメッセージを作成
      errMsgs.push(
        Message.generateMessage(Type.NOT_CONTAIN_SYMBOLS.message, msgPamams)
      );
    }
    return errMsgs;
  }

  /**
   * 範囲チェック
   * ※未使用のためコメントアウト
   * 以下を使用する場合は、item.valueとminとmaxがすべて数値である必要があるので、
   * 必要に応じて数値へ型変換して使うこと（※文字列のまま比較しないこと）
   * 参考：javascript 文字列のまま数字を比較すると危険が危ない。
   * https://chaika.hatenablog.com/entry/2016/02/01/153218
   * @typedef {object} NumberItem
   * @property {string} key 項目名
   * @property {number} value 値
   * @param {NumberItem} item チェックする項目
   * @param {number} min 最小値
   * @param {number} max 最大値
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  // static range(item, min, max){
  //   let errMsgs = [];
  //   // TODO: item.valueの数値チェック
  //   // 最小値 <= 入力値 <= 最大値 かどうか
  //   if(item.value && (!min <= item.value || !item.value <= max)){
  //     const msgPamams = [ item.key ];
  //     // エラーメッセージを作成
  //     errMsgs.push(
  //       Message.generateMessage(Type.RANGE.message, msgPamams)
  //     );
  //   }
  //   return errMsgs;
  // }

  /**
   * 桁数チェック：最大
   * @typedef {object} Item
   * @property {string} key 項目名
   * @property {(number|string)} value 値
   * @param {Item} item チェックする項目
   * @param {number} max 最大桁
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static digitsMax(item, max) {
    let errMsgs = [];
    // 入力値の桁数 > 最大桁 ではないかどうか
    if (item.value && String(item.value).length > max) {
      const msgPamams = [item.key, max];
      // エラーメッセージを作成
      errMsgs.push(Message.generateMessage(Type.DIGITS_MAX.message, msgPamams));
    }
    return errMsgs;
  }

  /**
   * 桁数チェック：最小
   * @typedef {object} Item
   * @property {string} key 項目名
   * @property {(number|string)} value 値
   * @param {Item} item チェックする項目
   * @param {number} min 最小桁
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static digitsMin(item, min) {
    let errMsgs = [];
    // 入力値の桁数 < 最小桁 ではないかどうか
    if (item.value && String(item.value).length < min) {
      const msgPamams = [item.key, min];
      // エラーメッセージを作成
      errMsgs.push(Message.generateMessage(Type.DIGITS_MIN.message, msgPamams));
    }
    return errMsgs;
  }

  /**
   * 桁数チェック：固定長
   * @typedef {object} Item
   * @property {string} key 項目名
   * @property {(number|string)} value 値
   * @param {Item} item チェックする項目
   * @param {number} length 固定長の桁数
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static fixedLength(item, length) {
    let errMsgs = [];
    const value = item.value.code ? item.value.code : item.value;
    // 入力値の桁数 = 固定長の桁数 かどうか
    if (value && String(value).length !== length) {
      const msgPamams = [item.key, length];
      // エラーメッセージを作成
      errMsgs.push(
        Message.generateMessage(Type.FIXED_LENGTH.message, msgPamams)
      );
    }
    return errMsgs;
  }

  /**
   * メールアドレスチェック：正規表現
   * @typedef {object} Item
   * @property {string} key 項目名
   * @property {(number|string)} value 値
   * @param {Item} item チェックする項目
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static isMailFormat(item) {
    let errMsgs = [];
    const regex = new RegExp(/^[a-zA-Z0-9_+-.]+@[a-zA-Z0-9-.]+$/);
    // 正規表現チェック
    if (item.value && !regex.test(item.value)) {
      const msgPamams = [item.key];
      // エラーメッセージを作成
      errMsgs.push(Message.generateMessage(Type.IS_MAIL.message, msgPamams));
    }
    return errMsgs;
  }

  /**
   * 郵便番号チェック：正規表現
   * @typedef {object} Item
   * @property {string} key 項目名
   * @property {string} value 値
   * @param {Item} item チェックする項目
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static isZipCdFormat(item) {
    let errMsgs = [];
    const regex = new RegExp(/^[0-9]{3}-[0-9]{4}$/);
    // 正規表現チェック
    if (item.value && !regex.test(item.value)) {
      const msgPamams = [item.key];
      // エラーメッセージを作成
      errMsgs.push(Message.generateMessage(Type.IS_ZIP_CD.message, msgPamams));
    }
    return errMsgs;
  }

  /**
   * 電話番号チェック：正規表現
   * @typedef {object} Item
   * @property {string} key 項目名
   * @property {string} value 値
   * @param {Item} item チェックする項目
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static isPhoneNumFormat(item) {
    let errMsgs = [];
    const regex = new RegExp(/^0[0-9]{1,4}-[0-9]{1,4}-[0-9]{4}$/);
    // 正規表現チェック
    if (item.value && !regex.test(item.value)) {
      const msgPamams = [item.key];
      // エラーメッセージを作成
      errMsgs.push(
        Message.generateMessage(Type.IS_PHONE_NUM.message, msgPamams)
      );
    }
    return errMsgs;
  }

  /**
   * パスワードチェック：正規表現
   * @typedef {object} Item
   * @property {string} key 項目名
   * @property {string} value 値
   * @param {Item} item チェックする項目
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static isPassword(item) {
    let errMsgs = [];
    const regex = new RegExp(/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9].+$/);
    // 正規表現チェック
    if (item.value && !regex.test(item.value)) {
      const msgPamams = [item.key];
      // エラーメッセージを作成
      errMsgs.push(
        Message.generateMessage(Type.IS_PASSWORD.message, msgPamams)
      );
    }
    return errMsgs;
  }

  /**
   * ファイルサイズチェック：最大(MB)
   * @typedef {object} FileItem
   * @property {string} key 項目名
   * @property {File} value 値
   * @param {FileItem} item チェックする項目
   * @param {number} maxMb 最大ファイルサイズ(MB)
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static fileSizeMax(item, maxMb) {
    let errMsgs = [];
    const sizeLimit = 1024 * 1024 * maxMb;
    // ファイルサイズが指定されたサイズ以下かどうか
    if (item.value && item.value.size && item.value.size > sizeLimit) {
      const msgPamams = [item.key, maxMb];
      // エラーメッセージを作成
      errMsgs.push(
        Message.generateMessage(Type.FILE_SIZE_MAX.message, msgPamams)
      );
    }
    return errMsgs;
  }

  /**
   * 画面別閲覧数一覧：日付相関チェック
   * @typedef {object} StringItem
   * @property {string} key 項目名
   * @property {string} value 値
   * @param {StringItem} startDateString 開始年月日(value：yyyy-mm-dd形式)
   * @param {StringItem} endDateString 終了年月日(value：yyyy-mm-dd形式)
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static isOkUsageStatsDate(startDateString, endDateString) {
    let errMsgs = [];
    // 日付文字列をDate型に変換
    const startDate = new Date(startDateString.value);
    const endDate = new Date(endDateString.value);
    const sysDate = this.getNowDate();
    if (startDate > endDate) {
      // 開始日 > 終了日 の場合はエラー
      errMsgs.push(Message.USAGE_STATS.STARTDATE_HAS_PASSED);
    }
    if (startDate < sysDate.setFullYear(sysDate.getFullYear() - 2)) {
      // 開始日 < システム日付 - 2年 の場合はエラー
      errMsgs.push(Message.USAGE_STATS.STARTDATE_IS_OLD);
    }
    if (startDate.setDate(startDate.getDate() + 14) <= endDate) {
      // 開始日 + 14日 <= 終了日 の場合はエラー
      errMsgs.push(Message.USAGE_STATS.ENDDATE_HAS_PASSED);
    }
    return errMsgs;
  }

  /**
   * ライセンス登録：日付相関チェック
   * @typedef {object} StringItem
   * @property {string} key 項目名
   * @property {string} value 値
   * @param {StringItem} startDateString 開始年月日(value：yyyy-mm-dd形式)
   * @param {StringItem} endDateString 終了年月日(value：yyyy-mm-dd形式)
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static isOkSchoolLicenseManageDate(startDateString, endDateString) {
    let errMsgs = [];
    // 日付文字列をDate型に変換
    const startDate = new Date(startDateString.value);
    const endDate = new Date(endDateString.value);
    const sysDate = this.getNowDate();
    if (startDate > endDate) {
      // 開始日 > 終了日 の場合はエラー
      errMsgs.push(Message.SCHOOL_LICENSES.STARTDATE_HAS_PASSED);
    }
    if (endDate < sysDate) {
      // 終了日が現在日時を過ぎていればエラー
      errMsgs.push(Message.SCHOOL_LICENSES.ENDDATE_EXPIRED);
    }
    return errMsgs;
  }

  /**
   * ライセンス登録：基準有効期間チェック
   * （ライセンスの有効期間が、基準有効期間から前後1年以内かどうかを確認）
   * @typedef {object} StringItem
   * @property {string} key 項目名
   * @property {string} value 値
   * @typedef {object} NumberItem
   * @property {string} key 項目名
   * @property {string} value 値
   * @param {StringItem} licenseCd ライセンスコード
   * @param {StringItem} startDateString 開始年月日(value：yyyy-mm-dd形式)
   * @param {StringItem} endDateString 終了年月日(value：yyyy-mm-dd形式)
   * @param {NumberItem} standardTerm 基準年度
   * @returns NGの場合：警告メッセージの配列, OKの場合：空配列
   */
  static isCheckSchoolLicenseManageDateSpan(
    licenseCd,
    startDateString,
    endDateString,
    standardTerm
  ) {
    let warnMsg = '';
    // 日付文字列をDate型に変換
    const startDate = new Date(startDateString.value);
    const endDate = new Date(endDateString.value);
    // 入力値の期間を日にちで算出
    const span = (endDate - startDate) / 86400000;
    // 基準有効期間の期間をから最短許容基準有効期間を日にちで算出
    const minSpan = (standardTerm.value - 1) * 365;
    // 基準有効期間の期間をから最長許容基準有効期間を日にちで算出
    const maxSpan = (standardTerm.value + 1) * 365;

    if (span < minSpan || maxSpan < span) {
      // 入力した期間が基準有効期間-1年より小さい、または+1年より大きい場合はエラー
      const msgPamams = [licenseCd.value, standardTerm.value];
      warnMsg = Message.generateMessage(Type.IS_IN_RANGE.message, msgPamams);
    }
    return warnMsg;
  }

  /**
   * ユーザ情報カード：ユーザ区分チェック
   * （学校管理者の場合はエラー）
   * @typedef {object} SelectItem
   * @property {string} key 項目名
   * @property {SelectItemValue} value 値
   * @typedef {object} SelectItemValue
   * @property {string} code コード
   * @property {item} name コードの論理名
   * @param {SelectItem} item チェックする項目({key: '項目名', value: {code: '値', name: 'codeの論理名'}})
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static isNonSelectableSchoolAdmin(item) {
    let errMsgs = [];
    if (item.value.code === UserAuth.SCHOOL_ADMIN.code) {
      const msgPamams = [item.key, UserAuth.SCHOOL_ADMIN.name];
      // エラーメッセージを作成
      errMsgs.push(Message.generateMessage(Message.NON_SELECTABLE, msgPamams));
    }
    return errMsgs;
  }

  /**
   * ユーザライセンス割り当て：ユーザ区分/学年の存在チェック
   * @typedef {Object} Item
   * @property {string} key 項目名
   * @property {ItemDetail} value 値
   * @typedef {Object} ItemValue
   * @property {string} value.authCode ユーザ区分
   * @property {string} value.grdCode 学年
   * @param {ItemValue} item ユーザ区分/学年
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static isAuthGrade(item) {
    let errMsgs = [];
    if (!item.value.authCode || !item.value.grdCode) {
      const msgPamams = [item.key];
      // エラーメッセージを作成
      errMsgs.push(
        Message.generateMessage(Type.REQUIRED_SELECT.message, msgPamams)
      );
    }
    return errMsgs;
  }

  /**
   * 現在日付(9:00)をDate型で取得
   * @returns Date型の現在日付(9:00)
   */
  static getNowDate() {
    // 現在日時の取得
    const now = new Date();
    // 年月日の型を作成
    let format = 'yyyy-MM-dd';
    // 作成した型に年月日をreplace
    format = format.replace(/yyyy/, now.getFullYear());
    format = format.replace(/MM/, ('0' + (now.getMonth() + 1)).slice(-2));
    format = format.replace(/dd/, ('0' + now.getDate()).slice(-2));
    return new Date(format);
  }

    /**
   * ライセンス登録：ライセンス数チェック
   * @typedef {object} StringItem
   * @property {string} key 項目名
   * @property {string} value 値
   * @param {number} usedCnt ライセンス使用数
   * @param {number} totalCnt ライセンス総数
   * @returns NGの場合：エラーメッセージの配列, OKの場合：空配列
   */
  static isOkSchoolLicenseManageCnt(usedCnt, totalCnt) {
    let errMsgs = [];
    if (totalCnt < usedCnt) {
      // ライセンス総数 < ライセンス使用数 の場合はエラー
      errMsgs.push(Message.SCHOOL_LICENSES.LICENSE_COUNT_OVER);
    }
    return errMsgs;
  }
}
