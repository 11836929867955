module.exports = {
  'login': {id: 'C0001', name: 'ログイン'},
  'password-change': {id: 'C0002', name: 'パスワード変更'},
  'bookshelf': {id: 'S0001', name: '本棚'},
  'usage-stats': {id: 'S0002', name: '画面別閲覧数一覧'},
  'usage-stats-detail': {id: 'S0003', name: 'ユーザ閲覧履歴'},
  'school-manage': {id: 'M0001', name: '学校一覧'},
  'school-manage-register': {id: 'M0002', name: '学校登録'},
  'school-manage-update': {id: 'M0003', name: '学校更新'},
  'license-manage-school': {id: 'M0004', name: 'ライセンス割り当て'},
  'user-manage': {id: 'M0005', name: 'ユーザ一覧'},
  'user-manage-register': {id: 'M0006', name: 'ユーザ登録'},
  'user-manage-update': {id: 'M0007', name: 'ユーザ更新'},
  'license-manage-user': {id: 'M0008', name: 'ユーザライセンス割り当て'},
  'book-manage': {id: 'M0009', name: '電子教材一覧'},
  'book-manage-register': {id: 'M0010', name: '電子教材登録'},
  'book-manage-update': {id: 'M0011', name: '電子教材更新'}
};