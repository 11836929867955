<template>
  <div class="max-w-2xl mx-auto lg:max-w-4xl md:max-w-4xl">
    <Title msg="パスワード変更" submsg="Password Change" />
    <div class="flex justify-center pb-10">
      <form @submit.prevent autocomplete="off" class="pt-7 w-full">
        <ErrMsgCard :errMsgs="errMsgs" process="パスワード変更" />
        <div class="flex justify-center">
          <div class="grid grid-cols-1 gap-5 w-full sm:w-2/3 md:w-2/5 lg:w-1/3">
            <div>
              <div class="flex items-center mb-1.5">
                <span class="text-gray-700">ログインID</span>
              </div>
              <input type="text" v-model="loginId" class="w-full" readonly />
            </div>

            <div>
              <div class="flex items-center mb-1.5">
                <span class="text-gray-700">{{ oldPassword.key }}</span>
                <span class="form-required">必須</span>
              </div>
              <input
                :type="getPassInputType(isOldPassVisible)"
                placeholder="現在のパスワードを入力"
                v-model="oldPassword.value"
                maxlength="16"
                class="w-full"
              />
              <span class="pass-mask-icon" v-if="oldPassword.value" @click="oldPassVisibleToggle">
                <font-awesome-icon v-if="isOldPassVisible" icon="eye-slash" size="lg" />
                <font-awesome-icon v-else icon="eye" size="lg" />
              </span>
            </div>

            <div>
              <div class="flex items-center mb-1.5">
                <span class="text-gray-700">{{ newPassword.key }}</span>
                <span class="form-required">必須</span>
              </div>
              <ul class="list-disc ml-5">
                <li v-for="(msg, index) in passwordRuleMsgs" :key="index" class="text-sm text-red-500">
                  {{ msg }}
                </li>
              </ul>
              <input
                :type="getPassInputType(isNewPassVisible)"
                placeholder="新しいパスワードを入力"
                v-model="newPassword.value"
                maxlength="16"
                class="w-full"
                autocomplete="new-password"
              />
              <span class="pass-mask-icon" v-if="newPassword.value" @click="newPassVisibleToggle">
                <font-awesome-icon v-if="isNewPassVisible" icon="eye-slash" size="lg" />
                <font-awesome-icon v-else icon="eye" size="lg" />
              </span>
            </div>

            <div>
              <div class="flex items-center mb-1.5">
                <span class="text-gray-700">{{ newPasswordConfirm.key }}</span>
                <span class="form-required">必須</span>
              </div>
              <ul class="list-disc ml-5">
                <li v-for="(msg, index) in passwordRuleMsgs" :key="index" class="text-sm text-red-500">
                  {{ msg }}
                </li>
              </ul>
              <input
                :type="getPassInputType(isNewPassConfirmVisible)"
                placeholder="再度新しいパスワードを入力"
                v-model="newPasswordConfirm.value"
                maxlength="16"
                class="w-full"
                autocomplete="new-password"
              />
              <span class="pass-mask-icon" v-if="newPasswordConfirm.value" @click="newPassConfirmVisibleToggle">
                <font-awesome-icon v-if="isNewPassConfirmVisible" icon="eye-slash" size="lg" />
                <font-awesome-icon v-else icon="eye" size="lg" />
              </span>
            </div>
          </div>
        </div>

        <div class="flex justify-center mt-12">
          <div>
            <button
              @click="$router.go(-1)"
              type="button"
              name="back-button"
              class="btn btn-gray w-full mr-5 md:mr-10"
            >
              戻る
            </button>
          </div>
          <div>
            <button
              v-on:click="update"
              type="button"
              name="button"
              class="btn btn-indigo w-full"
            >
              更新
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { updatePassword } from '../api';
import { rsaEncrypto } from '../crypto';
import Title from '../components/TheTitle.vue';
import ErrMsgCard from '../components/ErrorMssageCard.vue';
import UserAuth from '../const/userAuthority';
import Validation from '../utils/validation';
import Message from '../const/message';
import Authorize from '../utils/authorize';

export default {
  name: 'PasswordChange',
  components: {
    Title,
    ErrMsgCard,
  },
  data() {
    return {
      loginId: this.$store.state.loginId,
      oldPassword: { key: '現在のパスワード', value: '' },
      newPassword: { key: '新しいパスワード', value: '' },
      newPasswordConfirm: { key: '新しいパスワード（確認）', value: '' },
      isOldPassVisible: false,
      isNewPassVisible: false,
      isNewPassConfirmVisible: false,
      passwordRuleMsgs: Message.UPDATE_PASSWORD.RULE,

      errMsgs: [],
      isUnsent: true
    };
  },
  created: function(){
    // 権限チェック
    if (this.$store.state.authority === UserAuth.STUDENT.code
      || this.$store.state.isSsoLogin) {
      // エラーページに遷移
      this.$router.push({
        name: 'not-found',
        params: { catchAll: 'not-found' },
      });
      // 処理強制終了
      return false;
    }
  },
  methods: {
    async update(){
      // パスワードをマスキング
      this.isOldPassVisible = false;
      this.isNewPassVisible = false;
      this.isNewPassConfirmVisible = false;
      // バリデーション定義作成
      let validationArray = [];
      // バリデーションチェック実施
      this.errMsgs = validationArray.concat(
        Validation.requiredInput(this.oldPassword),
        Validation.isNotContainSymbols(this.oldPassword),
        Validation.digitsMin(this.oldPassword, 8),
        Validation.requiredInput(this.newPassword),
        Validation.isPassword(this.newPassword),
        Validation.digitsMin(this.newPassword, 8),
        Validation.digitsMax(this.newPassword, 16),
        Validation.requiredInput(this.newPasswordConfirm),
        Validation.isPassword(this.newPasswordConfirm),
        Validation.digitsMin(this.newPasswordConfirm, 8),
        Validation.digitsMax(this.newPasswordConfirm, 16),
      );

      // エラーメッセージがある場合は処理を中断
      if (this.errMsgs.length) return false;
      
      // パスワードの暗号化
      let encryptoOldPass = rsaEncrypto(this.oldPassword.value);
      let encryptoNewPass = rsaEncrypto(this.newPassword.value);
      let encryptoNewConfirmPass = rsaEncrypto(this.newPasswordConfirm.value);

      if (Date.now() / 1000 > this.$store.state.tokenExp){
        if (!await Authorize.isAuth()) {
          // 認証失敗：ログイン画面へ遷移
          this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
          return false;
        }
      }

      let self = this;
      updatePassword( function(rspBody){
        if (rspBody.result.code === 0) {
          self.isUnsent = false;
          self.$store.commit('setIsNotChangedPassword', false);
          // 成功した場合、リダイレクトURLの有無に遷移先を制御
          if (self.$route.query.redirect) {
            // リダイレクトURLがある場合は当該画面へ遷移
            self.$store.commit('setOkAction', function(){
              self.$router.push(
                self.$route.query.redirect
              );
            });
            
          } else {
            // リダイレクトURLがない場合はルートページへ遷移
            self.$store.commit('setOkAction', function(){
              self.$router.push({ name: 'routing' });
            });
          }
          // 更新成功ポップアップ表示
          self.$store.commit(
            'setMessages',
            [
              Message.generateMessage(Message.COMPLETED, [Message.PROC_TYPE.UPDATE]),
            ]
          );
          self.$store.commit('setIsModalInfo', true);
          self.$store.commit('setCancelBtnHide', true);
          self.$store.commit('setIsModalOpen', true);
        } else {
          // 失敗した場合、エラー内容を表示
          self.errMsgs = rspBody.result.messages;
        }
      }, this.loginId, encryptoOldPass, encryptoNewPass, encryptoNewConfirmPass, this.$route.path );
    },
    oldPassVisibleToggle() {
      this.isOldPassVisible = !this.isOldPassVisible;
    },
    newPassVisibleToggle() {
      this.isNewPassVisible = !this.isNewPassVisible;
    },
    newPassConfirmVisibleToggle() {
      this.isNewPassConfirmVisible = !this.isNewPassConfirmVisible;
    },
    getPassInputType (isPassVisible) {
      return isPassVisible ? 'text' : 'password';
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isUnsent && to.name != 'maintenance') {
      this.$store.commit('setMessages', [Message.INPUT_VALUE_CLEAR]);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setIsModalOpen', true);
      this.$store.commit('setOkAction', function () {
        next();
      });
      this.$store.commit('setCancelAction', function () {
        next(false);
      });
    } else {
      next();
    }
  },
};
</script>

<style scoped>
input {
  @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50 focus:bg-white bg-gray-50;
}

input:read-only {
  @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-200;
}
</style>
