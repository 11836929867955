<template>
  <div class="static max-w-5xl mx-auto">
    <Title msg="ユーザ一覧" submsg="User List" />
    <!-- CSV取り込み中にボタン操作させないためのレイヤー -->
    <div
      v-if="isCsvImporting === true"
      class="
        z-20
        absolute
        bg-gray-900 bg-opacity-70
        top-0
        left-0
        w-full
        h-full
        positionCenter
      "
    >
      <div class="modal-content">
        <div class="py-8 px-2 heightset">
          <div class="mb-2 text-center text-md font-bold whitespace-pre-wrap">
            <p>CSV取込データ反映中のため操作できません。</p>
            <p class="mt-2">30秒経過後、再読み込みボタンを押してください。</p>
            <p class="mt-2">※100件取込時の待ち時間：1分程度</p>
          </div>
          <div class="pt-4 modal-footer flex justify-center">
            <button @click="getUserCsvStatus(csvErrorSchoolCd)" name="reroad" class="btn btn-indigo mx-3">
              再読み込み
            </button>
            <button 
              v-if="isSysAdmin"
              @click="
                resetSchoolCd = '';
                csvErrorSchoolCd = '';
                isCsvImporting = false;
              "
              name="anothorSchool"
              class="btn btn-gray mx-3"
            >
              別の学校を選択
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col my-1">
      <!-- 教職員の場合は各種ボタン非表示 -->
      <div class="flex flex-col md:flex-row my-2" v-if="!isTeacher">
        <div class="flex flex-row">
          <div>
            <router-link
              :to="{
                name: 'user-manage-register',
                params: { schoolCd: $store.state.schoolCd },
              }"
              id="user-register"
              class="mr-3"
            >
              <button class="btn btn-indigo">新規登録</button>
            </router-link>
          </div>
          <button @click="toUserLicense" class="btn-long btn-indigo mr-3">
            <font-awesome-icon icon="id-card" size="lg" />
            <span class="mx-2">
              <font-awesome-icon icon="caret-right" size="lg" />
            </span>
            <font-awesome-icon icon="user-friends" size="lg" class="mr-2" />
            ユーザライセンス割り当て
          </button>
        </div>
        <div class="flex mt-3 md:mt-0">
          <div class="flex mr-3">
            <label class="btn-csv btn-indigo h-9">
              CSV取込
              <input
                ref="csvUploader"
                @change="uploadCsv"
                name="uploadCsv"
                type="file"
                accept=".csv"
              />
            </label>
          </div>

          <div v-if="isCsvImportError && csvErrorSchoolCd" class="flex mr-3">
            <button
              @click="downloadErrorCsv"
              class="btn-err-csv bg-red-700 text-white focus:ring-red-200"
            >
              エラーCSV出力
            </button>
            <button
              @click="deleteErrCsv"
              class="btn-err-csv-delete border-l-2"
            >
              <font-awesome-icon icon="trash-alt" size="lg" />
            </button>
          </div>
          <div v-if="isCsvImportFailed && csvErrorSchoolCd" class="flex mr-3">
            <button
              @click="
                $store.commit('setMessages', [
                  'CSV取込にて取り込んだファイルの形式が不正です。',
                  'カンマ区切りのCSV形式で再取込をお願いします。',
                ]);
                $store.commit('setCancelBtnHide', true);
                $store.commit('setIsModalOpen', true);
              "
              class="btn-err-csv bg-red-700 text-white focus:ring-red-200"
            >
              エラー内容表示
            </button>
            <button
              @click="deleteErrCsv"
              class="btn-err-csv-delete border-l-2"
            >
              <font-awesome-icon icon="trash-alt" size="lg" />
            </button>
          </div>
          <div class="flex items-center h-8">
            <a 
              class="text-indigo-800 underline text-sm"
              :href="'＋まなび_ユーザCSVテンプレート.csv'"
              download
            >
              CSV取込テンプレート
            </a>
          </div>
        </div>
      </div>
    </div>

    <Form
      ref="form"
      :handleSearch="search"
      :handleDownloadCsv="downloadCsv"
      :handleDownloadUsersCard="formDownloadUsersCard"
      :handleGetFileStatus="getUserCsvStatus"
      :schoolList="allSchools"
      :isSysAdmin="isSysAdmin"
      :isSchoolAdmin="isSchoolAdmin"
      :resetSchoolCd="resetSchoolCd"
    />

    <!-- 教職員の場合は各種ボタン非表示 -->
    <div v-if="!isTeacher" class="mt-4">
      <button
        @click="batchDelete"
        v-tooltip="{content: 'チェックボックスを選択して500件まで一括削除'}"
        class="w-32 text-sm font-semibold text-white bg-gray-500 border border-gray-300 shadow py-1.5 px-5 rounded"
      >
        <font-awesome-icon icon="trash-alt" class="mr-1" />
        一括削除
      </button>
      <button
        v-if="isSchoolAdmin"
        @click="tableDownloadUsersCard"
        v-tooltip="{content: 'チェックボックスを選択して10件までユーザ情報カード出力'}"
        class="ml-3 text-sm font-semibold text-white bg-gray-500 border border-gray-300 shadow py-1.5 px-5 rounded"
      >
        <font-awesome-icon icon="id-card" class="mr-1" />
        カード出力
      </button>
    </div>
    <Table
      :searchedUsers="searchedUsers"
      :subjects="subjects"
      :schoolList="allSchools"
      :isSysAdmin="isSysAdmin"
      :isSchoolAdmin="isSchoolAdmin"
      @checked="getCheckedLoginIds"
    />
  </div>
</template>

<script>
import {
  searchSchools,
  searchUsers,
  deleteUsers,
  downloadUsersCard,
  downloadUsersCsv,
  downloadErrorCsv,
  uploadUsersCsv,
  findUsersCsvStatus,
  deleteUsersErrorCsv
} from '../api';
import UserAuth from '../const/userAuthority';
import Title from '../components/TheTitle.vue';
import Form from '../components/UserListForm.vue';
import Table from '../components/UserListTable.vue';
import DownloadFile from '../utils/downloadFile';
import Message from '../const/message';
import FileStatus from '../const/fileStatus';
import Authorize from '../utils/authorize';

export default {
  name: 'UserList',
  components: {
    Title,
    Form,
    Table,
  },
  data() {
    return {
      searchedUsers: [],
      subjects: [],
      allSchools: [],
      schoolCd: '',
      resetSchoolCd: '',
      baseYear: '',
      checkedLoginIds: [],
      isSysAdmin: this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code,
      isSchoolAdmin: this.$store.state.authority === UserAuth.SCHOOL_ADMIN.code,
      isTeacher: this.$store.state.authority === UserAuth.TEACHER.code,
      isCsvImporting: false,
      isCsvImportError: false,
      isCsvImportFailed: false,
      csvErrorSchoolCd: '',

      errMsgs: [],
    };
  },
  created: async function () {
    // 権限チェック
    const userSchoolCd = this.$store.state.schoolCd;
    const pathSchoolCd = this.$route.params.schoolCd;
    if (
      ((this.isTeacher || this.isSchoolAdmin) && userSchoolCd === pathSchoolCd) ||
      this.isSysAdmin
    ) {
      if (this.isSysAdmin) {
        // システム管理者の場合、学校コードを全件取得
        const rspBody = await searchSchools(this.$route.path);
        this.allSchools = rspBody.detail;
        this.resetSchoolCd = pathSchoolCd;
      }
      if (!this.isTeacher) {
        // 教職員以外の場合、パスパラメータの学校のCSVの取込状況を取得
        await this.getUserCsvStatus(pathSchoolCd);
      }
    } else {
      // エラーページに遷移
      this.$router.push({
        name: 'not-found',
        params: { catchAll: 'not-found' },
      });
      // 処理強制終了
      return false;
    }
  },
  methods: {
    async search(schoolCd, baseYear, authority, grade, loginId) {
      if (!this.isSysAdmin) {
        // システム管理者でない場合検索条件の学校コードに自身の所属する学校をセット
        schoolCd = this.$store.state.schoolCd;
      }
      if (schoolCd) {
        if (Date.now() / 1000 > this.$store.state.tokenExp){
          if (!await Authorize.isAuth()) {
            // 認証失敗：ログイン画面へ遷移
            this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
            return false;
          }
        }
        // 教職員以上の権限を検索時、入力されている基準年度、学年を無視する
        if (authority === '2' | authority === '3' | authority === '9') {
          baseYear = null;
          grade = null;
        }

        let self = this;
        // ユーザ検索API呼び出し
        searchUsers(
          function (rspBody) {
            if (rspBody.result.messages.length) {
              self.searchedUsers = [];

              // 失敗した場合、エラーメッセージをポップアップにセットして表示
              self.$store.commit('setMessages', rspBody.result.messages);
              self.$store.commit('setCancelBtnHide', true);
              self.$store.commit('setIsModalOpen', true);
            } else {
              self.searchedUsers = rspBody.detail.users;
              self.subjects = rspBody.detail.subjects;
            }
          },
          schoolCd,
          baseYear,
          authority,
          grade,
          loginId,
          this.$route.path
        );
      } else {
        // 学校コードが未選択の場合はエラーメッセージを出力
        this.isNotSchoolCdSelected();
      }
    },
    batchDelete() {
      if (!this.isSysAdmin) {
        // システム管理者でない場合
        // 入力フォームの学校コードを自身の学校で上書き
        this.schoolCd = this.$store.state.schoolCd;
      }
      let self = this;
      const deleteCnt = self.checkedLoginIds.length;
      if (deleteCnt === 0) {
        // 削除対象が存在しない場合はエラーメッセージを表示
        self.$store.commit('setMessages', [
          Message.generateMessage(Message.DELETE_NOT_SELECT, [
            Message.PROC_TYPE.DELETE,
            Message.USER.NAME,
          ]),
        ]);
        self.$store.commit('setCancelBtnHide', true);
        self.$store.commit('setIsModalOpen', true);
        return false;
      }

      if (deleteCnt > Message.USER.DELETE_LIMIT) {
        // 削除対象が上限より多い場合はエラーメッセージを表示
        self.$store.commit('setMessages', [
          Message.generateMessage(Message.LIMIT_OVER, [
            Message.PROC_TYPE.DELETE,
            Message.USER.DELETE_LIMIT,
          ]),
        ]);
        self.$store.commit('setCancelBtnHide', true);
        self.$store.commit('setIsModalOpen', true);
        return false;
      }

      // 削除するログインIDの配列をさらに4件ごとに配列に格納
      let deleteIdsSets = [];

      for (let i = 0; i < deleteCnt; i = i + 4) {
        const sliceLength = i + 4 <= deleteCnt ? i + 4 : deleteCnt;
        deleteIdsSets.push(self.checkedLoginIds.slice(i, sliceLength));
      }
      // 4件ごとに配列に格納されたログインIDを、それぞれカンマ区切りで文字列結合
      let checkedLoginIdsStringSets = [];
      deleteIdsSets.forEach((deleteIdsSet) => {
        checkedLoginIdsStringSets.push(deleteIdsSet.join(', '));
      });
      // 警告メッセージの初期化
      let warnMsgs = [];
      warnMsgs.push(
        Message.generateMessage(Message.DELETE_WARN, [
          deleteCnt,
          Message.PROC_TYPE.DELETE,
        ])
      );
      // 警告メッセージに追加
      warnMsgs = warnMsgs.concat(checkedLoginIdsStringSets);

      // 削除確認ポップアップを表示する
      self.$store.commit('setMessages', warnMsgs);
      self.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      self.$store.commit('setIsModalOpen', true);
      self.$store.commit('setOkAction', async function () {
        if (Date.now() / 1000 > self.$store.state.tokenExp){
          if (!await Authorize.isAuth()) {
            // 認証失敗：ログイン画面へ遷移
            self.$router.push({ name: 'login', query: { redirect: self.$route.path } });
            return false;
          }
        }
        deleteUsers(
          function (rspBody) {
            if (rspBody.result.code === 0) {
              // 成功した場合、削除完了メッセージをポップアップにセット
              self.$store.commit('setMessages', [
                Message.generateMessage(Message.COMPLETED, [
                  Message.PROC_TYPE.DELETE,
                ]),
              ]);
              self.$store.commit('setIsModalInfo', true);
            } else {
              // 失敗した場合、エラーメッセージをポップアップにセット
              self.$store.commit('setMessages', rspBody.result.messages);
            }
            // OK押下後、フォームから検索条件を取得後、フォーム内で検索API呼び出し
            self.$store.commit('setOkAction', function () {
              self.$refs.form.search(false);
            });
            // ポップアップを表示
            self.$store.commit('setCancelBtnHide', true);
            self.$store.commit('setIsModalOpen', true);
          },
          self.schoolCd,
          self.checkedLoginIds,
          self.$route.path
        );
      });
    },
    /** CSVの取込状況を取得 */
    async getUserCsvStatus(schoolCd){
      // 学校コードが空の場合は取得しない
      if (!schoolCd) return false;

      if (Date.now() / 1000 > this.$store.state.tokenExp){
        if (!await Authorize.isAuth()) {
          // 認証失敗：ログイン画面へ遷移
          this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
          return false;
        }
      }

      this.csvErrorSchoolCd = '';
      this.isCsvImportError = false;
      this.isCsvImportFailed = false;
      // CSV取込状況取得API
      const rspBody = await findUsersCsvStatus(schoolCd, this.$route.path);
      if (!rspBody.detail) {
        // detailがない場合、取込中フラグをOFF
        this.isCsvImporting = false;
        // 検索条件をクリアし、再検索実行
        this.$refs.form.clearOutsideSchool();
        this.$refs.form.search(false);
      } else if (rspBody.detail.fileStatus === FileStatus.IMPORT_WAIT.code) {
        // 取込中の場合、取込中フラグをON
        this.csvErrorSchoolCd = schoolCd;
        this.isCsvImporting = true;
      } else if (rspBody.detail.fileStatus === FileStatus.IMPORT_ERROR.code) {
        // 取込エラーの場合、取込エラーフラグをON、取込中フラグをOFF
        this.csvErrorSchoolCd = schoolCd;
        this.isCsvImporting = false;
        this.isCsvImportError = true;
        // ポップアップを表示
        this.$store.commit('setMessages', [
          'CSV取込にて取り込んだファイルにエラーが発生しています。',
          'エラーファイルをダウンロードして内容を確認してください。',
          '該当のユーザを修正後CSV再取込、または画面からの登録をお願いします。',
        ]);
        this.$store.commit('setCancelBtnHide', true);
        this.$store.commit('setIsModalOpen', true);
      } else if (rspBody.detail.fileStatus === FileStatus.IMPORT_FAILED.code) {
        // 取込失敗の場合、取込失敗フラグをONと取込中フラグをOFF
        this.csvErrorSchoolCd = schoolCd;
        this.isCsvImporting = false;
        this.isCsvImportFailed = true;
        // ポップアップを表示
        this.$store.commit('setMessages', [
          'CSV取込にて取り込んだファイルの形式が不正です。',
          'カンマ区切りのCSV形式で再取込をお願いします。',
        ]);
        this.$store.commit('setCancelBtnHide', true);
        this.$store.commit('setIsModalOpen', true);
      }
    },
    getCheckedLoginIds(array) {
      this.checkedLoginIds = array;
    },
    toUserLicense() {
      if (!this.isSysAdmin) {
        // システム管理者でない場合
        // 入力フォームの学校コードを自身の学校で上書き
        this.schoolCd = this.$store.state.schoolCd;
      }
      if (this.schoolCd) {
        this.$store.commit('clearSearchUserLicenseKeys');
        // パスパラメータに学校コードを設定
        this.$router.push({
          name: 'license-manage-user',
          params: { schoolCd: this.schoolCd },
        });
      } else {
        // 学校コードが未選択の場合はエラーメッセージを出力
        this.isNotSchoolCdSelected();
      }
    },
    async formDownloadUsersCard(baseYear, authority, grade, loginId) {
      if (Date.now() / 1000 > this.$store.state.tokenExp){
        if (!await Authorize.isAuth()) {
          // 認証失敗：ログイン画面へ遷移
          this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
          return false;
        }
      }
      const loginIds = loginId ? [loginId] : [];
      // ユーザ情報カードダウンロードAPI呼び出し
      const rspBody = await downloadUsersCard(
        this.$store.state.schoolCd, this.$route.path, true, loginIds, baseYear, authority, grade
      );
      if (rspBody.result.code === 0 && rspBody.detail.signedUrl) {
        // ファイル出力
        DownloadFile.downloadUrl(rspBody.detail.signedUrl, DownloadFile.USER_CARD);
      } else {
        // 失敗した場合、エラーメッセージをポップアップにセット
        this.$store.commit('setMessages', rspBody.result.messages);
        // ポップアップを表示
        this.$store.commit('setCancelBtnHide', true);
        this.$store.commit('setIsModalOpen', true);
      }
    },
    async tableDownloadUsersCard() {
      const checkedCnt = this.checkedLoginIds.length;
      if (checkedCnt === 0) {
        // 出力対象が存在しない場合はエラーメッセージを表示
        this.$store.commit('setMessages', [
          Message.generateMessage(Message.DELETE_NOT_SELECT, [
            Message.USER.DOWNLOAD_USERS_CARD.NAME,
            Message.USER.NAME,
          ]),
        ]);
        this.$store.commit('setCancelBtnHide', true);
        this.$store.commit('setIsModalOpen', true);
        return false;
      }

      if (checkedCnt > Message.USER.DOWNLOAD_USERS_CARD.LIMIT) {
        // 出力対象が上限より多い場合はエラーメッセージを表示
        this.$store.commit('setMessages', [
          Message.generateMessage(Message.LIMIT_OVER, [
            Message.USER.DOWNLOAD_USERS_CARD.NAME,
            Message.USER.DOWNLOAD_USERS_CARD.LIMIT,
          ]),
        ]);
        this.$store.commit('setCancelBtnHide', true);
        this.$store.commit('setIsModalOpen', true);
        return false;
      }
      if (Date.now() / 1000 > this.$store.state.tokenExp){
        if (!await Authorize.isAuth()) {
          // 認証失敗：ログイン画面へ遷移
          this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
          return false;
        }
      }
      // ユーザ情報カードダウンロードAPI呼び出し
      const rspBody = await downloadUsersCard(
        this.$store.state.schoolCd,
        this.$route.path,
        false,
        this.checkedLoginIds
      );
      if (rspBody.result.code === 0 && rspBody.detail.signedUrl) {
        // ファイル出力
        DownloadFile.downloadUrl(rspBody.detail.signedUrl, DownloadFile.USER_CARD);
      } else {
        // 失敗した場合、エラーメッセージをポップアップにセット
        this.$store.commit('setMessages', rspBody.result.messages);
        // ポップアップを表示
        this.$store.commit('setCancelBtnHide', true);
        this.$store.commit('setIsModalOpen', true);
      }
    },
    async downloadCsv(schoolCd, baseYear, authority, grade, loginId) {
      if (!this.isSysAdmin) {
        // システム管理者でない場合
        // 入力フォームの学校コードを自身の学校で上書き
        schoolCd = this.$store.state.schoolCd;
      }
      if (schoolCd) {
        if (Date.now() / 1000 > this.$store.state.tokenExp){
          if (!await Authorize.isAuth()) {
            // 認証失敗：ログイン画面へ遷移
            this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
            return false;
          }
        }
        // ユーザCSVダウンロードAPI呼び出し
        downloadUsersCsv(
          function (rsp) {
            // ファイル出力
            DownloadFile.download(rsp, DownloadFile.USER_CSV);
          },
          schoolCd,
          baseYear,
          authority,
          grade,
          loginId,
          this.$route.path
        );
      } else {
        // 学校コードが未選択の場合はエラーメッセージを出力
        this.isNotSchoolCdSelected();
      }
    },
    async downloadErrorCsv() {
      if (Date.now() / 1000 > this.$store.state.tokenExp){
        if (!await Authorize.isAuth()) {
          // 認証失敗：ログイン画面へ遷移
          this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
          return false;
        }
      }
      // エラーCSVダウンロードAPI呼び出し
      downloadErrorCsv(function (rspBody) {
        // ファイル出力
        DownloadFile.downloadUrl(rspBody.detail.signedUrl, DownloadFile.ERROR_CSV);
      }, this.csvErrorSchoolCd, this.$route.path);
    },
    async uploadCsv(e) {
      if (!this.isSysAdmin) {
        // システム管理者でない場合
        // 入力フォームの学校コードを自身の学校で上書き
        this.schoolCd = this.$store.state.schoolCd;
      }
      if (this.schoolCd) {
        if (Date.now() / 1000 > this.$store.state.tokenExp){
          if (!await Authorize.isAuth()) {
            // 認証失敗：ログイン画面へ遷移
            this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
            return false;
          }
        }
        this.isCsvImportError = false;
        const file = e.target.files[0];
        let self = this;
        // ユーザCSVアップロードAPI呼び出し
        uploadUsersCsv(
          function (rspBody) {
            if (rspBody.result.code === 0) {
              // 成功した場合、完了メッセージをポップアップにセット
              self.$store.commit('setMessages', [
                'CSVアップロードが完了しました。反映まで少し時間がかかる場合がございます。',
                'その間以下の機能は一時利用できませんのでご了承ください。',
                '・ユーザ一覧画面の各種操作',
                '・ユーザライセンス割り当ての各種操作',
              ]);
              self.$store.commit('setOkAction', function () {
                if (self.schoolCd === self.$route.params.schoolCd){
                  // パスパラメータとCSV取込の学校が同じ場合は画面をリロード
                  location.reload();
                } else {
                  // それ以外の場合はCSV取込をした学校のユーザ一覧へ遷移
                  self.$router.push({
                    name: 'user-manage',
                    params: { schoolCd: self.schoolCd },
                  });
                }
              });
              self.$store.commit('setIsModalInfo', true);
            } else {
              // 失敗した場合、エラーメッセージをポップアップにセット
              self.$store.commit('setMessages', rspBody.result.messages);
            }
            self.$store.commit('setCancelBtnHide', true);
            self.$store.commit('setIsModalOpen', true);
          },
          this.schoolCd,
          file,
          this.$route.path
        );
      } else {
        // 学校コードが未選択の場合はエラーメッセージを出力
        this.isNotSchoolCdSelected();
      }
      // ファイル情報を初期化
      this.$refs.csvUploader.value = '';
    },
    /** エラーCSV削除 */
    deleteErrCsv() {
      const self = this;
      // 削除確認ポップアップを表示
      this.$store.commit('setMessages', [
        Message.generateMessage(Message.CONFIRM, [Message.PROC_TYPE.DELETE]),
      ]);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setIsModalOpen', true);
      this.$store.commit('setOkAction', async function () {
        if (Date.now() / 1000 > self.$store.state.tokenExp){
          if (!await Authorize.isAuth()) {
            // 認証失敗：ログイン画面へ遷移
            self.$router.push({ name: 'login', query: { redirect: self.$route.path } });
            return false;
          }
        }
        const rspBody = await deleteUsersErrorCsv(self.csvErrorSchoolCd, self.$route.path);

        if (rspBody.result.code === 0) {
          // 成功した場合、削除完了メッセージをポップアップにセット
          self.$store.commit('setMessages', [
            Message.generateMessage(Message.COMPLETED, [
              Message.PROC_TYPE.DELETE,
            ]),
          ]);
          self.$store.commit('setIsModalInfo', true);
        } else {
          // 失敗した場合、エラーメッセージをポップアップにセット
          self.$store.commit('setMessages', rspBody.result.messages);
        }
        // CSVの取込状況を再取得
        self.$store.commit('setOkAction', function () { self.getUserCsvStatus(self.csvErrorSchoolCd); });
        // ポップアップを表示
        self.$store.commit('setCancelBtnHide', true);
        self.$store.commit('setIsModalOpen', true);
      });
    },
    isNotSchoolCdSelected() {
      if (!this.schoolCd) {
        // 学校コードが未選択の場合はエラーメッセージを出力
        this.$store.commit('setMessages', [
          '学校コード/学校名 が未選択です。選択してください。',
        ]);
        this.$store.commit('setCancelBtnHide', true);
        this.$store.commit('setIsModalOpen', true);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.checkedLoginIds.length && to.name != 'maintenance') {
      this.$store.commit('setMessages', [Message.TRANSITION_WARN]);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setIsModalOpen', true);
      this.$store.commit('setOkAction', function () {
        next();
      });
    } else {
      next();
    }
  },
};
</script>
<style scoped>
input[type='file'] {
  @apply hidden;
}
.btn-csv {
  @apply font-semibold w-32 rounded py-2 px-6 text-center text-sm focus:outline-none focus:ring focus:ring-opacity-50;
  cursor: pointer;
}
.btn-long {
  @apply font-semibold rounded py-2 px-2 text-sm focus:outline-none focus:ring focus:ring-opacity-50;
  width: 276px;
}
.btn-err-csv {
  @apply font-semibold hover:bg-red-800 rounded-l w-32 py-2 px-2 text-sm focus:outline-none focus:ring focus:ring-opacity-50;
}
.btn-err-csv-delete {
  @apply text-white bg-red-400 hover:bg-red-500 font-semibold w-12 rounded-r py-2 px-4 text-sm focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-red-200;
}
.positionCenter {
  /* 画面の中央に要素を表示させる設定 */
  display: flex;
  align-items: center;
  justify-content: center;
}
.heightset {
  @apply overflow-y-auto;
  max-height: calc(100vh - 100px);
}
</style>
