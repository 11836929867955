import Http from '../axios';
import Message from '../const/message';

const headers = {
  'Content-Type': 'application/json;charset=utf-8',
};

const instance = new Http(process.env.VUE_APP_SERVER_HOST, headers);

/**
 * 学校削除
 * @param {Function} callback コールバック関数
 * @param {string} schoolCds 学校コードリスト
 * @param {string} fromPath 呼び出し元のパス
 */
export const deleteSchools = async function (callback, schoolCds, fromPath) {
  const params = {
    schoolCds: schoolCds,
  };
  const response = await instance.delete('/schools', params, fromPath);
  callback(response.body);
};

/**
 * 電子教材削除
 * @param {Function} callback コールバック関数
 * @param {string} licenseCds ライセンスコードリスト
 * @param {string} fromPath 呼び出し元のパス
 */
export const deleteTextbooks = async function (callback, licenseCds, fromPath) {
  const params = {
    licenseCds: licenseCds,
  };
  const response = await instance.delete('/textbooks', params, fromPath);
  callback(response.body);
};

/**
 * ユーザ削除
 * @param {Function} callback コールバック関数
 * @param {string} schoolCd ユーザの所属する学校コード
 * @param {string} loginIds ログインIDリスト
 * @param {string} fromPath 呼び出し元のパス
 */
export const deleteUsers = async function (
  callback,
  schoolCd,
  loginIds,
  fromPath
) {
  const params = {
    loginIds: loginIds,
  };
  const response = await instance.delete(
    '/schools/' + schoolCd + '/users',
    params,
    fromPath
  );
  callback(response.body);
};

/**
 * エラーCSV削除
 * @param {string} schoolCd エラーCSV削除対象の学校コード
 * @param {string} fromPath 呼び出し元のパス
 */
export const deleteUsersErrorCsv = async function (schoolCd, fromPath) {
  const params = {};
  const response = await instance.delete(
    '/schools/' + schoolCd + '/users/error-csv',
    params,
    fromPath
  );
  return response.body;
};

/**
 * 証明書ダウンロード
 * @param {Function} callback コールバック関数
 * @param {string} schoolCd 学校コード
 * @param {string} fromPath 呼び出し元のパス
 */
export const downloadLicense = async function (callback, schoolCd, fromPath) {
  const params = {};
  const response = await instance.get(
    '/schools/' + schoolCd + '/license/download',
    params,
    fromPath,
    true
  );
  callback(response);
};

/**
 * 学校CSVダウンロード
 * @param {Function} callback コールバック関数
 * @param {string} fromPath 呼び出し元のパス
 */
export const downloadSchoolsCsv = async function (callback, fromPath) {
  const params = {};
  const response = await instance.get(
    '/schools/csv/download',
    params,
    fromPath,
    true
  );
  callback(response);
};

/**
 * ユーザ情報カードダウンロード（S3の署名付きURLを取得）
 * @param {string} schoolCd 学校コード
 * @param {string} fromPath 呼び出し元のパス
 * @param {boolean} isCondition 検索条件をもとに検索するか
 * @param {array} loginIds ログインIDリスト
 * @param {string} baseYear 基準年度
 * @param {string} authority ユーザ区分
 * @param {string} grade 学年
 */
export const downloadUsersCard = async function (
  schoolCd,
  fromPath,
  isCondition = true,
  loginIds = [],
  baseYear = '0',
  authority = '',
  grade = '0',
) {
  const params = {
    isCondition: isCondition ? 1 : 0, 
    loginIds: JSON.stringify(loginIds),
    baseYear: baseYear,
    authority: authority,
    grade: grade,
  };
  const response = await instance.get(
    '/schools/' + schoolCd + '/users/card/download',
    params,
    fromPath,
    true
  );
  return response.body;
};

/**
 * ユーザCSVダウンロード
 * @param {Function} callback コールバック関数
 * @param {string} schoolCd 学校コード
 * @param {string} baseYear 基準年度
 * @param {string} authority ユーザ区分
 * @param {string} grade 学年
 * @param {string} loginId ログインID
 * @param {string} fromPath 呼び出し元のパス
 */
export const downloadUsersCsv = async function (
  callback,
  schoolCd,
  baseYear,
  authority,
  grade,
  loginId,
  fromPath
) {
  const params = {
    baseYear: baseYear,
    authority: authority,
    grade: grade,
    loginId: loginId,
  };
  const response = await instance.get(
    '/schools/' + schoolCd + '/users/csv/download',
    params,
    fromPath,
    true
  );
  callback(response);
};

/**
 * 学校取得
 * @param {Function} callback コールバック関数
 * @param {string} schoolCd 学校コード
 * @param {string} fromPath 呼び出し元のパス
 */
export const findSchool = async function (callback, schoolCd, fromPath) {
  const params = {};
  const response = await instance.get('/schools/' + schoolCd, params, fromPath);
  callback(response.body);
};

/**
 * 電子教材取得
 * @param {Function} callback コールバック関数
 * @param {string} licenseCd ライセンスコード
 * @param {string} fromPath 呼び出し元のパス
 */
export const findTextbook = async function (callback, licenseCd, fromPath) {
  const params = {};
  const response = await instance.get(
    '/textbooks/' + licenseCd,
    params,
    fromPath
  );
  callback(response.body);
};

/**
 * ユーザ取得
 * @param {Function} callback コールバック関数
 * @param {string} schoolCd 学校コード
 * @param {string} loginId ログインID
 * @param {string} fromPath 呼び出し元のパス
 */
export const findUser = async function (callback, schoolCd, loginId, fromPath) {
  const params = {};
  const response = await instance.get(
    '/schools/' + schoolCd + '/users/' + loginId,
    params,
    fromPath
  );
  callback(response.body);
};

/**
 * ログイン
 * @param {Function} callback コールバック関数
 * @param {string} loginId ログインID
 * @param {string} password パスワード
 */
export const login = async function (callback, loginId, password) {
  const params = {
    loginId: loginId,
    password: password,
  };
  const response = await instance.post('/login', params);
  callback(response.body);
};

/**
 * SSOログイン
 * @param {Function} callback コールバック関数
 * @param {string} code コード
 * @param {string} state 遷移元情報
 */
export const loginWithSso = async function (callback, code, state) {
  const params = {
    code: code,
    state: state,
  };
  const response = await instance.post('/login/sso', params);
  callback(response.body);
};

/**
 * ログアウト
 * @param {Function} callback コールバック関数
 * @param {string} accessToken アクセストークン
 */
export const logout = async function (callback, accessToken) {
  const params = {
    accessToken: accessToken,
  };
  const response = await instance.post('/logout', params);
  callback(response.body);
};

// 端末制御は行わなくなったためコメントアウト
// /**
//  * クライアントリフレッシュ
//  * @param {Function} callback コールバック関数
//  * @param {string} loginId ログインID
//  * @param {string} fromPath 呼び出し元のパス
//  */
// export const refreshClient = async function (callback, loginId, fromPath) {
//   const params = {
//     loginId: loginId,
//   };
//   const response = await instance.post('/client/refresh', params, fromPath);
//   callback(response.body);
// };

/**
 * トークンリフレッシュ
 * @param {string} refreshToken リフレッシュトークン
 */
export const refreshToken = async function (refreshToken) {
  const params = {
    refreshToken: refreshToken,
  };
  const response = await instance.post('/token/refresh', params);
  return response.body;
};

/**
 * 学校登録
 * @param {Function} callback コールバック関数
 * @param {string} schoolCd 学校コード
 * @param {string} schoolName 学校名
 * @param {string} schoolType 学校区分
 * @param {string} zipCd 郵便番号
 * @param {string} prefCd 都道府県コード
 * @param {string} address 住所
 * @param {string} phoneNum 電話番号
 * @param {string} remarks 備考
 * @param {string} fromPath 呼び出し元のパス
 */
export const registerSchool = async function (
  callback,
  schoolCd,
  schoolName,
  schoolType,
  zipCd,
  prefCd,
  address,
  phoneNum,
  remarks,
  fromPath
) {
  const params = {
    schoolCd: schoolCd,
    schoolName: schoolName,
    schoolType: schoolType,
    zipCd: zipCd,
    prefCd: prefCd,
    address: address,
    phoneNum: phoneNum,
    remarks: remarks,
  };
  const response = await instance.post('/schools', params, fromPath);
  callback(response.body);
};

/**
 * 電子教材登録
 * @param {Function} callback コールバック関数
 * @param {string} licenseCd ライセンスコード
 * @param {string} textbookName 電子教材名
 * @param {string} subjectCd 教科コード
 * @param {number} standardTerm 基準有効期間
 * @param {string} textbookType1 電子教材種別1
 * @param {string} textbookType2 電子教材種別2
 * @param {number} displayOrder 表示順
 * @param {string} remarks 備考
 * @param {boolean} isUploadedFiles 複数ファイルアップロードフラグ
 * @param {string} fromPath 呼び出し元のパス
 */
export const registerTextbook = async function (
  callback,
  licenseCd,
  textbookName,
  subjectCd,
  standardTerm,
  displayOrder,
  textbookType1,
  textbookType2,
  remarks,
  isUploadedFiles,
  fromPath
) {
  const params = {
    licenseCd: licenseCd,
    textbookName: textbookName,
    subjectCd: subjectCd,
    standardTerm: Number(standardTerm),
    displayOrder: Number(displayOrder),
    textbookType1: textbookType1,
    textbookType2: textbookType2,
    remarks: remarks,
    isUploadedFiles: isUploadedFiles,
  };
  const response = await instance.post('/textbooks', params, fromPath);
  callback(response.body);
};

/**
 * ユーザ登録
 * @param {Function} callback コールバック関数
 * @param {string} schoolCd 学校コード
 * @param {string} displayName 表示名
 * @param {string} emailAddress メールアドレス
 * @param {string} authority ユーザ区分
 * @param {string} admissionYear 入学年度
 * @param {string} remarks 備考
 * @param {string} fromPath 呼び出し元のパス
 *
 */
export const registerUser = async function (
  callback,
  schoolCd,
  displayName,
  emailAddress,
  authority,
  admissionYear,
  remarks,
  fromPath
) {
  const params = {
    displayName: displayName,
    emailAddress: emailAddress,
    authority: authority,
    admissionYear: admissionYear ? Number(admissionYear) : 0,
    remarks: remarks,
  };
  const response = await instance.post(
    '/schools/' + schoolCd + '/users',
    params,
    fromPath
  );
  callback(response.body);
};

/**
 * 本棚情報取得
 * @param {Function} callback コールバック関数
 * @param {string} loginId ログインID
 * @param {string} fromPath 呼び出し元のパス
 */
export const searchBookshelf = async function (callback, loginId, fromPath) {
  const params = {};
  const response = await instance.get(
    '/users/' + loginId + '/bookshelf',
    params,
    fromPath
  );
  callback(response.body);
};

/**
 * ライセンス取得
 * @param {Function} callback コールバック関数
 * @param {string} schoolCd 学校コード
 * @param {string} fromPath 呼び出し元のパス
 */
export const searchLicenses = async function (callback, schoolCd, fromPath) {
  const params = {};
  const response = await instance.get(
    '/schools/' + schoolCd + '/licenses',
    params,
    fromPath
  );
  callback(response.body);
};

/**
 * 学校一覧取得
 * @param {string} fromPath 呼び出し元のパス
 * @param {?string} schoolCd 学校コード
 * @param {?string} prefCd 都道府県コード
 * @param {?string} schoolType 学校区分
 */
export const searchSchools = async function (
  fromPath,
  schoolCd = null,
  prefCd = null,
  schoolType = null
) {
  const params = {
    schoolCd: schoolCd,
    prefCd: prefCd,
    schoolType: schoolType,
  };
  const response = await instance.get('/schools', params, fromPath);
  return response.body;
};

/**
 * 学校区分取得
 * @param {string} fromPath 呼び出し元のパス
 */
export const searchSchoolTypes = async function (fromPath) {
  const params = {};
  const response = await instance.get('/school-types', params, fromPath);
  return response.body;
};

/**
 * 教科コード取得
 * @param {Function} callback コールバック関数
 * @param {string} fromPath 呼び出し元のパス
 */
export const searchSubjects = async function (callback, fromPath) {
  const params = {};
  const response = await instance.get('/subjects', params, fromPath);
  return response.body;
};

/**
 * 電子教材一覧取得
 * @param {Function} callback コールバック関数
 * @param {string} fromPath 呼び出し元のパス
 * @param {?string} subjectCd 教科コード
 * @param {?string} name 電子教材名
 */
export const searchTextbooks = async function (
  callback,
  fromPath,
  subjectCd = null,
  name = null
) {
  const params = {
    subjectCd: subjectCd,
    name: name,
  };
  const response = await instance.get('/textbooks', params, fromPath);
  callback(response.body);
};

/**
 * 画面別閲覧数取得
 * @param {Function} callback コールバック関数
 * @param {string} schoolCd 学校コード
 * @param {string} startDate 開始年月日
 * @param {string} endDate 終了年月日
 * @param {string} grade 学年
 * @param {string} fromPath 呼び出し元のパス
 */
export const searchUsageStats = async function (
  callback,
  schoolCd,
  grade,
  startDate,
  endDate,
  fromPath
) {
  const params = {
    grade: grade,
    startDate: startDate,
    endDate: endDate,
  };
  const response = await instance.get(
    '/schools/' + schoolCd + '/usage-stats',
    params,
    fromPath
  );
  callback(response.body);
};

/**
 * ユーザ閲覧履歴取得
 * @param {Function} callback コールバック関数
 * @param {string} schoolCd 学校コード
 * @param {string} grade 学年
 * @param {string} viewId 画面ID
 * @param {string} date 閲覧履歴取得日
 * @param {string} fromPath 呼び出し元のパス
 */
export const searchUsageStatsDetail = async function (
  callback,
  schoolCd,
  grade,
  viewId,
  date,
  fromPath
) {
  const params = {
    grade: grade,
    date: date,
  };
  const response = await instance.get(
    '/schools/' + schoolCd + '/usage-stats/' + viewId,
    params,
    fromPath
  );
  callback(response.body);
};

/**
 * ユーザライセンス取得
 * @param {Function} callback コールバック関数
 * @param {string} schoolCd 学校コード
 * @param {string} baseYear 基準年度
 * @param {string} authority ユーザ区分
 * @param {string} grade 学年
 * @param {string} loginId ログインID
 * @param {string} fromPath 呼び出し元のパス
 */
export const searchUserLicenses = async function (
  callback,
  schoolCd,
  baseYear,
  authority,
  grade,
  loginId,
  fromPath
) {
  const params = {
    baseYear: baseYear,
    authority: authority,
    grade: grade,
    loginId: loginId,
  };
  const response = await instance.get(
    '/schools/' + schoolCd + '/users/licenses',
    params,
    fromPath
  );
  callback(response.body);
};

/**
 * ユーザ一覧取得
 * @param {Function} callback コールバック関数
 * @param {string} schoolCd 学校コード
 * @param {string} baseYear 基準年度
 * @param {string} authority ユーザ区分
 * @param {string} grade 学年
 * @param {string} loginId ログインID
 * @param {string} fromPath 呼び出し元のパス
 */
export const searchUsers = async function (
  callback,
  schoolCd,
  baseYear,
  authority,
  grade,
  loginId,
  fromPath
) {
  const params = {
    baseYear: baseYear,
    authority: authority,
    grade: grade,
    loginId: loginId,
  };
  const response = await instance.get(
    '/schools/' + schoolCd + '/users',
    params,
    fromPath
  );
  callback(response.body);
};

/**
 * ライセンス更新
 * @param {Function} callback コールバック関数
 * @param {string} schoolCd 学校コード
 * @param {array} schoolLicenses ライセンスリスト
 * @param {string} fromPath 呼び出し元のパス
 */
export const updateLicenses = async function (
  callback,
  schoolCd,
  schoolLicenses,
  fromPath
) {
  const params = {
    schoolLicenses: schoolLicenses,
  };
  const response = await instance.put(
    '/schools/' + schoolCd + '/licenses',
    params,
    fromPath
  );
  callback(response.body);
};

/**
 * パスワード更新
 * @param {Function} callback コールバック関数
 * @param {string} loginId ログインID
 * @param {string} oldPassword 現パスワード
 * @param {string} newPassword 新パスワード
 * @param {string} newPasswordConfirm 新パスワード（確認用）
 * @param {string} fromPath 呼び出し元のパス
 */
export const updatePassword = async function (
  callback,
  loginId,
  oldPassword,
  newPassword,
  newPasswordConfirm,
  fromPath
) {
  const params = {
    oldPassword: oldPassword,
    newPassword: newPassword,
    newPasswordConfirm: newPasswordConfirm,
    accessToken: localStorage.getItem('aToken'),
  };
  const response = await instance.put(
    '/users/' + loginId + '/password',
    params,
    fromPath
  );
  callback(response.body);
};

/**
 * 学校更新
 * @param {Function} callback コールバック関数
 * @param {string} schoolName 学校名
 * @param {string} schoolCd 学校コード
 * @param {string} schoolType 学校区分
 * @param {string} zipCd 郵便番号
 * @param {string} prefCd 都道府県コード
 * @param {string} address 住所
 * @param {string} phoneNum 電話番号
 * @param {string} remarks 備考
 * @param {string} fromPath 呼び出し元のパス
 */
export const updateSchool = async function (
  callback,
  schoolCd,
  schoolName,
  schoolType,
  zipCd,
  prefCd,
  address,
  phoneNum,
  remarks,
  fromPath
) {
  const params = {
    schoolName: schoolName,
    schoolType: schoolType,
    zipCd: zipCd,
    prefCd: prefCd,
    address: address,
    phoneNum: phoneNum,
    remarks: remarks,
  };
  const response = await instance.put('/schools/' + schoolCd, params, fromPath);
  callback(response.body);
};

/**
 * 電子教材更新
 * @param {Function} callback コールバック関数
 * @param {string} licenseCd ライセンスコード
 * @param {string} textbookName 電子教材名
 * @param {string} subjectCd 教科コード
 * @param {number} standardTerm 基準有効期間
 * @param {number} displayOrder 表示順
 * @param {string} textbookType1 電子教材種別1
 * @param {string} textbookType2 電子教材種別2
 * @param {string} remarks 備考
 * @param {boolean} isUploadedFile1 ファイル１アップロードフラグ
 * @param {boolean} isUploadedFile2 ファイル２アップロードフラグ
 * @param {string} fromPath 呼び出し元のパス
 */
export const updateTextbook = async function (
  callback,
  licenseCd,
  textbookName,
  subjectCd,
  standardTerm,
  displayOrder,
  textbookType1,
  textbookType2,
  remarks,
  isUploadedFile1,
  isUploadedFile2,
  isDeletedFile2,
  fromPath
) {
  const params = {
    textbookName: textbookName,
    subjectCd: subjectCd,
    standardTerm: Number(standardTerm),
    displayOrder: Number(displayOrder),
    textbookType1: textbookType1,
    textbookType2: textbookType2,
    remarks: remarks,
    isUploadedFile1: isUploadedFile1,
    isUploadedFile2: isUploadedFile2,
    isDeletedFile2: isDeletedFile2,
  };
  const response = await instance.put(
    '/textbooks/' + licenseCd,
    params,
    fromPath
  );
  callback(response.body);
};

/**
 * ユーザ更新
 * @param {Function} callback コールバック関数
 * @param {string} schoolCd 学校コード
 * @param {string} loginId ログインID
 * @param {string} displayName 表示名
 * @param {string} emailAddress メールアドレス
 * @param {string} newPassword 新パスワード
 * @param {string} newPasswordConfirm 新パスワード（確認用）
 * @param {string} authority ユーザ区分
 * @param {string} userStatus 状態
 * @param {string} remarks 備考
 * @param {string} fromPath 呼び出し元のパス
 */
export const updateUser = async function (
  callback,
  schoolCd,
  loginId,
  displayName,
  emailAddress,
  newPassword,
  newPasswordConfirm,
  userStatus,
  remarks,
  fromPath
) {
  const params = {
    displayName: displayName,
    emailAddress: emailAddress,
    newPassword: newPassword,
    newPasswordConfirm: newPasswordConfirm,
    userStatus: userStatus,
    remarks: remarks,
  };
  const response = await instance.put(
    '/schools/' + schoolCd + '/users/' + loginId,
    params,
    fromPath
  );
  callback(response.body);
};

/**
 * ユーザライセンス更新
 * @param {Function} callback コールバック関数
 * @param {string} schoolCd 学校コード
 * @param {array} userLicenses ユーザライセンスリスト
 * @param {string} fromPath 呼び出し元のパス
 */
export const updateUserLicenses = async function (
  callback,
  schoolCd,
  userLicenses,
  fromPath
) {
  const params = {
    userLicenses: userLicenses,
  };
  const response = await instance.put(
    '/schools/' + schoolCd + '/users/licenses',
    params,
    fromPath
  );
  callback(response.body);
};

/**
 * 電子教材サムネイルアップロード
 * @param {string} licenseCd ライセンスコード
 * @param {string} seq 電子教材ファイルの番号(1 or 2)
 * @param {File} img サムネイルのファイル
 * @param {string} fromPath 呼び出し元のパス
 */
export const uploadTextbookThumbnail = async function (
  licenseCd,
  seq,
  img,
  fromPath
) {
  let formData = new FormData();
  formData.append('image', img);
  const response = await instance.put(
    '/textbooks/' + licenseCd + '/' + seq + '/thumbnail/upload',
    formData,
    fromPath,
    false
  );
  return response.body;
};

/**
 * 電子教材アップロード
 * @param {string} url S3 署名付きURL
 * @param {File} zip 電子教材のzipファイル
 */
export const uploadTextbookZip = async function (url, zip) {
  const res = await instance.s3Put(url, zip);
  if (res.statusCode === 200) {
    return res.body;
  } else {
    return [Message.TEXTBOOK.UPLOAD_FAIL];
  }
};

/**
 * ユーザCSVアップロード
 * @param {Function} callback コールバック関数
 * @param {string} schoolCd 学校コード
 * @param {File} csv ユーザ情報のCSVファイル
 * @param {string} fromPath 呼び出し元のパス
 */
export const uploadUsersCsv = async function (
  callback,
  schoolCd,
  csv,
  fromPath
) {
  let formData = new FormData();
  formData.append('csv', csv);
  const response = await instance.put(
    '/schools/' + schoolCd + '/users/csv/upload',
    formData,
    fromPath
  );
  callback(response.body);
};

/**
 * ユーザCSV状況取得
 * @param {string} schoolCd 学校コード
 * @param {string} fromPath 呼び出し元のパス
 */
export const findUsersCsvStatus = async function (
  schoolCd,
  fromPath
) {
  const params = {};
  const response = await instance.get(
    '/schools/' + schoolCd + '/users/csv/status',
    params,
    fromPath
  );
  return response.body;
};

/**
 * エラーCSVダウンロード
 * @param {Function} callback コールバック関数
 * @param {string} schoolCd 学校コード
 * @param {string} fromPath 呼び出し元のパス
 */
export const downloadErrorCsv = async function (callback, schoolCd, fromPath) {
  const params = {
    // ファイル区分：ユーザCSV
    fileType: '0',
  };
  const response = await instance.get(
    '/schools/' + schoolCd + '/users/error-csv/download',
    params,
    fromPath
  );
  callback(response.body);
};

/**
 * アクセスログ出力
 * @param {string} viewId 画面ID
 * @param {string} fromPath 呼び出し元のパス
 */
export const outputAccessLog = async function (viewId, fromPath) {
  const params = {
    viewId: viewId,
  };
  await instance.post('/access-log', params, fromPath, true);
};
