<template>
  <div class="max-w-2xl mx-auto lg:max-w-4xl md:max-w-4xl">
    <Title msg="ユーザ登録" submsg="User Register" />
    <div class="flex justify-center pb-10">
      <form @submit.prevent class="pt-7 w-full md:w-2/3">
        <ErrMsgCard :errMsgs="errMsgs" :process="process" />
        <div class="grid grid-cols-1 gap-5">
          <div>
            <p class="text-base text-red-500">
              ※「ログインID」と「パスワード」は自動採番されます。
            </p>
          </div>

          <div>
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ displayName.key }}</span>
              <span class="form-not-required">任意</span>
            </div>
            <input
              type="text"
              placeholder="例：ユーザ１"
              v-model="displayName.value"
              maxlength="50"
              class="w-full"
            />
          </div>

          <div>
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ emailAddress.key }}</span>
              <span class="form-not-required">任意</span>
            </div>
            <input
              type="email"
              placeholder="例：abc1234@gmail.com"
              v-model="emailAddress.value"
              maxlength="256"
              class="w-full"
            />
          </div>

          <!--アクセス者の権限以下の権限のみ表示 -->
          <div>
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ authority.key }}</span>
              <span class="form-required">必須</span>
            </div>
            <div class="my-2 flex">
              <input
                type="radio"
                id="student"
                name="authority"
                :value="userAuth.STUDENT.code"
                v-model="authority.value"
                class="
                  radio-button:border-indigo-800
                  radio-button:text-white
                  radio-button:bg-indigo-800
                  hidden
                "
              />
              <label for="student" class="w-1/3 radio-label">
                {{ userAuth.STUDENT.name }}
              </label>
              <input
                type="radio"
                id="teacher"
                name="authority"
                :value="userAuth.TEACHER.code"
                v-model="authority.value"
                class="
                  radio-button:border-indigo-800
                  radio-button:text-white
                  radio-button:bg-indigo-800
                  hidden
                "
              />
              <label for="teacher" class="w-1/3 radio-label">
                {{ userAuth.TEACHER.name }}
              </label>
              <input
                v-if="isRegistrantSysAdmin"
                type="radio"
                id="systemAdmin"
                name="authority"
                :value="userAuth.SYSTEM_ADMIN.code"
                v-model="authority.value"
                class="
                  radio-button:border-indigo-800
                  radio-button:text-white
                  radio-button:bg-indigo-800
                  hidden
                "
              />
              <label
                v-if="isRegistrantSysAdmin"
                for="systemAdmin"
                class="w-1/3 radio-label"
              >
                {{ userAuth.SYSTEM_ADMIN.name }}
              </label>
            </div>
          </div>

          <!-- アクセス者の権限がシステム管理者の場合のみ表示 -->
          <div v-if="isRegistrantSysAdmin">
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">所属学校名</span>
              <span class="form-required">必須</span>
            </div>
            <multiselect
              v-model="school.value"
              :options="setSchoolOptions(schoolList)"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="入力、選択してください"
              selectedLabel="選択済み"
              selectLabel="Enterを押して選択"
              deselectLabel="Enterを押して選択解除"
              label="name"
              track-by="name"
              key="school"
            >
              <template v-slot:noResult>
                {{noResult}}
              </template>
              <template v-slot:noOptions>
                {{noOptions}}
              </template>
            </multiselect>
          </div>

          <div v-if="isStudent">
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ admissionYear.key }}</span>
              <span class="form-required">必須</span>
            </div>
            <p class="text-sm text-red-500">
              学年判別で使用するため、転入生登録時は転入生が1年生の時の年度を入力してください。
            </p>
            <p class="text-sm text-red-500">
              例：① 2021年度に2年生として転入の場合 ⇒
              入学年度は2020年度（現在年度 - 1年）
            </p>
            <p class="text-sm text-red-500 ml-7">
              ② 2021年度に3年生として転入の場合 ⇒ 入学年度は2019年度（現在年度 -
              2年）
            </p>
            <span class="flex">
              <input
                type="text"
                id=" admissionYear"
                v-model="admissionYear.value"
                maxlength="4"
                class="w-1/2"
                placeholder="例：2021"
              />
              <span class="pl-2 pt-5">年度</span>
            </span>
          </div>

          <div>
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ remarks.key }}</span>
              <span class="form-not-required">任意</span>
            </div>
            <textarea
              v-model="remarks.value"
              maxlength="200"
              class="w-full"
              rows="3"
            ></textarea>
          </div>

          <div class="flex justify-center mt-8">
            <button
              @click="$router.go(-1)"
              type="button"
              name="back-button"
              class="button btn-gray w-full mr-5 md:mr-10"
            >
              戻る
            </button>
            <button
              v-on:click="register"
              type="button"
              name="button"
              class="button btn-indigo w-full"
            >
              登録
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { registerUser, searchSchools } from '../api';
import Title from '../components/TheTitle.vue';
import ErrMsgCard from '../components/ErrorMssageCard.vue';
import UserAuth from '../const/userAuthority';
import Message from '../const/message';
import Validation from '../utils/validation';
import Authorize from '../utils/authorize';

export default {
  name: 'UserManageRegester',
  components: {
    Title,
    ErrMsgCard,
  },
  data() {
    return {
      displayName: { key: '表示名', value: '' },
      emailAddress: { key: 'メールアドレス', value: '' },
      authority: { key: 'ユーザ区分', value: UserAuth.STUDENT.code },
      school: { key: '所属学校名', value: { name: '', code: '' } },
      admissionYear: { key: '入学年度', value: '' },
      remarks: { key: '備考', value: '' },
      userAuth: UserAuth,
      schoolList: [],

      errMsgs: [],
      process: Message.PROC_TYPE.REGISTER,
      noResult: Message.MULTI_NOT_RESULT,
      noOptions: Message.MULTI_NOT_OPTIONS,
      isUnsent: true
    };
  },
  created: async function () {
    // 権限チェック
    if (
      (this.$store.state.authority === UserAuth.SCHOOL_ADMIN.code &&
        this.$store.state.schoolCd === this.$route.params.schoolCd) ||
      this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code
    ) {
      if (this.isRegistrantSysAdmin) {
        // システム管理者の場合、学校コードを全件取得
        const rspBody = await searchSchools(this.$route.path);
        this.schoolList = rspBody.detail;
      }
    } else {
      // エラーページに遷移
      this.$router.push({
        name: 'not-found',
        params: { catchAll: 'not-found' },
      });
      // 処理強制終了
      return false;
    }
  },
  methods: {
    register() {
      let self = this;
      // 登録確認ポップアップを表示
      this.$store.commit('setMessages', [
        Message.generateMessage(Message.CONFIRM, [this.process]),
      ]);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setIsModalOpen', true);
      this.$store.commit('setOkAction', async function () {
        // システム管理者でない場合
        if (!self.isRegistrantSysAdmin) {
          // 検索条件の学校コードに自身の所属する学校をセット
          self.school.value.code = self.$store.state.schoolCd;
        }
        // バリデーション定義作成
        let validationArray = [];
        // バリデーションチェック実施
        self.errMsgs = validationArray.concat(
          Validation.requiredInput(self.authority),
          Validation.requiredSelect(self.school),
          self.authority.value === UserAuth.STUDENT.code
            ? Validation.requiredInput(self.admissionYear)
            : [],
          Validation.isInteger(self.admissionYear),
          Validation.fixedLength(self.admissionYear, 4),
          Validation.isMailFormat(self.emailAddress)
        );

        // エラーメッセージがある場合は処理を中断
        if (self.errMsgs.length) return false;

        if (Date.now() / 1000 > self.$store.state.tokenExp){
          if (!await Authorize.isAuth()) {
            // 認証失敗：ログイン画面へ遷移
            self.$router.push({ name: 'login', query: { redirect: self.$route.path } });
            return false;
          }
        }

        // ユーザ登録API呼び出し
        registerUser(
          function (rspBody) {
            if (rspBody.result.code === 0) {
              self.isUnsent = false;
              // 成功した場合、完了メッセージを生成
              const completeMsg = [
                Message.generateMessage(Message.COMPLETED, [
                  Message.PROC_TYPE.REGISTER,
                ]),
                'ログインID: ' + rspBody.detail.loginId,
              ];
              // パスワードが渡された場合のみメッセージに追加
              if (rspBody.detail.password) completeMsg.push('パスワード: ' + rspBody.detail.password);

              // 完了メッセージをポップアップで表示し、ユーザ一覧に遷移
              self.$store.commit('setMessages', completeMsg);
              self.$store.commit('setOkAction', function () {
                self.toUserList();
              });
              self.$store.commit('setIsModalInfo', true);
              self.$store.commit('setCancelBtnHide', true);
              self.$store.commit('setIsModalOpen', true);
            } else {
              // 失敗した場合、エラー内容を表示
              self.errMsgs = rspBody.result.messages;
            }
          },
          self.school.value.code,
          self.displayName.value,
          self.emailAddress.value,
          self.authority.value,
          self.admissionYear.value,
          self.remarks.value,
          self.$route.path
        );
      });
    },
    setSchoolOptions(schoolList) {
      let options = new Array();
      schoolList.forEach((s) => {
        let option = {
          name: s.schoolCd + ' / ' + s.schoolName,
          code: s.schoolCd,
        };
        options.push(option);
      });
      return options;
    },
    toUserList() {
      this.$router.push({
        name: 'user-manage',
      });
    },
  },
  computed: {
    isStudent() {
      return this.authority.value === UserAuth.STUDENT.code;
    },
    isRegistrantSysAdmin() {
      return this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isUnsent && to.name != 'maintenance') {
      this.$store.commit('setMessages', [Message.INPUT_VALUE_CLEAR]);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setIsModalOpen', true);
      this.$store.commit('setOkAction', function () {
        next();
      });
      this.$store.commit('setCancelAction', function () {
        next(false);
      });
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.button {
  @apply font-semibold w-32 rounded py-2 px-2 focus:outline-none focus:ring focus:ring-opacity-50;
}
input,
select,
textarea {
  @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}
input:read-only {
  @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-200;
}
select,
textarea {
  @apply mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-300;
}
.multiselect {
  height: 40px;
  @apply p-0 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}
.multiselect--active {
  height: 40px;
}
.multiselect >>> .multiselect__single {
  min-height: 15px;
  @apply my-0 py-0 leading-tight;
}
.multiselect >>> .multiselect__input {
  min-height: 15px;
  @apply my-0 py-0 h-6 leading-tight;
}
.multiselect >>> .multiselect__tags {
  max-height: 45px;
}
.multiselect >>> .multiselect__content-wrapper {
  @apply overflow-x-hidden;
}
</style>
