/** ユーザ区分 */
class UserAuthority {
  /** 学習者 */
  static STUDENT = { code: '1', name: '学習者' };
  /** 教職員 */
  static TEACHER = { code: '2', name: '教職員' };
  /** 学校管理者 */
  static SCHOOL_ADMIN = { code: '3', name: '学校管理者' };
  /** システム管理者 */
  static SYSTEM_ADMIN = { code: '9', name: 'ｼｽﾃﾑ管理者' };

  /**
   * ユーザ区分のコードから論理名を取得する
   * @param {string} code ユーザ区分のコード
   * @returns ユーザ区分の論理名
   */
  static getNameByCode(code) {
    let name = '';
    for (const key in this) {
      if (this[key].code === code) {
        name = this[key].name;
      }
    }
    return name;
  }

  /**
   * ユーザ区分を配列で取得
   * @param {string} userAuth ログインユーザのユーザ区分のコード
   * @returns ユーザ区分の配列
   */
  static getList(userAuth) {
    let array = [];
    for (const key in this) {
      // ログインユーザがシステム管理者以外の場合は、システム管理者を追加しない
      if (this[key] !== this.SYSTEM_ADMIN || userAuth === this.SYSTEM_ADMIN.code) array.push(this[key]);
    }
    return array;
  }
}

module.exports = UserAuthority;
