<template>
  <div class="z-40">
    <Slide :closeOnNavigation="true">
      <router-link :to="{ name: 'bookshelf' }" id="bookshelf" v-if="isTeacher">
        <em class="w-8">
          <font-awesome-icon icon="book-open" size="lg" />
        </em>
        <span> 本棚 </span>
      </router-link>

      <!-- 将来機能予定 -->
      <!-- <router-link to="/" id="board">
        <em class="w-8">
          <font-awesome-icon icon="clipboard-list" size="lg" />
        </em>
        <span>
          掲示板
        </span>
      </router-link>
      
      <router-link to="/" id="homework-submit">
        <em class="w-8">
          <font-awesome-icon icon="file-upload" size="lg" />
        </em>
        <span>
          提出
        </span>
      </router-link> -->

      <router-link @click="clearKey('S0002')" :to="{ name: 'usage-stats', params: {schoolCd: $store.state.schoolCd} }" id="user-manage">
        <em class="w-8">
          <font-awesome-icon icon="chart-bar" size="lg" />
        </em>
        <span> 画面別閲覧数一覧 </span>
      </router-link>

      <router-link @click="clearKey('M0001')" :to="{ name: 'school-manage' }" id="school-manage" v-if="isSysAdmin">
        <em class="w-8">
          <font-awesome-icon icon="school" size="lg" />
        </em>
        <span> 学校管理 </span>
      </router-link>

      <router-link @click="clearKey('M0005')" :to="{ name: 'user-manage', params: {schoolCd: $store.state.schoolCd} }" id="user-manage">
        <em class="w-8">
          <font-awesome-icon icon="users" size="lg" />
        </em>
        <span> ユーザ管理 </span>
      </router-link>

      <router-link @click="clearKey('M0009')" :to="{ name: 'book-manage' }" id="book-manage" v-if="isSysAdmin">
        <em class="w-8">
          <font-awesome-icon icon="book" size="lg" />
        </em>
        <span> 電子教材管理 </span>
      </router-link>
    </Slide>
  </div>
</template>

<script>
import { Slide } from "vue3-burger-menu";
import UserAuth from '../const/userAuthority';
import ViewId from '../const/viewId';

export default {
  name: 'SideMenu',
  data() {
    return {
      isSysAdmin: this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code,
      isTeacher: this.$store.state.authority === UserAuth.TEACHER.code,
    };
  },
  methods: {
    clearKey(targetViewId) {
      if(targetViewId === ViewId['usage-stats'].id) this.$store.commit('clearSearchUsageStatsKeys', this.$store.state.searchScreenviewKeys);
      if(targetViewId === ViewId['school-manage'].id) this.$store.commit('clearSearchSchoolKeys', this.$store.state.searchSchoolKeys);
      if(targetViewId === ViewId['user-manage'].id) this.$store.commit('clearSearchUserKeys', this.$store.state.searchUserKeys);
      if(targetViewId === ViewId['book-manage'].id) this.$store.commit('clearSearchBookKeys', this.$store.state.searchBookKeys);
    },
  },
  components: {
    Slide,
  },
};
</script>
