<template>
  <div class="">
    <form @submit.prevent class="pt-4">
      <div class="grid grid-cols-1 gap-5">
        <div>
          <span class="text-gray-700">学校名</span>
          <div class="flex justify-start">
            <input type="text" :value="getSchoolName" class="w-2/3" readonly />
            <button
              v-on:click="downloadLicense()"
              type="button"
              value="license"
              name="button"
              class="btn btn-indigo ml-6 my-1"
            >
              <span class="mx-2">
                <font-awesome-icon
                  icon="file-download"
                  size="lg"
                  class="mr-2"
                />
                証明書発行
              </span>
            </button>
          </div>
        </div>
        <div>
          <span class="text-gray-700">ライセンスコード / ライセンス名</span>
          <div class="flex justify-start">
            <multiselect
              v-model="license"
              :options="options"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="入力、選択してください"
              selectedLabel=""
              selectLabel=""
              deselectLabel=""
              label="name"
              track-by="name"
            >
              <template v-slot:noResult>
                {{noResult}}
              </template>
              <template v-slot:noOptions>
                {{noOptions}}
              </template>
            </multiselect>
            <button
              v-on:click="callParentEvent"
              type="button"
              value="license"
              name="button"
              class="btn btn-indigo ml-6 my-1"
            >
              追加
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { downloadLicense } from '../api'
import DownloadFile from '../utils/downloadFile'
import Message from '../const/message';
import Authorize from '../utils/authorize';

export default {
  name: 'SchoolLicenseManageForm',
  emits: ['parent-event'],
  props: {
    userLicenses: { type: Object, default: () => {} },
    schoolCd: { type: String, default: '' },
  },

  data() {
    return {
      license: { name: '', code: '' },
      options: [],
      noResult: Message.MULTI_NOT_RESULT,
      noOptions: Message.MULTI_NOT_OPTIONS,
    };
  },
  methods: {
    /** 親コンポーネントのメソッドにアクセス */
    callParentEvent() {
      // ライセンスコードがnullの場合初期値をセット
      this.license = this.license ?? { name: '', code: '' };

      if (this.license.code) {
        this.$emit('parent-event', this.license);
      }
    },
    /** 証明書発行 */
    async downloadLicense() {
      if (!this.schoolCd) {
        // エラーページ(メンテナンス画面)へ遷移
        this.$router.push({
          name: 'maintenance',
        })
      }
      if (Date.now() / 1000 > this.$store.state.tokenExp){
        if (!await Authorize.isAuth()) {
          // 認証失敗：ログイン画面へ遷移
          this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
          return false;
        }
      }
      const schoolName = this.getSchoolName;
      // 証明書ダウンロードAPI呼び出し
      downloadLicense(function (rsp) {
        // ファイル出力
        DownloadFile.download(rsp, DownloadFile.LICENSE, schoolName)
      }, this.schoolCd, this.$route.path);
    },
  },
  computed: {
    /** 学校名取得 */
    getSchoolName() {
      return this.userLicenses.schoolName;
    },
  },
  watch: {
    /** userLicensesが変更されたら、multiselectフォームを更新する */
    userLicenses: function (userLicenses) {
      this.options = [];
      userLicenses.allLicenses.forEach((license) => {
        let option = {
          name: license.licenseCd + ' / ' + license.textbookName,
          code: license.licenseCd,
        };
        this.options.push(option);
      });
    },
  },
};
</script>
<style scoped>
input:read-only {
  @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-200;
}
.multiselect {
  width: 66.6%;
  height: 40px;
  @apply p-0 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}
.multiselect--active {
  height: 40px;
}
.multiselect >>> .multiselect__single {
  min-height: 15px;
  @apply my-0 py-0 leading-tight;
}
.multiselect >>> .multiselect__input {
  min-height: 15px;
  @apply my-0 py-0 h-6 leading-tight;
}
.multiselect >>> .multiselect__tags {
  max-height: 45px;
}
.multiselect >>> .multiselect__content-wrapper {
  @apply overflow-x-hidden;
}
.btn {
  @apply py-2 px-0;
}
</style>
