<template>
  <div class="antialiased pt-6">
    <span class="text-gray-700 text-lg font-bold">
      <font-awesome-icon icon="caret-down" class="ml-1" />
      有効ライセンス一覧
    </span>
    <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
      <table id="validLicenseTable" class="min-w-full leading-normal text-xs">
        <thead>
          <tr>
            <th class="td-status"></th>
            <th class="td-licenseCd">ライセンス<br />コード</th>
            <th class="td-lisenceName">ライセンス名</th>
            <th class="td-usedCnt md:px-0">使用数</th>
            <th class="td-totalCnt">総数</th>
            <th class="td-purchaseDate">購入年月日</th>
            <th class="td-startDate">開始年月日</th>
            <th class="td-endDate">終了年月日</th>
            <th class="td-delBtn">削除</th>
          </tr>
        </thead>
        <tbody class="w-full">
          <tr
            v-for="(addLicense, index) in addLicensesStore"
            :key="addLicense.licenseCd"
          >
            <td class="td-status">
              <p class="whitespace-nowrap font-bold text-blue-500">
                {{ addLicense.procType.value.name }}
              </p>
            </td>
            <td class="td-licenseCd">
              <p class="td-p whitespace-nowrap">
                {{ addLicense.licenseCd.value }}
              </p>
            </td>
            <td class="td-lisenceName">
              <p class="td-p whitespace-pre-wrap overflow-hidden" style="overflow-wrap: break-word">
                {{ addLicense.licenseName.value }}
              </p>
            </td>
            <td class="td-usedCnt">
              <p class="td-p whitespace-nowrap">
                {{ addLicense.usedCnt.value }}
              </p>
            </td>
            <td class="td-totalCnt">
              <input
                type="number"
                class="w-full h-8 px-2 no-spin whitespace-nowrap"
                @input="totalCntSliceMaxLength(5, index)"
                v-model.number="addLicensesStore[index].totalCnt.value"
              />
            </td>
            <td class="td-purchaseDate">
              <input
                type="date"
                onkeydown="return false"
                class="w-full h-8 px-1.5 no-spin whitespace-nowrap"
                v-model="addLicensesStore[index].purchaseDate.value"
              />
            </td>
            <td class="td-startDate">
              <input
                type="date"
                onkeydown="return false"
                class="w-full h-8 px-2 no-spin whitespace-nowrap"
                v-model="addLicensesStore[index].startDate.value"
              />
            </td>
            <td class="td-endDate">
              <input
                type="date"
                onkeydown="return false"
                class="w-full h-8 px-2 no-spin whitespace-nowrap"
                v-model="addLicensesStore[index].endDate.value"
              />
            </td>
            <td class="td-delBtn">
              <button
                v-on:click="deleteAddColumn(addLicensesStore[index].id.value)"
                class="btn"
              >
                <font-awesome-icon icon="trash-alt" size="lg" />
              </button>
            </td>
          </tr>
          <tr
            v-for="validLicense in existLicensesStore"
            :key="validLicense.licenseCd"
            :class="{ 'tr-delete': validLicense.procType.value.code === '2' }"
          >
            <td
              class="td-status"
            >
              <p class="whitespace-nowrap font-bold" 
                :class="{ 'text-red-500': validLicense.procType.value.code === '2',
                'text-green-500': validLicense.procType.value.code === '3' }"
              >
                {{ validLicense.procType.value.name }}
              </p>
            </td>
            <td class="td-licenseCd">
              <p class="td-p whitespace-nowrap">
                {{ validLicense.licenseCd.value }}
              </p>
            </td>
            <td class="td-lisenceName">
              <p class="td-p whitespace-nowrap">
                {{ licenseCdToName(validLicense.licenseCd.value) }}
              </p>
            </td>
            <td class="td-usedCnt">
              <p class="td-p whitespace-nowrap">
                {{ validLicense.usedCnt.value }}
              </p>
            </td>
            <td class="td-totalCnt">
              <input
                type="number"
                class="w-full h-8 px-2 no-spin whitespace-nowrap"
                @input="totalCntSliceMaxLength(5, index)"
                v-model.number="validLicense.totalCnt.value"
              />
            </td>
            <td class="td-purchaseDate">
              <input
                type="date"
                onkeydown="return false"
                class="w-full h-8 px-1.5 no-spin whitespace-nowrap"
                v-model="validLicense.purchaseDate.value"
              />
            </td>
            <td class="td-startDate">
              <input
                type="date"
                onkeydown="return false"
                class="w-full h-8 px-1.5 no-spin whitespace-nowrap"
                v-model="validLicense.startDate.value"
              >
            </td>
            <td class="td-endDate">
              <input
                type="date"
                onkeydown="return false"
                class="w-full h-8 px-1.5 no-spin whitespace-nowrap"
                v-model="validLicense.endDate.value"
              >
            </td>
            <td class="td-delBtn">
              <button
                v-if="validLicense.procType.value.code !== '2'"
                v-on:click="changeExistColumnStatus($event.currentTarget.id)"
                class="btn"
                :id="validLicense.id.value"
              >
                <font-awesome-icon icon="trash-alt" size="lg" />
              </button>
              <button
                v-if="validLicense.procType.value.code === '2'"
                v-on:click="changeExistColumnStatus($event.currentTarget.id)"
                class="delBtn"
                :id="validLicense.id.value"
              >
                <font-awesome-icon icon="undo-alt" size="lg" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex justify-center my-4">
      <button
        @click="$router.go(-1)"
        type="button"
        name="back-button"
        class="btn btn-gray mr-5 md:mr-10"
      >
        戻る
      </button>
      <button v-on:click="callParentEvent" type="button" name="button" class="btn btn-indigo">
        更新
      </button>
    </div>
  </div>
</template>

<script>
import Validation from '../utils/validation';
import Message from '../const/message';
import ProcType from '../const/procType';

export default {
  name: 'SchoolLicenseManageValidTable',
  emits: ['parent-event'],
  props: {
    userLicenses: { type: Object, default: () => {} },
    addLicenseInfo: { type: Object, default: () => {} },
  },
  data() {
    return {
      addLicensesStore: [],
      existLicensesStore: [],

      warnMsgs: [],
      errMsgs: [],

      updateFlg: false
    };
  },
  methods: {
    /** ライセンスコードからライセンス名を取得する */
    licenseCdToName(code) {
      return this.userLicenses.allLicenses.find((s) => s.licenseCd === code)
        .textbookName;
    },
    /** カラムの状態を変更する */
    changeExistColumnStatus(id) {
      switch(this.existLicensesStore[id].procType.value.name) {
        // 既存の場合、削除に変更
        case ProcType.EXISTING.name:
          if (this.existLicensesStore[id].usedCnt.value > 0 ) {
            this.openFirstModal(this.existLicensesStore, id);
          } else{
            this.existLicensesStore.find((el) => el.id.value == id).procType.value = ProcType.DELETE;
          }
          break;
        // 更新の場合、削除に変更
        case ProcType.UPDATE.name:
          if (this.existLicensesStore[id].usedCnt.value > 0 ) {
            this.openFirstModal(this.existLicensesStore, id);
          } else{
            this.existLicensesStore.find((el) => el.id.value == id).procType.value = ProcType.DELETE;
          }
          break;
          // 削除の場合
        case ProcType.DELETE.name:
          if(this.existLicensesStore[id].updateFlug.value === true) {
            // 更新フラグがtrueの場合、更新に変更
            this.existLicensesStore.find((el) => el.id.value == id).procType.value = ProcType.UPDATE;
          } else if (this.existLicensesStore[id].updateFlug.value === false) {
            // 更新フラグがfalseの場合、新規に変更
            this.existLicensesStore.find((el) => el.id.value == id).procType.value = ProcType.EXISTING;
          }
          break;
      }
    },
    /** 1つ目の確認モーダルウィンドウを開く */
    openFirstModal(target, id) {
      const self = this;
      const msg = [
        '選択したライセンスは現在使用中のユーザーが存在します。',
        'ライセンスを削除するとユーザーのライセンスも失効します。'
      ];
      this.$store.commit('setMessages', msg);
      this.$store.commit('setOkBtnMsg', '確認済み');
      this.$store.commit('setCancelBtnMsg', '再度確認する');
      this.$store.commit('setOkAction', async function(){
        await new Promise((resolve) => setTimeout(resolve, 100));
        self.openSecondModal(target, id);
      });
      this.$store.commit('setIsModalOpen', true)
    },
    /** 2つ目の確認モーダルウィンドウを開く */
    openSecondModal(target, id) {
      const msg = [
        Message.generateMessage(Message.CONFIRM, [Message.PROC_TYPE.DELETE]),
        'ライセンスの削除は更新ボタンを押すと実行されます。'
      ];
      this.$store.commit('setMessages', msg);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setOkAction', function(){
          target.find((el) => el.id.value == id).procType.value = ProcType.DELETE;
      });
      this.$store.commit('setIsModalBtnReverse', true);
      this.$store.commit('setIsModalOpen', true);
    },
    /** 追加したカラムを削除する */
    deleteAddColumn(id) {
      // idが該当する配列のみ削除（テーブル1行が1配列とリンクしているので、テーブルの該当行削除）
      this.addLicensesStore = this.addLicensesStore.filter((addLicense) => {
        return addLicense.id.value != id;
      });
    },
    /** メッセージの先頭に行数を追加 */
    addRowNumMessage(errMsgs, index){
      return errMsgs.map(msg => (index + 1) + '行目の' + msg);
    },
    /** 親コンポーネントのメソッドを呼び出す */
    callParentEvent() {
      let self = this;
      let updateLicenses = []; // 更新ライセンス格納用array初期化
      this.warnMsgs = []; // 警告メッセージ格納用array初期化

      // 有効ライセンステーブル内の新規データを追加
      let willUpdateLicenses = self.addLicensesStore.concat(this.existLicensesStore);

      // ライセンスのチェック
      for (let i = 0; i < willUpdateLicenses.length; i++) {
        if(willUpdateLicenses[i].procType.value.code === ProcType.EXISTING.code) {
          // 処理区分が既存はスキップ
          continue;
        } else if (willUpdateLicenses[i].procType.value.code !== ProcType.DELETE.code) {
          // 処理区分が削除でない場合、バリデーションチェック実施
          self.errMsgs = self.errMsgs.concat(
            this.addRowNumMessage(Validation.requiredInput(willUpdateLicenses[i].totalCnt), i),
            this.addRowNumMessage(Validation.isInteger(willUpdateLicenses[i].totalCnt), i),
            this.addRowNumMessage(Validation.isOkSchoolLicenseManageCnt(
              willUpdateLicenses[i].usedCnt.value,
              willUpdateLicenses[i].totalCnt.value
            ), i),
            this.addRowNumMessage(Validation.requiredInput(willUpdateLicenses[i].purchaseDate), i),
            this.addRowNumMessage(Validation.isDate(willUpdateLicenses[i].purchaseDate), i),
            this.addRowNumMessage(Validation.requiredInput(willUpdateLicenses[i].startDate), i),
            this.addRowNumMessage(Validation.isDate(willUpdateLicenses[i].startDate), i),
            this.addRowNumMessage(Validation.requiredInput(willUpdateLicenses[i].endDate), i),
            this.addRowNumMessage(Validation.isDate(willUpdateLicenses[i].endDate), i),
            this.addRowNumMessage(Validation.isOkSchoolLicenseManageDate(
              willUpdateLicenses[i].startDate,
              willUpdateLicenses[i].endDate
            ), i)
          );

          // 基準有効期間の警告表示のバリデーションチェック実施
          let tempWarnMsgs = Validation.isCheckSchoolLicenseManageDateSpan(
            willUpdateLicenses[i].licenseCd,
            willUpdateLicenses[i].startDate,
            willUpdateLicenses[i].endDate,
            willUpdateLicenses[i].standardTerm
          );
          if (tempWarnMsgs.length) {
            self.warnMsgs.push(tempWarnMsgs);
          }
        }

        // リクエストに必要な値をobjectに格納
        let licenseInfo = {
          licenseCd: willUpdateLicenses[i].licenseCd.value,
          procType: willUpdateLicenses[i].procType.value.code,
          usedCnt: willUpdateLicenses[i].usedCnt.value,
          totalCnt: willUpdateLicenses[i].totalCnt.value,
          purchaseDate: willUpdateLicenses[i].purchaseDate.value,
          startDate: willUpdateLicenses[i].startDate.value,
          endDate: willUpdateLicenses[i].endDate.value,
          oldId: willUpdateLicenses[i].oldId.value
        };

        // objectを配列に格納
        updateLicenses.push(licenseInfo);
      }

      // エラーメッセージが存在する場合
      if (self.errMsgs.length) {
        // ポップアップを表示する
        this.$store.commit('setMessages', self.errMsgs);
        this.$store.commit('setOkAction', function(){self.errMsgs = [];});
        this.$store.commit('setCancelBtnHide', true);
        this.$store.commit('setIsModalOpen', true);
        // 処理を中断
        return false;
      }

      if (self.warnMsgs.length) {
        // 先頭にメッセージを追加
        self.warnMsgs.unshift(Message.SCHOOL_LICENSES.DESCRIPTION_WARN);
        // 警告メッセージが追加されている場合、更新確認ポップアップを表示
        self.$store.commit('setMessages', self.warnMsgs);
        self.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
        self.$store.commit('setIsModalOpen', true);
        self.$store.commit('setOkAction', function () {
          // 親コンポーネントのライセンス更新メソッド呼び出し
          if (updateLicenses.length) self.$emit('parent-event', updateLicenses);
        });
      } else {
        // 親コンポーネントのライセンス更新メソッド呼び出し
        if (updateLicenses.length) self.$emit('parent-event', updateLicenses);
      }
      this.updateFlg = true;
    },
    /** 総数のMaxLengthを制御（type="Number"の要素はMaxLengthを指定できないため） */
    totalCntSliceMaxLength(maxLength, index) {
      if(this.addLicensesStore.length != 0) {
        // Numberの総数を文字列に変換してMaxLengthでslice
        const strSliceTotalCnt = String(this.addLicensesStore[index].totalCnt.value).slice(0, maxLength);
        if (strSliceTotalCnt) {
          this.addLicensesStore[index].totalCnt.value = Number(strSliceTotalCnt);
        } else {
          this.addLicensesStore[index].totalCnt.value = strSliceTotalCnt;
        }
      }
    }
  },
  computed: {
    // existLicensesStoreの前の値を保持する
    computedExistLicensesStore () {
      return JSON.parse(JSON.stringify(this.existLicensesStore))
    }
  },
  watch: {
    /** addLicenseInfoに変更があったら、addLicensesStoreを更新する */
    addLicenseInfo: function () {
      let addLicense = {
        id: { key: 'id', value: this.addLicenseInfo.id },
        licenseName: {
          key: 'ライセンス名',
          value: this.addLicenseInfo.licenseName,
        },
        standardTerm: {
          key: '基準有効期間',
          value: this.userLicenses.allLicenses.find(
            (s) => s.licenseCd === this.addLicenseInfo.licenseCd
          ).standardTerm,
        },
        licenseCd: {
          key: 'ライセンスコード',
          value: this.addLicenseInfo.licenseCd,
        },
        procType: { key: '処理区分', value: ProcType.NEW },
        usedCnt: { key: '使用数数', value: 0 },
        totalCnt: { key: '総数', value: 0 },
        purchaseDate: { key: '購入年月日', value: '' },
        startDate: { key: '開始年月日', value: '' },
        endDate: { key: '終了年月日', value: '' },
        oldId: { key: '旧ID', value: null }
      };

      this.addLicensesStore.unshift(addLicense);
    },
    /** 表内の値が変更されたら、proctypeを更新に変更＆更新履歴フラグをtrue */
    computedExistLicensesStore:{
      handler(newValue, oldValue) {
        if(oldValue.length != 0 && oldValue.length == newValue.length) {
          for(let i= 0; i< newValue.length; i++) {
            if((oldValue[i].totalCnt.value !== newValue[i].totalCnt.value |
            oldValue[i].purchaseDate.value !== newValue[i].purchaseDate.value |
            oldValue[i].startDate.value !== newValue[i].startDate.value |
            oldValue[i].endDate.value !== newValue[i].endDate.value) &&
            !this.updateFlg &&
            newValue[i].procType.value !== ProcType.DELETE) {
              this.existLicensesStore[i].updateFlug.value = true;
              this.existLicensesStore[i].procType.value = ProcType.UPDATE;
            }
          }
          this.updateFlg = false;
        }
      },
      deep: true
    },
    /** userLicensesが変更がされたら、existLicensesStoreを更新する */
    userLicenses: function () {
      this.existLicensesStore = [];
      this.addLicensesStore = [];

      this.userLicenses.validLicenses.forEach((validLicense, index) => {
        let existLicense = {
          id: { key: 'id', value: index },
          licenseCd: { key: 'ライセンスコード', value: validLicense.licenseCd },
          procType: { key: '処理区分', value: ProcType.EXISTING },
          usedCnt: { key: '使用数', value: validLicense.usedCnt },
          totalCnt: { key: '総数', value: validLicense.totalCnt },
          purchaseDate: { key: '購入年月日', value: validLicense.purchaseDate },
          startDate: { key: '開始年月日', value: validLicense.startDate },
          endDate: { key: '終了年月日', value: validLicense.endDate },
          updateFlug: { key: '更新履歴', value: false },
          oldId: { key: '旧id', value: validLicense.oldId },
          standardTerm: {
            key: '基準有効期間',
            value: this.userLicenses.allLicenses.find(
              (s) => s.licenseCd ===  validLicense.licenseCd
            ).standardTerm,
          },
        };
        this.existLicensesStore.push(existLicense);
      });
    },
  },
};
</script>

<style scoped>
thead,
tbody,
tr {
  @apply flex w-full;
}
tbody {
  @apply flex-col items-center bg-gray-50;
}
th,
td {
  @apply px-2.5 border-gray-200 text-xs;
}
th {
  @apply py-2 border-b-2 bg-gray-100 font-semibold text-gray-600 tracking-wider flex items-center place-content-center;
}
.tr-delete > td {
  @apply bg-red-100;
}
td {
  @apply py-1 border-b bg-white flex items-center;
}
.td-status {
  @apply place-content-center pr-0;
  width: 6%;
}
.td-licenseCd {
  @apply place-content-center;
  width: 10%;
}
.td-lisenceName {
  @apply py-2.5;
  width: 28%;
}
.td-usedCnt {
  width: 6%;
}
.td-totalCnt {
  width: 8%;
}
.td-purchaseDate {
  width: 12%;
}
.td-startDate,
.td-endDate {
  width: 13%;
}
.td-delBtn {
  width: 6%;
}

.td-p {
  @apply text-gray-900;
}
.delBtn {
  @apply bg-gray-100 shadow py-1 px-1 w-2/3 rounded-md border-0 focus:border-indigo-300;
}

select,
input[type='number'] {
  @apply mt-0 text-xs rounded-md border-gray-300 shadow-sm focus:border-indigo-300 placeholder-gray-400 focus:ring-0;
}
input[type='month'],
input[type='date'] {
  position: relative;
  @apply mt-0 text-xs rounded-md border-gray-300 shadow-sm focus:border-indigo-300 placeholder-gray-400 focus:ring-0;
}
input[type='month']::-webkit-calendar-picker-indicator,
input[type='date']::-webkit-calendar-picker-indicator {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.no-spin::-webkit-inner-spin-button,
.no-spin::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

td.td-usedCnt,
td.td-totalCnt{
  @apply flex justify-end text-sm;
}
td.td-licenseCd {
  @apply text-sm;
}
th.td-usedCnt {
  @apply md:px-0;
}

td.td-delBtn {
  @apply px-0 place-content-center;
}
</style>
