/** 処理区分 */
class ProcType {
  /** 既存 */
  static EXISTING = { name: '既存', code: '0' };
  /** 新規 */
  static NEW = { name: '新規', code: '1' };
  /** 削除 */
  static DELETE = { name: '削除', code: '2' };
  /** 更新 */
  static UPDATE = { name: '更新', code: '3' };
}

module.exports = ProcType;