<template>
  <div class="overlay" v-if="$store.state.isModalOpen">
    <div class="modal-content">
      <div class="py-8 px-2 heightset">
        <p v-for="(msg, index) in $store.state.messages" :key="index"
          :class="{ 
            'text-red-500': !$store.state.isModalInfo,
            'text-gray-700': $store.state.isModalInfo
          }"
          class="mb-2 text-center text-md font-bold whitespace-pre-wrap">
          {{ msg }}
        </p>
        <div class="pt-4 modal-footer flex justify-center"
          :class="{ 
            'flex-row-reverse': $store.state.isModalBtnReverse
          }"
        >
          <button
            @click="ok"
            class="btn-daialog btn-indigo mx-3"
          >
            {{ $store.state.okBtnMsg }}
          </button>
          <button
            v-if="!$store.state.cancelBtnHide"
            @click="cancel"
            class="btn-daialog btn-gray mx-3"
          >
            {{ $store.state.cancelBtnMsg }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Message from '../const/message';

export default {
  name: 'Modal',
  created() {

  },
  methods: {
    ok() {
      this.$store.state.okAction();
      this.close();
    },
    cancel() {
      this.$store.state.cancelAction();
      this.close();
    },
    close() {
      this.$store.commit('setMessages', []);
      this.$store.commit('setOkBtnMsg', Message.BUTTON_TYPE.OK);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CLOSE);
      this.$store.commit('setIsModalOpen', false);
      this.$store.commit('setIsModalInfo', false);
      this.$store.commit('setIsModalBtnReverse', false);
      this.$store.commit('setCancelBtnHide', false);
      this.$store.commit('setOkAction', function(){});
      this.$store.commit('setCancelAction', function(){});
    },
  },
};
</script>

<style scoped>
.btn-daialog {
  @apply font-semibold w-40 rounded py-2 px-6 text-base focus:outline-none focus:ring focus:ring-opacity-50;
}
.heightset {
  @apply overflow-y-auto;
  max-height: calc(100vh - 100px);
}
</style>