import createPersistedState from 'vuex-persistedstate';
import { createStore } from 'vuex';
import SecureLS from 'secure-ls';
let ls = new SecureLS({ encodingType: 'aes', isCompression: true });

const initialState = {
  // ログインユーザの情報
  rToken: '',
  authTime: '',
  tokenExp: '',
  loginId: '',
  displayName: '',
  schoolCd: '',
  maxGrade: 9,
  authority: '',
  isSsoLogin: false,
  isNotChangedPassword: true,
  // モーダル状態管理
  isModalOpen: false,
  isModalInfo: false,
  isModalBtnReverse: false,
  cancelBtnHide: false,
  messages: [],
  okBtnMsg: 'OK',
  cancelBtnMsg: '閉じる',
  okAction: function () { },
  cancelAction: function () { },
  // loading状態管理
  loading: false,
  searchUsageStatsKeys: null,
  searchSchoolKeys: null,
  searchUserKeys: null,
  searchUserLicenseKeys: null,
  searchBookKeys: null,
};

const store = createStore({
  state: initialState,
  mutations: {
    setRToken (state, rToken) {
      state.rToken = rToken;
    },
    setAuthTime (state, authTime) {
      state.authTime = authTime;
    },
    setTokenExp (state, tokenExp) {
      state.tokenExp = tokenExp;
    },
    setLoginId (state, loginId) {
      state.loginId = loginId;
    },
    setDisplayName (state, displayName) {
      state.displayName = displayName;
    },
    setSchoolCd (state, schoolCd) {
      state.schoolCd = schoolCd;
    },
    setMaxGrade (state, maxGrade) {
      state.maxGrade = maxGrade;
    },
    setAuthority (state, authority) {
      state.authority = authority;
    },
    setIsSsoLogin (state, isSsoLogin) {
      state.isSsoLogin = isSsoLogin;
    },
    setIsNotChangedPassword (state, isNotChangedPassword) {
      state.isNotChangedPassword = isNotChangedPassword;
    },

    setIsModalOpen (state, isModalOpen) {
      state.isModalOpen = isModalOpen;
    },
    setIsModalInfo (state, isModalInfo) {
      state.isModalInfo = isModalInfo;
    },
    setIsModalBtnReverse (state, isModalBtnReverse) {
      state.isModalBtnReverse = isModalBtnReverse;
    },
    setCancelBtnHide (state, cancelBtnHide) {
      state.cancelBtnHide = cancelBtnHide;
    },
    setMessages (state, messages) {
      state.messages = messages;
    },
    setOkBtnMsg (state, okBtnMsg) {
      state.okBtnMsg = okBtnMsg;
    },
    setCancelBtnMsg (state, cancelBtnMsg) {
      state.cancelBtnMsg = cancelBtnMsg;
    },
    setOkAction (state, okAction) {
      state.okAction = okAction;
    },
    setCancelAction (state, cancelAction) {
      state.cancelAction = cancelAction;
    },
    setLoading (state, loading) {
      state.loading = loading;
    },
    setSearchUsageStatsKeys (state, data) {
      state.searchUsageStatsKeys = {
        school: data.school,
        grade: data.grade,
        startDate: data.startDate,
        endDate: data.endDate,
      };
    },
    clearSearchUsageStatsKeys (state) {
      state.searchUsageStatsKeys = null;
    },
    setSearchSchoolKeys (state, data) {
      state.searchSchoolKeys = {
        pref: data.pref,
        schoolType: data.schoolType,
        school: data.school,
      };
    },
    clearSearchSchoolKeys (state) {
      state.searchSchoolKeys = null;
    },
    setSearchUserKeys (state, data) {
      state.searchUserKeys = {
        school: data.school,
        baseYear: data.baseYear,
        authority: data.authority,
        grade: data.grade,
        loginId: data.loginId,
      };
    },
    clearSearchUserKeys (state) {
      state.searchUserKeys = null;
    },
    setSearchUserLicenseKeys (state, data) {
      state.searchUserLicenseKeys = {
        loginId: data.loginId,
        baseYear: data.baseYear,
        authGrade: data.authGrade,
      };
    },
    clearSearchUserLicenseKeys (state) {
      state.searchUserLicenseKeys = null;
    },
    setSearchBookKeys (state, data) {
      state.searchBookKeys = {
        subject: data.subject,
        name: data.name,
      };
    },
    clearSearchBookKeys (state) {
      state.searchBookKeys = null;
    },
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      // vuexに「vuex-persistedstate」を追加
      key: 'plusManabi', // localStrageに保持する際のキー
      storage: {
        // secure-lsで暗号化
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
      // storage: window.localStrage, // ローカルで確認する用
    }),
  ],
});

export default store;
