<template>
  <div class="max-w-2xl mx-auto lg:max-w-4xl md:max-w-4xl">
    <Title msg="ユーザ更新" submsg="User Update" />
    <div class="flex justify-center pb-10">
      <form @submit.prevent autocomplete="off" class="pt-7 w-full md:w-2/3">
        <!-- <div class="flex justify-center">
          <div class="w-1/2 h-10 mb-4 btn-long btn-indigo">
            <button @click="refreshClient" class="w-full h-full font-semibold">
              <span class="static mx-2">
                <font-awesome-icon icon="tablet-alt" size="lg" class="absolute" />
                <font-awesome-icon icon="times" size="lg" class="pl-1 pb-1 mr-3" />
                登録端末情報削除
              </span>
            </button>
          </div>
        </div> -->
        <ErrMsgCard :errMsgs="errMsgs" :process="process" />
        <div class="grid grid-cols-1 gap-5">
          <div class="w-1/2">
            <div class="mb-1.5">
              <span class="text-gray-700">{{ loginId.key }}</span>
            </div>
            <input
              type="text"
              v-model="loginId.value"
              class="w-full"
              readonly
            />
          </div>

          <div>
            <div class="mb-1.5">
              <span class="text-gray-700">{{ schoolName.key }}</span>
            </div>
            <input
              type="text"
              v-model="schoolName.value"
              class="w-full"
              readonly
            />
          </div>

          <div>
            <div class="mb-1.5">
              <span class="text-gray-700">{{ displayName.key }}</span>
              <span v-if="!isReadonly" class="form-not-required"
                >任意</span
              >
            </div>
            <input
              type="text"
              v-model="displayName.value"
              maxlength="50"
              class="w-full"
              :readonly="isReadonly"
            />
          </div>

          <div>
            <div class="mb-1.5">
              <span class="text-gray-700">{{ emailAddress.key }}</span>
              <span v-if="!isReadonly" class="form-not-required"
                >任意</span
              >
            </div>
            <input
              type="email"
              v-model="emailAddress.value"
              maxlength="256"
              class="w-full"
              :readonly="isReadonly"
            />
          </div>

          <div v-if="!isReadonly || (isUpdaterTeacher && isStudent)" class="w-1/2">
            <div class="mb-1.5">
              <span class="text-gray-700">{{ newPassword.key }}</span>
              <span class="form-not-required">任意</span>
            </div>
            <p class="text-sm text-red-500">
              更新する場合のみ入力してください。
            </p>
            <input
              :type="getPassInputType(isNewPassVisible)"
              placeholder="数字のみ8桁で入力"
              v-model="newPassword.value"
              maxlength="8"
              class="w-full"
              autocomplete="new-password"
            />
            <span class="pass-mask-icon" v-if="newPassword.value" @click="newPassVisibleToggle">
              <font-awesome-icon v-if="isNewPassVisible" icon="eye-slash" size="lg" />
              <font-awesome-icon v-else icon="eye" size="lg" />
            </span>
          </div>

          <div v-if="!isReadonly || (isUpdaterTeacher && isStudent)" class="w-1/2">
            <div class="mb-1.5">
              <span class="text-gray-700">{{ newPasswordConfirm.key }}</span>
              <span class="form-not-required">任意</span>
            </div>
            <p class="text-sm text-red-500">
              更新する場合のみ入力してください。
            </p>
            <input
              :type="getPassInputType(isNewPassConfirmVisible)"
              placeholder="数字のみ8桁で入力"
              v-model="newPasswordConfirm.value"
              maxlength="8"
              class="w-full"
              autocomplete="new-password"
            />
            <span class="pass-mask-icon" v-if="newPasswordConfirm.value" @click="newPassConfirmVisibleToggle">
              <font-awesome-icon v-if="isNewPassConfirmVisible" icon="eye-slash" size="lg" />
              <font-awesome-icon v-else icon="eye" size="lg" />
            </span>
          </div>

          <!-- 更新者のユーザ区分が教職員ではなく、
          更新対象ユーザのユーザ区分が学習者の場合で、状態が利用中または利用不可の場合のみ表示 -->
          <div
            v-if="isStudent && isNotBeforeUse && !isReadonly"
            class="w-1/2"
          >
            <div class="mb-1.5">
              <span class="text-gray-700">{{ status.key }}</span>
              <span class="form-required">必須</span>
            </div>
            <div class="my-2 flex">
              <input
                id="usable"
                v-model="status.value"
                class="
                  radio-button:border-indigo-800
                  radio-button:text-white
                  radio-button:bg-indigo-800
                  hidden
                "
                name="status"
                type="radio"
                :value="getUsableCd"
              />
              <label for="usable" class="w-1/2 radio-label">
                {{ getUsableName }}
              </label>
              <input
                id="unusable"
                v-model="status.value"
                class="
                  radio-button:border-indigo-800
                  radio-button:text-white
                  radio-button:bg-indigo-800
                  hidden
                "
                name="status"
                type="radio"
                :value="getUnusableCd"
              />
              <label for="unusable" class="w-1/2 radio-label">
                {{ getUnusableName }}
              </label>
            </div>
          </div>

          <div>
            <div class="mb-1.5">
              <span class="text-gray-700">{{ remarks.key }}</span>
              <span v-if="!isReadonly" class="form-not-required"
                >任意</span
              >
            </div>
            <textarea
              v-model="remarks.value"
              maxlength="200"
              class="w-full placeholder-gray-400"
              rows="3"
              :readonly="isReadonly"
            ></textarea>
          </div>

          <div class="flex justify-center mt-8">
            <button
              @click="$router.go(-1)"
              type="button"
              name="back-button"
              class="button btn-gray w-full"
            >
              戻る
            </button>
            <button
              v-if="!isReadonly || (isUpdaterTeacher && isStudent)"
              v-on:click="update"
              type="button"
              name="button"
              class="button btn-indigo w-full ml-5 md:ml-10"
            >
              更新
            </button>
            <button
              v-if="!isReadonly"
              v-on:click="remove"
              type="button"
              name="button"
              class="button btn-indigo w-full ml-5 md:ml-10"
            >
              削除
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { findUser, updateUser, deleteUsers } from '../api';
import { rsaEncrypto } from '../crypto';
import Title from '../components/TheTitle.vue';
import ErrMsgCard from '../components/ErrorMssageCard.vue';
import UserAuth from '../const/userAuthority';
import UserStatus from '../const/userStatus';
import Message from '../const/message';
import Validation from '../utils/validation';
import Authorize from '../utils/authorize';

export default {
  name: 'UserManageUpdate',
  components: {
    Title,
    ErrMsgCard,
  },
  data() {
    return {
      loginId: { key: 'ログインID', value: this.$route.params.loginId },
      schoolCd: { key: '学校コード', value: this.$route.params.schoolCd },
      schoolName: { key: '所属学校名', value: '' },
      displayName: { key: '表示名', value: '' },
      emailAddress: { key: 'メールアドレス', value: '' },
      newPassword: { key: 'パスワード', value: '' },
      newPasswordConfirm: { key: 'パスワード（確認）', value: '' },
      status: { key: '状態', value: '' },
      remarks: { key: '備考', value: '' },
      authority: { key: 'ユーザ区分', value: '' },
      isNewPassVisible: false,
      isNewPassConfirmVisible: false,

      errMsgs: [],
      process: Message.PROC_TYPE.UPDATE,
      isUnsent: true
    };
  },
  created: function () {
    // 権限チェック
    if (
      (this.$store.state.authority === UserAuth.TEACHER.code &&
        this.$store.state.schoolCd === this.$route.params.schoolCd) ||
      (this.$store.state.authority === UserAuth.SCHOOL_ADMIN.code &&
        this.$store.state.schoolCd === this.$route.params.schoolCd) ||
      this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code
    ) {
      // 更新対象のユーザ情報を取得
      let self = this;
      findUser(
        function (rspBody) {
          if (rspBody.result.code === 0) {
            let user = rspBody.detail;
            self.loginId.value = user.loginId;
            self.schoolCd.value = user.schoolCd;
            self.schoolName.value = user.schoolName;
            self.displayName.value = user.displayName;
            self.emailAddress.value = user.mail;
            self.status.value = user.userStatus;
            self.remarks.value = user.remarks;
            self.authority.value = user.authority;
          } else {
            // ユーザが存在しない場合、ポップアップを表示
            self.$store.commit('setMessages', [
              Message.generateMessage(Message.NOT_EXIST, [Message.USER.NAME]),
            ]);
            self.$store.commit('setOkAction', function () {
              self.toUserList();
            });
            self.$store.commit('setIsModalInfo', true);
            self.$store.commit('setCancelBtnHide', true);
            self.$store.commit('setIsModalOpen', true);
          }
        },
        this.schoolCd.value,
        this.loginId.value,
        this.$route.path
      );
    } else {
      // エラーページに遷移
      this.$router.push({
        name: 'not-found',
        params: { catchAll: 'not-found' },
      });
      // 処理強制終了
      return false;
    }
  },
  methods: {
    // 端末制御は行わなくなったため、以下コメントアウト
    // refreshClient() {
    //   let self = this;
    //   this.process = Message.PROC_TYPE.DELETE;
    //   // 削除確認ポップアップを表示
    //   this.$store.commit('setMessages', [
    //     Message.generateMessage(Message.CONFIRM, [this.process]),
    //   ]);
    //   this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
    //   this.$store.commit('setIsModalOpen', true);
    //   this.$store.commit('setOkAction', function () {
    //     refreshClient(function (rspBody) {
    //       if (rspBody.result.code === 0) {
    //         // 成功した場合、成功ポップアップを表示
    //         self.$store.commit('setMessages', [
    //           Message.generateMessage(Message.COMPLETED, [
    //             Message.PROC_TYPE.DELETE,
    //           ]),
    //         ]);
    //         self.$store.commit('setOkAction', function () {
    //           // self.toUserList();
    //         });
    //         self.$store.commit('setIsModalInfo', true);
    //         self.$store.commit('setCancelBtnHide', true);
    //         self.$store.commit('setIsModalOpen', true);
    //       }
    //     }, self.loginId.value, self.$route.path);
    //   });
    // },
    update() {
      // パスワードをマスキング
      this.isNewPassVisible = false;
      this.isNewPassConfirmVisible = false;

      let self = this;
      this.process = Message.PROC_TYPE.UPDATE;
      // 更新確認ポップアップを表示
      this.$store.commit('setMessages', [
        Message.generateMessage(Message.CONFIRM, [this.process]),
      ]);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setIsModalOpen', true);
      this.$store.commit('setOkAction', async function () {
        // バリデーション定義作成
        let validationArray = [];
        // バリデーションチェック実施
        self.errMsgs = validationArray.concat(
          Validation.isInteger(self.newPassword),
          Validation.fixedLength(self.newPassword, 8),
          Validation.isInteger(self.newPasswordConfirm),
          Validation.fixedLength(self.newPasswordConfirm, 8),
          Validation.requiredInput(self.status),
          Validation.isMailFormat(self.emailAddress)
        );

        // エラーメッセージがある場合は処理を中断
        if (self.errMsgs.length) return false;

        if (Date.now() / 1000 > self.$store.state.tokenExp){
          if (!await Authorize.isAuth()) {
            // 認証失敗：ログイン画面へ遷移
            self.$router.push({ name: 'login', query: { redirect: self.$route.path } });
            return false;
          }
        }

        // パスワードの暗号化(未入力の場合は暗号化しない)
        const encryptoPass = self.newPassword.value
          ? rsaEncrypto(self.newPassword.value)
          : '';
        const encryptoConfirmPass = self.newPasswordConfirm.value
          ? rsaEncrypto(self.newPasswordConfirm.value)
          : '';

        updateUser(
          function (rspBody) {
            if (rspBody.result.code === 0) {
              self.isUnsent = false;
              // 成功した場合、ユーザ一覧に遷移
              self.$store.commit('setMessages', [
                Message.generateMessage(Message.COMPLETED, [
                  Message.PROC_TYPE.UPDATE,
                ]),
              ]);
              self.$store.commit('setOkAction', function () {
                self.toUserList();
              });
              self.$store.commit('setIsModalInfo', true);
              self.$store.commit('setCancelBtnHide', true);
              self.$store.commit('setIsModalOpen', true);
            } else {
              // 失敗した場合、エラー内容を表示
              self.errMsgs = rspBody.result.messages;
            }
          },
          self.schoolCd.value,
          self.loginId.value,
          self.displayName.value,
          self.emailAddress.value,
          encryptoPass,
          encryptoConfirmPass,
          self.status.value,
          self.remarks.value,
          self.$route.path
        );
      });
    },
    remove() {
      let self = this;
      this.process = Message.PROC_TYPE.DELETE;
      // 削除確認ポップアップを表示
      this.$store.commit('setMessages', [
        Message.generateMessage(Message.CONFIRM, [this.process]),
      ]);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setIsModalOpen', true);
      this.$store.commit('setOkAction', async function () {
        if (Date.now() / 1000 > self.$store.state.tokenExp){
          if (!await Authorize.isAuth()) {
            // 認証失敗：ログイン画面へ遷移
            self.$router.push({ name: 'login', query: { redirect: self.$route.path } });
            return false;
          }
        }
        deleteUsers(
          function (rspBody) {
            if (rspBody.result.code === 0) {
              self.isUnsent = false;
              // 成功した場合、ユーザ一覧に遷移
              self.$store.commit('setMessages', [
                Message.generateMessage(Message.COMPLETED, [
                  Message.PROC_TYPE.DELETE,
                ]),
              ]);
              self.$store.commit('setOkAction', function () {
                self.toUserList();
              });
              self.$store.commit('setIsModalInfo', true);
              self.$store.commit('setCancelBtnHide', true);
              self.$store.commit('setIsModalOpen', true);
            } else {
              // 失敗した場合、エラー内容を表示
              self.errMsgs = rspBody.result.messages;
            }
          },
          self.schoolCd.value,
          [self.loginId.value],
          self.$route.path
        );
      });
    },
    toUserList() {
      this.$router.push({
        name: 'user-manage',
      });
    },
    newPassVisibleToggle() {
      this.isNewPassVisible = !this.isNewPassVisible;
    },
    newPassConfirmVisibleToggle() {
      this.isNewPassConfirmVisible = !this.isNewPassConfirmVisible;
    },
    getPassInputType (isPassVisible) {
      return isPassVisible ? 'text' : 'password';
    }
  },
  computed: {
    isStudent() {
      return this.authority.value === UserAuth.STUDENT.code;
    },
    isNotBeforeUse() {
      return (
        this.status.value === UserStatus.USABLE.code ||
        this.status.value === UserStatus.UNUSABLE.code
      );
    },
    isUpdaterTeacher() {
      return this.$store.state.authority === UserAuth.TEACHER.code;
    },
    isReadonly(){
      if (this.isUpdaterTeacher){
        // 教職員の場合、読み取り専用(※パスワード更新を除く)
        return true;
      } else if (this.$store.state.authority === UserAuth.SCHOOL_ADMIN.code) {
        // 学校管理者の場合、システム管理者のユーザは読み取り専用
        return this.authority.value === UserAuth.SYSTEM_ADMIN.code
      } else {
        // システム管理者の場合、読み取り専用のユーザなし
        return false;
      }
    },
    getUsableCd() {
      return UserStatus.USABLE.code;
    },
    getUsableName() {
      return UserStatus.USABLE.name;
    },
    getUnusableCd() {
      return UserStatus.UNUSABLE.code;
    },
    getUnusableName() {
      return UserStatus.UNUSABLE.name;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (
      this.isUnsent 
        && (!this.isReadonly || (this.isUpdaterTeacher && this.isStudent)) 
        && to.name != 'maintenance'
    ) {
      this.$store.commit('setMessages', [Message.INPUT_VALUE_CLEAR]);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setIsModalOpen', true);
      this.$store.commit('setOkAction', function () {
        next();
      });
      this.$store.commit('setCancelAction', function () {
        next(false);
      });
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.button {
  @apply font-semibold w-32 rounded py-2 px-2 focus:outline-none focus:ring focus:ring-opacity-50;
}
.btn-long {
  @apply rounded focus:outline-none focus:ring focus:ring-opacity-50;
}
input:read-only,
textarea:read-only {
  @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-200;
}
input,
select,
textarea {
  @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}
select,
textarea {
  @apply mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-300;
}
</style>
