<template>
  <div class="static max-w-2xl mx-auto md:max-w-5xl">
    <Title msg="ユーザライセンス割り当て" submsg="Assign License to User" />
    <!-- CSV取り込み中にボタン操作させないためのレイヤー -->
    <div
      v-if="isCsvImporting === true"
      class="
        z-20
        absolute
        bg-gray-900 bg-opacity-70
        top-0
        left-0
        w-full
        h-full
        positionCenter
      "
    >
      <div class="modal-content">
        <div class="py-8 px-2 heightset">
          <div class="mb-2 text-center text-md font-bold whitespace-pre-wrap">
            CSV取込データ反映中のため操作できません。
            <p class="mt-2">しばらくお待ちください。</p>
          </div>
          <div class="pt-4 modal-footer flex justify-center">
            <button
              onclick="location.reload()"
              name="reroad"
              class="btn btn-indigo"
            >
              再読み込み
            </button>
          </div>
        </div>
      </div>
    </div>
    <Form ref="form" @search="search" />
    <Table @register="register" :userLicenses="userLicenses" />
  </div>
</template>

<script>
import {
  findUsersCsvStatus,
  searchUserLicenses,
  updateUserLicenses,
} from '../api';
import Title from '../components/TheTitle.vue';
import Form from '../components/UserLicenseManageForm.vue';
import Table from '../components/UserLicenseManageTable.vue';
import Message from '../const/message';
import UserAuth from '../const/userAuthority';
import FileStatus from '../const/fileStatus';
import Authorize from '../utils/authorize';

export default {
  name: 'BookManageUser',
  components: {
    Title,
    Form,
    Table,
  },
  data() {
    return {
      schoolCd: this.$route.params.schoolCd,
      shoolInfo: {},
      baseYear: 0,
      loginId: '',
      userLicenses: {},
      isCsvImporting: false,
      csvErrorSchoolCd: '',
      isUnsent: false
    };
  },
  created: async function () {
    // 権限チェック
    if (
      (this.$store.state.authority === UserAuth.SCHOOL_ADMIN.code &&
        this.$store.state.schoolCd === this.$route.params.schoolCd) ||
      this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code
    ) {
      // パスパラメータの学校について、CSVの取込状況を取得
      const rspBody = await findUsersCsvStatus(this.$route.params.schoolCd, this.$route.path);
      if (!rspBody.detail) {
        // detailがない場合、取込中フラグと取込エラーフラグをOFF
        this.isCsvImporting = false;
      } else if (rspBody.detail.fileStatus === FileStatus.IMPORT_WAIT.code) {
        // 取込中の場合、取込中フラグをON
        this.isCsvImporting = true;
      } else if (rspBody.detail.fileStatus === FileStatus.IMPORT_ERROR.code) {
        // 取込エラーの場合、取込中フラグをOFF
        this.isCsvImporting = false;
        this.$store.commit('setMessages', [
          'ユーザCSV取込にてエラーが発生しております。',
          '登録・更新に失敗したデータがありますが、',
          'ユーザライセンス割り当ては可能です。',
          ' ',
          '※エラーの詳細はユーザ一覧からダウンロードできます。',
        ]);
        this.$store.commit('setCancelBtnHide', true);
        this.$store.commit('setIsModalOpen', true);
      }
    } else {
      // エラーページに遷移
      this.$router.push({
        name: 'not-found',
        params: { catchAll: 'not-found' },
      });
      // 処理強制終了
      return false;
    }
  },
  methods: {
    /** ユーザライセンス検索 */
    async search(baseYear, auth, grade, loginId) {
      if (Date.now() / 1000 > this.$store.state.tokenExp){
        if (!await Authorize.isAuth()) {
          // 認証失敗：ログイン画面へ遷移
          this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
          return false;
        }
      }
      let self = this;
      searchUserLicenses(
        function (rspBody) {
          if (rspBody.result.code === 0) {
            self.isUnsent = true;
            self.userLicenses = rspBody.detail;
          } else {
            // 検索に失敗した場合、エラーメッセージをポップアップにセットして表示
            self.$store.commit('setMessages', rspBody.result.messages);
            self.$store.commit('setCancelBtnHide', true);
            self.$store.commit('setIsModalOpen', true);
          }
        },
        this.schoolCd,
        baseYear,
        auth,
        grade,
        loginId,
        this.$route.path
      );
    },
    /** ユーザライセンス割り当て */
    async register(registerList) {
      if (Date.now() / 1000 > this.$store.state.tokenExp){
        if (!await Authorize.isAuth()) {
          // 認証失敗：ログイン画面へ遷移
          this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
          return false;
        }
      }
      let self = this;
      updateUserLicenses(
        function (rspBody) {
          if (rspBody.result.code === 0) {
            self.isUnsent = false;
            // 登録成功の場合、ポップアップを表示し、OKボタン押下で一覧に遷移
            self.$store.commit('setMessages', [
              Message.generateMessage(Message.COMPLETED, [
                Message.PROC_TYPE.UPDATE,
              ]),
            ]);

            self.$store.commit('setOkAction', function () {
              self.$refs.form.search();
            });
            self.$store.commit('setIsModalInfo', true);
            self.$store.commit('setCancelBtnHide', true);
            self.$store.commit('setIsModalOpen', true);
          } else {
            // 失敗した場合、エラーメッセージをポップアップにセットして表示
            self.$store.commit('setMessages', rspBody.result.messages);
            self.$store.commit('setCancelBtnHide', true);
            self.$store.commit('setIsModalOpen', true);
          }
        },
        this.schoolCd,
        registerList,
        this.$route.path
      );
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isUnsent && to.name != 'maintenance') {
      this.$store.commit('setMessages', [Message.INPUT_VALUE_CLEAR]);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setIsModalOpen', true);
      this.$store.commit('setOkAction', function () {
        next();
      });
      this.$store.commit('setCancelAction', function () {
        next(false);
      });
    } else {
      next();
    }
  },
};
</script>

<style scoped>
input:read-only {
  @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50 bg-gray-200;
}
input,
select,
textarea {
  @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}
select,
textarea {
  @apply mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-300;
}
.positionCenter {
  /* 画面の中央に要素を表示させる設定 */
  display: flex;
  align-items: center;
  justify-content: center;
}
.heightset {
  @apply overflow-y-auto;
  max-height: calc(100vh - 100px);
}
</style>
