import { createApp } from 'vue'
import Top from './Top.vue'
import store from './store'
import './index.css'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Multiselect from '@suadelabs/vue3-multiselect'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VTooltip from 'v-tooltip'

library.add(fas)

const app = createApp(Top)
app.use(router)
app.use(store)
app.use(VueAxios, axios)
app.use(VTooltip)
app.component('font-awesome-icon', FontAwesomeIcon)
app.component('multiselect', Multiselect)

app.mount('#app')
