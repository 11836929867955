<template>
  <div class="max-w-5xl mx-auto">
    <Title msg="電子教材一覧" submsg="Book List" />
    <div class="flex my-3">
      <router-link to="/manage/book/register" id="book-register">
        <button class="btn btn-indigo mr-4">新規登録</button>
      </router-link>
    </div>
    <Form ref="form" :handleSearch="search" :subjectList="subjects" />
    <div class="mt-2">
      <button
        @click="batchDelete"
        v-tooltip="{content: 'チェックボックスを選択して一括削除'}"
        class="w-32 text-sm font-semibold text-white bg-gray-500 border border-gray-300 shadow py-1.5 px-5 rounded"
      >
        <font-awesome-icon icon="trash-alt" class="mr-1" />
        一括削除
      </button>
    </div>
    <Table
      :textbooks="searchedTextbooks"
      :subjects="subjects"
      @delete="getDeleteLicenseCds"
    />
  </div>
</template>

<script>
import { searchTextbooks, searchSubjects, deleteTextbooks } from '../api';
import Title from '../components/TheTitle.vue';
import Form from '../components/BookListForm.vue';
import Table from '../components/BookListTable.vue';
import Message from '../const/message';
import UserAuth from '../const/userAuthority';
import Authorize from '../utils/authorize';

export default {
  name: 'BookList',
  components: {
    Title,
    Form,
    Table,
  },
  data() {
    return {
      searchedTextbooks: [],
      subjects: [],
      deleteLicenseCds: [],
    };
  },
  async created() {
    // 権限チェック
    if (this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code) {
      // 教科コードを全件取得
      const rspBody = await searchSubjects(this.$route.path);
      if(rspBody.result.code === 0){
        this.subjects = rspBody.detail.subjects;
      }
    } else {
      // エラーページに遷移
      this.$router.push({
        name: 'not-found',
        params: { catchAll: 'not-found' },
      });
      // 処理強制終了
      return false;
    }
  },
  methods: {
    async search(subjectCd, name) {
      if (Date.now() / 1000 > this.$store.state.tokenExp){
        if (!await Authorize.isAuth()) {
          // 認証失敗：ログイン画面へ遷移
          this.$router.push({ name: 'login', query: { redirect: this.$route.path } });
          return false;
        }
      }
      let self = this;
      searchTextbooks(
        function (rspBody) {
          if (rspBody.result.messages.length) {
            self.searchedTextbooks = [];
            // 失敗した場合、エラーメッセージをポップアップにセットして表示
            self.$store.commit('setMessages', rspBody.result.messages);
            self.$store.commit('setCancelBtnHide', true);
            self.$store.commit('setIsModalOpen', true);
          } else {
            // 成功した場合、取得したレコードを渡す
            self.searchedTextbooks = rspBody.detail;
          }
        }, 
        this.$route.path,
        subjectCd,
        name
      );
    },
    batchDelete() {
      let self = this;
      const deleteCnt = self.deleteLicenseCds.length;
      if (deleteCnt === 0) {
        // 削除対象が存在しない場合はエラーメッセージを表示
        self.$store.commit('setMessages', [
          Message.generateMessage(Message.DELETE_NOT_SELECT, [
            Message.PROC_TYPE.DELETE,
            Message.TEXTBOOK.NAME,
          ]),
        ]);
        self.$store.commit('setCancelBtnHide', true);
        self.$store.commit('setIsModalOpen', true);
        return false;
      }
      // 削除するライセンスコードの配列をさらに5件ごとに配列に格納
      let deleteIdsSets = [];

      for (let i = 0; i < deleteCnt; i = i + 5) {
        const sliceLength = i + 5 <= deleteCnt ? i + 5 : deleteCnt;
        deleteIdsSets.push(self.deleteLicenseCds.slice(i, sliceLength));
      }
      // 5件ごとに配列に格納されたライセンスコードを、それぞれカンマ区切りで文字列結合
      let deleteLicenseCdsStringSets = [];
      deleteIdsSets.forEach((deleteIdsSet) => {
        deleteLicenseCdsStringSets.push(deleteIdsSet.join(', '));
      });
      // 警告メッセージの初期化
      let warnMsgs = [];
      warnMsgs.push(
        Message.generateMessage(Message.DELETE_WARN, [
          deleteCnt,
          Message.PROC_TYPE.DELETE,
        ])
      );
      // 警告メッセージに削除するライセンスコードのリストを追加
      warnMsgs = warnMsgs.concat(deleteLicenseCdsStringSets);

      // 削除確認ポップアップを表示する
      this.$store.commit('setMessages', warnMsgs);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setIsModalOpen', true);
      this.$store.commit('setOkAction', async function () {
        if (Date.now() / 1000 > self.$store.state.tokenExp){
          if (!await Authorize.isAuth()) {
            // 認証失敗：ログイン画面へ遷移
            self.$router.push({ name: 'login', query: { redirect: self.$route.path } });
            return false;
          }
        }
        deleteTextbooks(function (rspBody) {
          if (rspBody.result.code === 0) {
            // 成功した場合、削除完了メッセージをポップアップにセット
            self.$store.commit('setMessages', [
              Message.generateMessage(Message.COMPLETED, [
                Message.PROC_TYPE.DELETE,
              ]),
            ]);
            self.$store.commit('setIsModalInfo', true);
          } else {
            // 失敗した場合、エラーメッセージをポップアップにセット
            self.$store.commit('setMessages', rspBody.result.messages);
          }
          // OK押下後、フォームから検索条件を取得後、フォーム内で検索API呼び出し
          self.$store.commit('setOkAction', function () {
            self.$refs.form.search();
          });
          // ポップアップを表示
          self.$store.commit('setCancelBtnHide', true);
          self.$store.commit('setIsModalOpen', true);
        }, self.deleteLicenseCds, self.$route.path);
      });
    },
    getDeleteLicenseCds(array) {
      this.deleteLicenseCds = array;
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.deleteLicenseCds.length && to.name != 'maintenance') {
      this.$store.commit('setMessages', [Message.TRANSITION_WARN]);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setIsModalOpen', true);
      this.$store.commit('setOkAction', function () {
        next();
      });
    } else {
      next();
    }
  },
};
</script>
