<template>
  <div></div>
</template>

<script>
import UserAuth from '../const/userAuthority';

export default {
  name: 'Routing',
  created: function () {
    const authority = this.$store.state.authority;
    // ユーザ区分によって遷移先を振り分け
    if (authority === UserAuth.SYSTEM_ADMIN.code) {
      // システム管理者の場合は学校一覧へ遷移
      this.$router.push({ name: 'school-manage' });
    } else if (authority === UserAuth.SCHOOL_ADMIN.code) {
      // 学校管理者の場合はユーザ一覧へ遷移
      this.$router.push({
        name: 'user-manage',
        params: { schoolCd: this.$store.state.schoolCd },
      });
    } else {
      // 上記以外のユーザの場合は本棚へ遷移
      this.$router.push({ name: 'bookshelf' });
    }
  }
};
</script>
