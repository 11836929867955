class Message {
  /** 共通：{0}してもよろしいですか？ */
  static CONFIRM = '{0}してもよろしいですか？';
  /** 共通：{0}が完了しました。 */
  static COMPLETED = '{0}が完了しました。';
  /** 共通：{0}が存在しません */
  static NOT_EXIST = '{0}が存在しません';
  /** 共通：選択されていません */
  static NOT_SELECT = '選択されていません';
  /** 共通：以下の{0}件を{1}します。よろしいですか？ */
  static DELETE_WARN = '以下の{0}件を{1}します。よろしいですか？';
  /** 共通：チェックボックスの値はリセットされますが、よろしいですか？ */
  static TRANSITION_WARN =
    'チェックボックスの値はリセットされますが、よろしいですか？';
  /** 共通：入力値はクリアされますが、よろしいですか？ */
  static INPUT_VALUE_CLEAR = '入力値はクリアされますが、よろしいですか？';
  /** 共通：{0}対象の{1}を選択してください。 */
  static DELETE_NOT_SELECT = '{0}対象の{1}を選択してください。';
  /** 共通：データが存在しません */
  static MULTI_NOT_RESULT = 'データが存在しません';
  /** 共通：データ取得中... */
  static MULTI_NOT_OPTIONS = 'データ取得中...';
  /** 共通：一度に{0}できる件数は{1}件までです。 */
  static LIMIT_OVER = '一度に{0}できる件数は {1} 件までです。';
  /** 共通：{0} に {1} は選択できません。 */
  static NON_SELECTABLE = '{0} に {1} は選択できません。';
  /** 共通：ZIPファイルをアップロード済みです */
  static EXIST_ZIP = 'ZIPファイルをアップロード済みです';

  /** 共通：処理区分 */
  static PROC_TYPE = {
    /** 登録 */
    REGISTER: '登録',
    /** 更新 */
    UPDATE: '更新',
    /** 削除 */
    DELETE: '削除',
    /** アップロード */
    UPLOAD: 'アップロード',
  };

  /** 共通：ボタン種類 */
  static BUTTON_TYPE = {
    /** OK */
    OK: 'OK',
    /** キャンセル */
    CANCEL: 'キャンセル',
    /** 閉じる */
    CLOSE: '閉じる',
  };

  /** パスワード変更 */
  static UPDATE_PASSWORD = {
    /** 8文字以上16文字以内, 半角数字と半角英字をそれぞれ含む記号なしの形式 */
    RULE: [
      '8文字以上16文字以内',
      '半角数字と半角英字をそれぞれ含む記号なしの形式',
    ],
  };

  /** 画面別閲覧数一覧 */
  static USAGE_STATS = {
    /** 開始日は終了日より前の日付を指定してください。 */
    STARTDATE_HAS_PASSED: '開始日は終了日より前の日付を指定してください。',
    /** 開始日は本日から過去2年以内の日付を指定してください。 */
    STARTDATE_IS_OLD: '開始日は本日から過去2年以内の日付を指定してください。',
    /** 終了日は開始日から2週間以内の日付を指定してください。 */
    ENDDATE_HAS_PASSED: '終了日は開始日から2週間以内の日付を指定してください。',
  };

  /** ライセンス割り当て */
  static SCHOOL_LICENSES = {
    /** 開始年月日は終了年月日より前の日付を指定してください。 */
    STARTDATE_HAS_PASSED:
      '開始年月日は終了年月日より前の日付を指定してください。',
    /** 終了日が過ぎたライセンスは登録できません。 */
    ENDDATE_EXPIRED: '終了年月日が過ぎたライセンスは登録できません。',
    /** ライセンス使用数以下のライセンス総数は指定できません。 */
    LICENSE_COUNT_OVER: 'ライセンス使用数以下のライセンス総数は指定できません。',
    /** 以下の警告を確認し、問題なければ「OK」を押して登録してください。 */
    DESCRIPTION_WARN:
      '以下の警告を確認し、問題なければ「OK」を押して登録してください。',
  };

  /** 学校マスタ */
  static SCHOOL = {
    /** 学校 */
    NAME: '学校',
    /** ページごとの表示件数 */
    DISPLAY_NUMBER: 50,
  };

  /** ユーザマスタ */
  static USER = {
    /** ユーザ */
    NAME: 'ユーザ',
    /** ユーザ情報カード出力 */
    DOWNLOAD_USERS_CARD: {
      /** カード出力 */
      NAME: 'カード出力',
      /** カード出力上限 */
      LIMIT: 10,
    },
    /** ページごとの表示件数 */
    DISPLAY_NUMBER: 50,
    /** 削除上限 */
    DELETE_LIMIT: 500,
  };

  /** 電子教材マスタ */
  static TEXTBOOK = {
    /** 電子教材 */
    NAME: '電子教材',
    /** 電子教材のアップロードに失敗しました。 */
    UPLOAD_FAIL: '電子教材のアップロードに失敗しました。',
    /** 一定時間経過後も参照できない場合は、再度アップロードを行ってください。 */
    NOW_UPLOADING:
      '一定時間経過後も参照できない場合は、再度アップロードを行ってください。',
  };

  /** SSOエラーメッセージ */
  static SSO_ERROR = {
    /** 同時ログイン制御 */
    CLIENT_FAILED: {
      name: 'CLIENT_FAILED',
      message:
        '登録されている利用端末と一致しません。利用端末を変更する場合は管理者にお問い合わせください。',
    },
    /** メールアドレス存在しない */
    NOT_FOUND_EMAIL: {
      name: 'NOT_FOUND_EMAIL',
      message:
        'このアカウントは＋まなびに登録されていないため、利用できません。管理者にお問い合わせください。',
    },
    /** ユーザステータスエラー */
    USER_UNUSABLE: {
      name: 'USER_UNUSABLE',
      message:
        'このアカウントは利用できません。利用したい場合は管理者にお問い合わせください。',
    },
    /** 処理失敗 */
    PROCESS_FAILED: {
    name: 'PROCESS_FAILED',
    message:
      '処理中にエラーが発生しました。もう一度処理をやり直してください。\n※このエラーが繰り返し表示される場合はシステム管理者にお問い合わせください。'
    },
  };

  /**
   * メッセージを生成する
   * @param {String} message 置換前の{0}が含まれたメッセージ
   * @param {Array} params 置換する文字列の配列
   * @returns 置換されたメッセージ
   */
  static generateMessage(message, params) {
    let result = message;
    for (let i = 0; i < params.length; i++) {
      // 置換される文字列を生成
      let index = '{' + i + '}';
      result = result.split(index).join(params[i]);
    }
    return result;
  }
}
module.exports = Message;
