<template>
  <div class="max-w-2xl mx-auto lg:max-w-4xl md:max-w-4xl">
    <Title msg="学校登録" submsg="School Register" />
    <div class="flex justify-center pb-10">
      <form @submit.prevent class="pt-7 w-full md:w-2/3">
        <ErrMsgCard :errMsgs="errMsgs" :process="process" />
        <div class="grid grid-cols-1 gap-5">
          <div class="w-1/2">
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ schoolCd.key }}</span>
              <span class="form-required">必須</span>
            </div>

            <input
              type="text"
              placeholder="例：1234567"
              v-model="schoolCd.value"
              maxlength="7"
              class="w-full"
            />
          </div>

          <div class="w-1/2">
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ schoolType.key }}</span>
              <span class="form-required">必須</span>
            </div>
            <select
              v-model="schoolType.value"
              class="h-12 w-full py-2 px-4 pr-10"
            >
              <option
                :value="{ name: '', code: '' }"
                disabled
                selected
                style="display: none"
              >
                選択してください
              </option>
              <option
                v-for="st in schoolTypeList"
                :key="st.code"
                v-bind:value="{ name: st.name, code: st.code }"
              >
                {{ st.name }}
              </option>
            </select>
          </div>

          <div>
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ schoolName.key }}</span>
              <span class="form-required">必須</span>
            </div>
            <input
              type="text"
              placeholder="例：まなび中学校"
              v-model="schoolName.value"
              maxlength="50"
              class="w-full"
            />
          </div>

          <div class="w-1/2">
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ zipCd.key }}</span>
              <span class="form-not-required">任意</span>
            </div>
            <input
              type="text"
              placeholder="例：558-0041"
              v-model="zipCd.value"
              maxlength="8"
              class="w-full"
            />
          </div>

          <div class="w-1/2">
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ pref.key }}</span>
              <span class="form-required">必須</span>
            </div>
            <multiselect
              v-model="pref.value"
              :options="prefList"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="入力、選択してください"
              selectedLabel="選択済み"
              selectLabel="Enterを押して選択"
              deselectLabel="Enterを押して選択解除"
              label="name"
              track-by="name"
            >
              <template v-slot:noResult>
                {{noResult}}
              </template>
              <template v-slot:noOptions>
                {{noOptions}}
              </template>
            </multiselect>
          </div>

          <div>
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ address.key }}</span>
              <span class="form-not-required">任意</span>
            </div>
            <input
              type="text"
              placeholder="例：大阪府大阪市住吉区南住吉４－７－５"
              v-model="address.value"
              maxlength="250"
              class="w-full"
            />
          </div>

          <div class="w-1/2">
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ phoneNum.key }}</span>
              <span class="form-not-required">任意</span>
            </div>
            <input
              type="text"
              placeholder="例：123-456-7890"
              v-model="phoneNum.value"
              maxlength="13"
              class="w-full"
            />
          </div>

          <div>
            <div class="flex items-center mb-1.5">
              <span class="text-gray-700">{{ remarks.key }}</span>
              <span class="form-not-required">任意</span>
            </div>
            <textarea
              v-model="remarks.value"
              maxlength="200"
              class="w-full"
              rows="3"
            ></textarea>
          </div>

          <div class="flex justify-center mt-8">
            <button
              @click="$router.go(-1)"
              type="button"
              name="back-button"
              class="button btn-gray mr-5 md:mr-10"
            >
              戻る
            </button>
            <button
              v-on:click="register"
              type="button"
              name="button"
              class="button btn-indigo"
            >
              登録
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { registerSchool, searchSchoolTypes } from '../api';
import Title from '../components/TheTitle.vue';
import ErrMsgCard from '../components/ErrorMssageCard.vue';
import prefList from '../const/prefList';
import Message from '../const/message';
import UserAuth from '../const/userAuthority';
import Validation from '../utils/validation';
import Authorize from '../utils/authorize';

export default {
  name: 'SchoolManageRegister',
  components: {
    Title,
    ErrMsgCard,
  },
  data() {
    return {
      schoolCd: { key: '学校コード', value: '' },
      schoolType: { key: '学校区分', value: { name: '', code: '' } },
      schoolName: { key: '学校名', value: '' },
      zipCd: { key: '郵便番号', value: '' },
      pref: { key: '都道府県', value: { name: '', code: '' } },
      address: { key: '住所', value: '' },
      phoneNum: { key: '電話番号', value: '' },
      remarks: { key: '備考', value: '' },
      schoolTypeList: [],
      prefList: prefList,

      errMsgs: [],
      process: Message.PROC_TYPE.REGISTER,
      noResult: Message.MULTI_NOT_RESULT,
      noOptions: Message.MULTI_NOT_OPTIONS,
      isUnsent: true
    };
  },
  created: async function () {
    // 権限チェック
    if (this.$store.state.authority === UserAuth.SYSTEM_ADMIN.code) {
      // 学校区分を全件取得
      const rspBody = await searchSchoolTypes(this.$route.path);
      this.schoolTypeList = rspBody.detail;
    } else {
      // エラーページに遷移
      this.$router.push({
        name: 'not-found',
        params: { catchAll: 'not-found' },
      });
      // 処理強制終了
      return false;
    }
  },
  methods: {
    register() {
      let self = this;
      // 登録確認ポップアップを表示
      this.$store.commit(
        'setMessages', 
        [Message.generateMessage(Message.CONFIRM, [this.process])]
      );
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setIsModalOpen', true);
      this.$store.commit('setOkAction', async function () {
        // 都道府県コードがnullの場合初期値をセット
        self.pref.value = self.pref.value ?? { name: '', code: '' };

        // バリデーション定義作成
        let validationArray = [];
        // バリデーションチェック実施
        self.errMsgs = validationArray.concat(
          Validation.requiredInput(self.schoolCd),
          Validation.fixedLength(self.schoolCd, 7),
          Validation.isInteger(self.schoolCd),
          Validation.requiredInput(self.schoolName),
          Validation.requiredSelect(self.schoolType),
          Validation.isZipCdFormat(self.zipCd),
          Validation.requiredSelect(self.pref),
          Validation.digitsMax(self.phoneNum, 13),
          Validation.isPhoneNumFormat(self.phoneNum)
        );

        // エラーメッセージがある場合は処理を中断
        if (self.errMsgs.length) return false;

        if (Date.now() / 1000 > self.$store.state.tokenExp){
          if (!await Authorize.isAuth()) {
            // 認証失敗：ログイン画面へ遷移
            self.$router.push({ name: 'login', query: { redirect: self.$route.path } });
            return false;
          }
        }

        // 学校登録API呼び出し
        registerSchool(
          function (rspBody) {
            if (rspBody.result.code === 0) {
              self.isUnsent = false;
              // 成功した場合、学校一覧に遷移
              self.$store.commit(
                'setMessages',
                [
                  Message.generateMessage(Message.COMPLETED, [Message.PROC_TYPE.REGISTER]),
                ]
              );
              self.$store.commit('setOkAction', function(){self.toSchoolList();});
              self.$store.commit('setIsModalInfo', true);
              self.$store.commit('setCancelBtnHide', true);
              self.$store.commit('setIsModalOpen', true);
            } else {
              // 失敗した場合、エラー内容を表示
              self.errMsgs = rspBody.result.messages;
            }
          },
          self.schoolCd.value,
          self.schoolName.value,
          self.schoolType.value.code,
          self.zipCd.value,
          self.pref.value.code,
          self.address.value,
          self.phoneNum.value,
          self.remarks.value,
          self.$route.path
        );
      });
    },
    toSchoolList() {
      this.$router.push({
        name: 'school-manage',
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isUnsent && to.name != 'maintenance') {
      this.$store.commit('setMessages', [Message.INPUT_VALUE_CLEAR]);
      this.$store.commit('setCancelBtnMsg', Message.BUTTON_TYPE.CANCEL);
      this.$store.commit('setIsModalOpen', true);
      this.$store.commit('setOkAction', function () {
        next();
      });
      this.$store.commit('setCancelAction', function () {
        next(false);
      });
    } else {
      next();
    }
  },
};
</script>

<style scoped>
.button {
  @apply font-semibold w-32 rounded py-2 px-2 focus:outline-none focus:ring focus:ring-opacity-50;
}
input,
select,
textarea {
  @apply focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}
select,
textarea {
  @apply mt-1 block rounded-md border-gray-300 shadow-sm focus:border-indigo-300;
}
.multiselect {
  height: 40px;
  @apply p-0 focus:ring focus:ring-indigo-200 focus:ring-opacity-50;
}
.multiselect--active {
  height: 40px;
}
.multiselect >>> .multiselect__single {
  min-height: 15px;
  @apply my-0 py-0 leading-tight;
}
.multiselect >>> .multiselect__input {
  min-height: 15px;
  @apply my-0 py-0 h-6 leading-tight;
}
.multiselect >>> .multiselect__tags {
  max-height: 45px;
}
.multiselect >>> .multiselect__content-wrapper {
  @apply overflow-x-hidden;
}
</style>
