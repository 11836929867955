import Cryptico from 'cryptico';
import key from './publicKey';

/**
 * 公開鍵暗号方式での暗号化
 * @param {string} plaintext 平文
 * @returns 暗号化文字列
 */
export const rsaEncrypto = function (plaintext) {
  const crypt = Cryptico.encrypt(plaintext, key);
  // 暗号文返却
  return crypt.cipher;
};
