<template>
  <div class="max-w-2xl mx-auto lg:max-w-4xl md:max-w-4xl">
    <Title msg="本棚" submsg="Book Shelf" />

    <div
      class="
        grid
        lg:grid-cols-4
        sm:grid-cols-3
        grid-cols-2
        gap-x-2 gap-y-4
        mx-auto
        my-6
        text-gray-600
      "
    >
      <a
        v-for="textbook in textbooks"
        :key="textbook.licenseCd"
        target="_blank"
        rel="noopener noreferrer"
        :href="textbook.fileUrl"
      >
        <label
          class="subject-label"
          :style="{ backgroundColor: textbook.subjectColor }"
        >
          {{ textbook.subjectName }}
        </label>
        <div class="textbook-box">
          <img
            v-if="textbook.thumbnail"
            class="thumbnail-img"
            :alt="textbook.textbookname"
            :src="textbook.thumbnail"
          />
          <img
            v-else
            class="m-auto mt-0.5 sm:py-9 py-3"
            src="../assets/no_image.png"
            alt="サムネイルが存在しません"
            width="150"
          />
          <p class="textbook-title">
            <font-awesome-icon icon="caret-right" />
            {{ textbook.textbookname }}
          </p>
        </div>
      </a>
    </div>
    <div
      v-if="isEmptyTextbooks"
      class="
        bg-red-50
        p-4
        rounded
        flex
        items-start
        text-red-600
        shadow-lg
        mx-auto
        max-w-2xl
      "
    >
      <div class="text-lg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="fill-current w-5 pt-1"
          viewBox="0 0 24 24"
        >
          <path
            d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.597 17.954l-4.591-4.55-4.555 4.596-1.405-1.405 4.547-4.592-4.593-4.552 1.405-1.405 4.588 4.543 4.545-4.589 1.416 1.403-4.546 4.587 4.592 4.548-1.403 1.416z"
          />
        </svg>
      </div>
      <div class="px-4">
        <h3 class="text-red-800 font-semibold tracking-wider">
          {{ errMsg }}
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
import { searchBookshelf } from '../api';
import UserAuth from '../const/userAuthority';
import Title from '../components/TheTitle.vue';

export default {
  name: 'BookShelf',
  components: {
    Title,
  },
  data() {
    return {
      textbooks: [],
      isEmptyTextbooks: false,
      errMsg: '',
    };
  },
  created: function () {
    // 権限チェック
    const authority = this.$store.state.authority;
    if (authority === UserAuth.STUDENT.code || authority === UserAuth.TEACHER.code) {
      // 電子教材情報を取得
      let self = this;
      searchBookshelf(function (rspBody) {
        if (rspBody.result.code === 0 && rspBody.detail.length) {
          self.textbooks = rspBody.detail;
        } else {
          rspBody.result.messages.forEach((msg) => {
            self.errMsg += msg;
          });
          self.isEmptyTextbooks = true;
        }
      }, this.$store.state.loginId, this.$route.path);
    } else {
      // エラーページに遷移
      this.$router.push({
        name: 'not-found',
        params: { catchAll: 'not-found' },
      });
      // 処理強制終了
      return false;
    }
  }
};
</script>

<style scoped>
.textbook-box {
  @apply py-4 bg-white rounded shadow;
}
.thumbnail-img {
  @apply sm:h-56 h-44 m-auto;
}
.textbook-title {
  @apply mt-3 text-center text-sm;
}

.subject-label {
  @apply mb-2 ml-2 py-1 px-4 rounded-t text-xs tracking-widest text-center text-white;
}
</style>
