<script>
import { defineComponent } from 'vue';
import { Line } from 'vue3-chart-v2';

export default defineComponent({
  name: 'UsageStatsChart',
  extends: Line,
  props: {
    usageStatsList: { type: Array, default: () => [] },
    displayDates: { type: Array, default: () => [] },
  },
  data() {
    return {
      colorList: [
        '#ffa8a8',
        '#ffa8d3',
        '#d3a8ff',
        '#a8a8ff',
        '#a8d3ff',
        '#a8ffff',
        '#a8ffd3',
        '#a8ffa8',
        '#d3ffa8',
        '#ffd3a8',
      ],
    };
  },
  watch: {
    usageStatsList: function (usageStatsList) {
      if (usageStatsList.length) {
        let labels = [];
        this.displayDates.forEach((displaydate) => {
          labels.push(displaydate.date);
        });
        this.labels = labels;
        let datasets = [];
        usageStatsList.forEach((usageStats, index) => {
          let count = [];
          usageStats.dateCounts.forEach((dateCount) => {
            count.push(dateCount.count);
          });
          datasets.push({
            label: usageStats.viewName,
            borderColor:
              Math.floor(index / 10) == 0
                ? this.colorList[index]
                : this.colorList[index - 10 * Math.floor(index / 10)],
            lineTension: 0,
            fill: false,
            borderDash: Math.floor(index / 10) % 2 == 0 ? [] : [8, 2],
            data: count,
          });
        });

        let options = {// chartのoption設定
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              ticks: {
                // 丸める桁数の指定(0=少数点以下を表示しない)
                precision: 0,
              }
            }]
          }
        }

        let chartParentDivNode = this.$refs.canvas.parentNode.parentNode;
        this.$refs.canvas.height = chartParentDivNode.clientHeight;
        this.$refs.canvas.width = chartParentDivNode.clientWidth;

        // Overwriting base render method with actual data.
        this.renderChart({
          labels: this.labels,
          datasets: datasets,
        },
        options);
      } else {
        if (this.state.chartObj) {
          this.state.chartObj.destroy();
        }
      }
    },
  },
});
</script>
<style scoped></style>
